export default {
  formId: 'checkoutForm',
  formField: {
    fullName: {
      name: 'fullName',
      label: 'Full Name*',
      requiredErrorMsg: 'Full name is required'
    },
    kilometer: {
      name: 'kilometer',
      label: 'Kilometer *',
      requiredErrorMsg: 'Kilometer is required'
    },
    contactNo: {
      name: 'contactNo',
      label: 'Phone Number*',
      requiredErrorMsg: 'Phone Number is required'
    },
    problemdetails: {
      name: 'problemdetails',
      label: 'Problem Details*',
      requiredErrorMsg: 'Problem Details is required'
    },
    useraddres: {
      name: 'useraddres',
      label: 'Addres*',
      requiredErrorMsg: 'Addres is required'
    },
    make: {
      name: 'make',
      label: 'Make*',
      requiredErrorMsg: 'Make is required'
    },
    model: {
      name: 'model',
      label: 'Model*',
      requiredErrorMsg: 'Model is required'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Address*',
      requiredErrorMsg: 'Address is required'
    }
  }
};
