import React from "react";

const CardWithHeader = ({ headerTitle = "Featured", children }) => {
  return (
    <div class="card border rounded-2">
      <div class="card-header border bg-grey-9">{headerTitle}</div>
      <div class="card-body">{children}</div>
    </div>
  );
};

export default CardWithHeader;
