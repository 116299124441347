import React from "react";
import { Carousel } from "react-bootstrap";
import { apiImgUrl } from "../../Helper/BaseUrl";
import NoImg from "../../assets/images/noImg.png";

const BasicSlider = ({ data }) => {
    return (
        <div className="sc-slider">
            {data?.banners?.length > 0 ? (
                <>
                    <Carousel className="">
                        {data?.banners?.map((item, i) => (
                            <Carousel.Item key={i}>
                                <img
                                    className="d-block w-100 img-fit img__height"
                                    src={apiImgUrl + item?.image_path}
                                    alt="First slide"
                                    style={{ maxHeight: 400 }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </>
            ) : (
                <>
                    <img className="no_img__banner" src={NoImg} alt="Images" />
                </>
            )}
        </div>
    );
};

export default BasicSlider;
