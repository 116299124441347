import React from 'react';
import SummaryHeader from './SummaryHeader';
import SummaryList from './SummaryList';
import { useSelector } from 'react-redux';
import Headline from '../../../Components/common/Headline';

const ShoppingSummary = () => {
    const { cart } = useSelector((state) => state);

    console.log(cart);

    let content = null;

    // if (cart.length > 0) content = cart.map((item, i) => <SummaryItem key={i} item={item} />);

    if (cart.length > 0)
        content = cart.map((item) => (
            <div key={item.sellerSlug} class='table-responsive shopping-summery mb-20'>
                <Headline title={item.sellerName} />
                <table class='table table-wishlist'>
                    <SummaryHeader />

                    <SummaryList item={{ sellerSlug: item.sellerSlug, cartItem: item.cartItem }} />
                </table>
            </div>
        ));

    return content;
};

export default ShoppingSummary;
