import React from "react";
import Dashboard from "./pages/Dashboard";
import AccountDetails from "./pages/AccountDetails";
import MyAddress from "./pages/MyAddress";
import Orders from "./pages/Orders";
import OrderTracking from "./pages/OrderTracking";
import MyComplain from "./pages/MyComplain";
import MyFavorite from "./pages/MyFavorite";
import MyEnquiry from "./pages/MyEnquiry";

const MyAccountPageList = () => {
  return (
    <div class="tab-content account dashboard-content pl-50">
      <Dashboard />
      <AccountDetails />
      <Orders />
      <MyComplain />
      <MyFavorite />
      <MyEnquiry />
      <OrderTracking />
      <MyAddress />
    </div>
  );
};

export default MyAccountPageList;
