import React from "react";
import Headline from "../Headline";

const BookingSuccess = () => {
  return (
    <div className="mt-50">
      <Headline
        title="Your booking is done"
        endItem={
          <a className="fs-6" href="#">
            Goto Booking List
          </a>
        }
      />

      <table class="table table-bordered mb-40">
        <tbody>
          <tr>
            <td className="p-4">
              <div className="mb-20">
                <h6>Reference Id</h6>
                <h4>SER10001001</h4>
              </div>

              <div className="d-flex justify-content-between flex-wrap mb-20">
                <span>
                  <h6>Renter Name</h6>
                  <h4>Mr. Hasan Ahmed</h4>
                </span>

                <span>
                  <h6>Contact</h6>
                  <h4>01759178623</h4>
                </span>
              </div>

              <div className="">
                <h6>Trip Type</h6>
                <h4>Round Trip</h4>
              </div>
            </td>

            <td className="text-center">
              <h5>Car Details</h5>
              <h3 className="mb-2">Toyota, Corolla</h3>
              <p>5 Seater Luxury Car</p>
              <p>Model: 2015</p>
              <p>Seat: 05</p>
            </td>
          </tr>

          <tr>
            <td className="p-4">
              <div className="d-flex justify-content-between flex-wrap mb-20">
                <span>
                  <h6>Pick Date</h6>
                  <h4>10/05/2022</h4>
                </span>

                <span>
                  <h6>Time</h6>
                  <h4>10:00AM</h4>
                </span>

                <span>
                  <h6>For</h6>
                  <h4>5 Days</h4>
                </span>
              </div>

              <div className="mb-20">
                <h6>Location Route (From - To)</h6>
                <h4>Dhaka To Chittagong</h4>
              </div>

              <div className="">
                <h6>Note</h6>
                <h4>Arrive the pick up location on time</h4>
              </div>
            </td>

            <td className="text-center">
              <h5>Rent a Car Company Details</h5>
              <h3>Shamoli Rent A Car</h3>
              <p>123, Adabor, Ring Road</p>
              <p>Dhaka - 1207</p>
              <p>017565141354</p>
            </td>
          </tr>
        </tbody>
      </table>

      <Headline title="Payment Details" />

      <table class="table">
        <tbody>
          <tr>
            <td>Total Rate Charges</td>
            <td>4000</td>
          </tr>
          <tr>
            <td>Other Charges</td>
            <td>2000</td>
          </tr>
          <tr>
            <th scope="row">
              <h6 className="text-end mr-5">Sub Total</h6>
            </th>
            <td className="fw-bold">
              <h6>6000</h6>
            </td>
          </tr>
          <tr>
            <td>Advance Payment</td>
            <td> (-) 900</td>
          </tr>
          <tr>
            <th scope="row">
              <h6 className="text-end mr-5">Balance</h6>
            </th>
            <td className="fw-bold">
              <h6>5100</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BookingSuccess;
