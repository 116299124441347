export const cartTotal = (cart) =>
    cart
        .map((seller) =>
            seller.cartItem
                .filter((item) => item.isSelected)
                .reduce((sellerTotal, item) => {
                    const itemDiscount = parseFloat(item.discount_price);
                    const itemQuantity = item.quantity;
                    return sellerTotal + itemDiscount * itemQuantity;
                }, 0)
        )
        .reduce((total, sellerTotal) => total + sellerTotal, 0);

export const totalCartItem = (cart) => new Set(cart.flatMap((seller) => seller.cartItem.map((item) => item.slug))).size;

export const selectedForCheckout = (cart) =>
    cart
        .flatMap((seller) => seller.cartItem.filter((item) => item.isSelected))
        .map((item) => {
            const { isSelected, name, dv_charge, productImage, ...itemWithoutSelected } = item;
            return itemWithoutSelected;
        });

// export const shippingTotal = (cart) =>
//     cart.map((seller) =>
//         seller.cartItem
//             .filter((item) => item.isSelected)
//             .reduce((shippingTotal, item) => shippingTotal + +item.dv_charge, 0)
//             .reduce((total, deliveryCharge) => total + deliveryCharge, 0)
//     );

export const shippingTotal = (cart) => {
    return cart
        .map((seller) => {
            const sellerShippingTotal = seller.cartItem
                .filter((item) => item.isSelected)
                .reduce((shippingTotal, item) => shippingTotal + +item.dv_charge, 0);
            return sellerShippingTotal;
        })
        .reduce((total, sellerShippingTotal) => total + sellerShippingTotal, 0);
};
