import React from 'react';
import { Link } from 'react-router-dom';
import Headline from '../../../Components/common/Headline';
import product1 from '../../../assets/imgs/shop/parts-2.jpg';
import product2 from '../../../assets/imgs/shop/parts-3.jpg';
import product3 from '../../../assets/imgs/shop/car-3.webp';
import product4 from '../../../assets/imgs/shop/parts-1.jpg';
import product5 from '../../../assets/imgs/shop/parts-5.jpg';
import product6 from '../../../assets/imgs/shop/parts-1.jpg';
import product7 from '../../../assets/imgs/shop/parts-2.jpg';
import product8 from '../../../assets/imgs/shop/parts-3.jpg';
import OwlCarousel from 'react-owl-carousel';
import { Box } from '@material-ui/core';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const MechanicBiddingList = () => {
    const options = {
        navText: ['Prev', 'Next'],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1024: {
                items: 4,
            },
            1280: {
                items: 5,
            },
            1366: {
                items: 5,
            },
        },
    };
    return (
        <div>
            <section className='section-padding pb-5'>
                <div className='container'>
                    <Headline
                        title='Mechanic Bidding'
                        endItem={
                            <h6>
                                {' '}
                                <Link to=''>View All</Link>
                            </h6>
                        }
                    />
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 wow animate__animated animate__fadeIn' data-wow-delay='.4s'>
                            <OwlCarousel
                                className='owl-theme'
                                loop
                                margin={25}
                                nav={false}
                                dots={false}
                                autoplay={false}
                                autoplayTimeout={5000}
                                {...options}
                            >
                                {/* 1 */}
                                <div className='product-cart-wrap'>
                                    <div className='product-img-action-wrap mb-3' style={{ height: 230 }}>
                                        <div className='product-img product-img-zoom'>
                                            <Link to=''>
                                                <img className='default-img' src={product1} alt='' />
                                                <img className='hover-img' src={product2} alt='' />
                                            </Link>
                                        </div>
                                        <div className='product-action-1'>
                                            <button
                                                aria-label='Quick view'
                                                className='action-btn small hover-up'
                                                data-bs-toggle='modal'
                                                data-bs-target='#quickViewModal'
                                            >
                                                <i className='fi-rs-eye'></i>
                                            </button>
                                            <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-heart'></i>
                                            </Link>
                                            <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-shuffle'></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='product-content-wrap text-center'>
                                        <h2>
                                            <Link to=''>Car engine Toyota AXIO</Link>
                                        </h2>

                                        <div className='mb-20'>
                                            <p className='text-brand'>Quantity: 2000</p>
                                        </div>

                                        <Link to='' className='btn w-100 hover-up d-flex align-items-center justify-content-center gap-2'>
                                            <ArticleOutlinedIcon sx={{ fontSize: 18 }} />
                                            View Details
                                        </Link>
                                    </div>
                                </div>

                                {/* 2 */}
                                <div className='product-cart-wrap'>
                                    <div className='product-img-action-wrap mb-3' style={{ height: 230 }}>
                                        <Box
                                            className='product-img product-img-zoom'
                                            // sx={{ minHeight: "200px" }}
                                        >
                                            <Link to=''>
                                                <img className='default-img' src={product5} alt='' />
                                                <img className='hover-img' src={product6} alt='' />
                                            </Link>
                                        </Box>
                                        <div className='product-action-1'>
                                            <button
                                                aria-label='Quick view'
                                                className='action-btn small hover-up'
                                                data-bs-toggle='modal'
                                                data-bs-target='#quickViewModal'
                                            >
                                                {' '}
                                                <i className='fi-rs-eye'></i>
                                            </button>
                                            <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-heart'></i>
                                            </Link>
                                            <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-shuffle'></i>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='product-content-wrap text-center'>
                                        <h2>
                                            <Link to=''>Car Parts Toyota</Link>
                                        </h2>

                                        <div className='mb-20'>
                                            <p className='text-brand'>Quantity: 1000</p>
                                        </div>

                                        <Link to='' className='btn w-100 hover-up d-flex align-items-center justify-content-center gap-2'>
                                            <ArticleOutlinedIcon sx={{ fontSize: 18 }} />
                                            View Details
                                        </Link>
                                    </div>
                                </div>

                                {/* 3 */}
                                <div className='product-cart-wrap'>
                                    <div className='product-img-action-wrap mb-3' style={{ height: 230 }}>
                                        <div className='product-img product-img-zoom'>
                                            <Link to=''>
                                                <img className='default-img' src={product7} alt='' />
                                                <img className='hover-img' src={product8} alt='' />
                                            </Link>
                                        </div>
                                        <div className='product-action-1'>
                                            <button
                                                aria-label='Quick view'
                                                className='action-btn small hover-up'
                                                data-bs-toggle='modal'
                                                data-bs-target='#quickViewModal'
                                            >
                                                {' '}
                                                <i className='fi-rs-eye'></i>
                                            </button>
                                            <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-heart'></i>
                                            </Link>
                                            <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-shuffle'></i>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='product-content-wrap text-center'>
                                        <h2>
                                            <Link to=''>Car Parts Honda</Link>
                                        </h2>

                                        <div className='mb-20'>
                                            <p className='text-brand'>Quantity: 1111</p>
                                        </div>

                                        <Link to='' className='btn w-100 hover-up d-flex align-items-center justify-content-center gap-2'>
                                            <ArticleOutlinedIcon sx={{ fontSize: 18 }} />
                                            View Details
                                        </Link>
                                    </div>
                                </div>

                                {/* 4 */}
                                <div className='product-cart-wrap'>
                                    <div className='product-img-action-wrap mb-3' style={{ height: 230 }}>
                                        <div className='product-img product-img-zoom'>
                                            <Link to=''>
                                                <img className='default-img' src={product3} alt='' />
                                                <img className='hover-img' src={product4} alt='' />
                                            </Link>
                                        </div>
                                        <div className='product-action-1'>
                                            <button
                                                aria-label='Quick view'
                                                className='action-btn small hover-up'
                                                data-bs-toggle='modal'
                                                data-bs-target='#quickViewModal'
                                            >
                                                {' '}
                                                <i className='fi-rs-eye'></i>
                                            </button>
                                            <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-heart'></i>
                                            </Link>
                                            <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-shuffle'></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='product-content-wrap text-center'>
                                        <h2>
                                            <Link to=''>Car Parts Volvo</Link>
                                        </h2>

                                        <div className='mb-20'>
                                            <p className='text-brand'>Quantity: 2568</p>
                                        </div>

                                        <Link to='' className='btn w-100 hover-up d-flex align-items-center justify-content-center gap-2'>
                                            <ArticleOutlinedIcon sx={{ fontSize: 18 }} />
                                            View Details
                                        </Link>
                                    </div>
                                </div>

                                {/* 5 */}
                                <div className='product-cart-wrap'>
                                    <div className='product-img-action-wrap mb-3' style={{ height: 230 }}>
                                        <div className='product-img product-img-zoom'>
                                            <Link to=''>
                                                <img className='default-img' src={product1} alt='' />
                                                <img className='hover-img' src={product2} alt='' />
                                            </Link>
                                        </div>
                                        <div className='product-action-1'>
                                            <button
                                                aria-label='Quick view'
                                                className='action-btn small hover-up'
                                                data-bs-toggle='modal'
                                                data-bs-target='#quickViewModal'
                                            >
                                                <i className='fi-rs-eye'></i>
                                            </button>
                                            <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-heart'></i>
                                            </Link>
                                            <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                                                <i className='fi-rs-shuffle'></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='product-content-wrap text-center'>
                                        <h2>
                                            <Link to=''>Car engine Toyota AXIO</Link>
                                        </h2>

                                        <div className='mb-20'>
                                            <p className='text-brand'>Quantity: 2000</p>
                                        </div>

                                        <Link to='' className='btn w-100 hover-up d-flex align-items-center justify-content-center gap-2'>
                                            <ArticleOutlinedIcon sx={{ fontSize: 18 }} />
                                            View Details
                                        </Link>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MechanicBiddingList;
