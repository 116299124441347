import React from 'react';
import { Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// import {decrease, getCartTotal, increase, remove} from "../reduxServices/actions/CartActions";

import { toast } from 'react-toastify';
import BreadCrumb from '../Components/common/BreadCrumb';
import axios from '../Helper/Config';
import { COOKIE_NAME, getCookie } from '../Helper/Cookies';
import { cartTotal, selectedForCheckout, shippingTotal, totalCartItem } from '../Helper/utils/function';
import ShoppingSummary from './Cart/CartBody/ShoppingSummary';

// const cartslist = useSelector((state) => state.cartItems.carts);
// const allItem = useSelector((state) => state.cartItems.totalItem);
// const totalAmount = useSelector((state) => state.cartItems.totalAmount);

function Checkout() {
    const { cart } = useSelector((state) => state);

    const subTotal = cartTotal(cart);
    const shippingCharge = shippingTotal(cart);
    const totalAmount = subTotal + shippingCharge;

    const checkoutItems = selectedForCheckout(cart);

    console.log(checkoutItems);
    // useEffect(() => {
    //     dispatch(getCartTotal());
    // }, [cartslist]);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const onFinish = (value) => {
        const formData = {
            uid: localStorage.getItem('uid'),
            username: `${value.username}`,
            contact_no: `${value.contact_no}`,
            email: `${value.email}`,
            address: `${value.address}`,
            payment_gateway: `${value.payment_gateway}`,
            cart_item: checkoutItems,
            sub_total: subTotal,
            ship_charge: shippingCharge,
        };
        axios.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${token}`;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.post('/onlineorder/order/add', formData).then((res) => {
            toast.dark(res.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.removeItem('cart');
            window.location.href = './success-order/' + res.data.data;
        });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('onFinishFailed:', errorInfo);
    };
    const token = getCookie(COOKIE_NAME);
    let username = '';
    if (token == 0) {
        username = '';
    } else {
        username = token;
    }
    if (username) {
        return (
            <div>
                <BreadCrumb category='Page' sub='Checkout' />

                <div className='breadcrumb-area breadcrumb-bg'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='breadcrumb-txt mt-20 mb-15'>
                                    <h1>Checkout Page</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='breadcrumb-overlay'></div>
                </div>

                <div className='cart-table'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 cart-cards'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5>Cart Information</h5>
                                    </div>
                                    <div className='card-body'>
                                        <ShoppingSummary />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 cart-cards'>
                                <div className='card mb-4'>
                                    <div className='card-header'>
                                        <h5>ORDER SUMMARY</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='order_summary_cntn'>
                                            <h5 className='p-0 mb-2'>
                                                {' '}
                                                Subtotal ({totalCartItem(cart)} items) <span> ৳ {subTotal} </span>
                                            </h5>
                                            <h5 className='p-0 mb-2'>
                                                {' '}
                                                Shipping Fee <span> ৳ {shippingCharge} </span>
                                            </h5>

                                            <h5 className='p-0'>
                                                {' '}
                                                Total <span> ৳ {totalAmount} </span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5>Billing Address</h5>
                                    </div>
                                    <div className='card-body'>
                                        <Form
                                            name='basic'
                                            layout='vertical'
                                            className='from_home_area'
                                            form={form}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete='off'
                                        >
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label='Your Name'
                                                        name='username'
                                                        className='form-group form-box'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Your Name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder='Your Name' className='form-control' />
                                                    </Form.Item>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label='Contact Number'
                                                        name='contact_no'
                                                        className='form-group form-box'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Contact Number!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder='Contact Number' className='form-control' />
                                                    </Form.Item>
                                                </div>

                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label='Email'
                                                        name='email'
                                                        className='form-group form-box'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Email Address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder='Email' className='form-control' />
                                                    </Form.Item>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label='Address'
                                                        name='address'
                                                        className='form-group form-box'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Address!',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={4} placeholder='Message' />
                                                    </Form.Item>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label='Payment Method'
                                                        name='payment_gateway'
                                                        className='form-group form-box'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Select a gateway!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select>
                                                            <Option value='cashondelivery'>Cash On Delivery</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className='process-to-checkout mt-3'>
                                                    <button className='btn btn-primary cartbtn cart'> Proceed to Payment </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Navigate to='/login' />;
    }
}

export default Checkout;
