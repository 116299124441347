import React from "react";
import Headline from "../../../../../../Components/common/Headline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const BookingSuccess = () => {
  return (
    <div className="mt-50">
      <Headline
        startIcon={
          <TaskAltIcon
            sx={{ fontSize: 28, color: "red", mr: 2 }}
            className="text-brand"
          />
        }
        title="Your booking is done"
        endItem={
          <a className="fs-6" href="#">
            Goto Booking List
          </a>
        }
      />

      <p>
        Your reference id is <strong>DRV10001001</strong>. All details will be
        sent to your email and check your booking profile.
      </p>

      <p className="mb-30">
        Thank you for booking. Use the above reference id in all communication.
      </p>

      <table class="table table-bordered mb-40">
        <tbody>
          <tr>
            <td className="p-4">
              <div className="mb-20">
                <h6>Reference Id</h6>
                <h4>DRV10001001</h4>
              </div>

              <div className="d-flex justify-content-between flex-wrap mb-20">
                <span>
                  <h6>Driver Name</h6>
                  <h4>Mr. Hasan Ahmed</h4>
                </span>

                <span>
                  <h6>Contact</h6>
                  <h4>01759178623</h4>
                </span>
              </div>

              <div className="d-flex justify-content-between flex-wrap mb-20">
                <span>
                  <h6>From Date</h6>
                  <h4>Mr. Hasan Ahmed</h4>
                </span>

                <span>
                  <h6>To Date</h6>
                  <h4>10/6/2023</h4>
                </span>
              </div>

              <div>
                <h6>Route (From - To)</h6>

                <span className="d-flex align-items-center gap-3">
                  <h4>Dhaka</h4>
                  <small>To</small>
                  <h4>Dhaka</h4>
                </span>
              </div>
            </td>

            <td className="text-center">
              <h5>Total Cost</h5>
              <h3 className="mb-2">6000</h3>
            </td>
          </tr>

          <tr>
            <td colspan="2" className="p-4">
              <h6>Notes</h6>
              <h4>Arrive the pickup location on time</h4>
            </td>
          </tr>
        </tbody>
      </table>

      <Headline title="Payment Details" />

      <table class="table">
        <tbody>
          <tr>
            <td>Total Rate Charges</td>
            <td>4000</td>
          </tr>
          <tr>
            <td>Other Charges</td>
            <td>2000</td>
          </tr>
          <tr>
            <th scope="row">
              <h6 className="text-end mr-5">Sub Total</h6>
            </th>
            <td className="fw-bold">
              <h6>6000</h6>
            </td>
          </tr>
          <tr>
            <td>Advance Payment</td>
            <td> (-) 900</td>
          </tr>
          <tr>
            <th scope="row">
              <h6 className="text-end mr-5">Balance</h6>
            </th>
            <td className="fw-bold">
              <h6>5100</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BookingSuccess;
