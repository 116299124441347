import React, {useEffect, useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import axios from "../Helper/Config";
import {Link} from 'react-router-dom'

function PrivacyPolicy() {
    const [singleData, setAllData] = useState([]);
    useEffect(() => {
        axios.get(`/common/company/privacypolicy`)
            .then((res) => {
                const temp = {...res?.data?.data}
                setAllData(temp)
            }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (<div className='privacy_policy_area'>
            <Container>
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to="#" rel="nofollow">
                                <i className="fi-rs-home mr-5"></i>Home
                            </Link>
                            <span></span>Privacy Policy
                        </div>
                    </div>
                </div>
                <br/>

                <h1 className="page-title"> Privacy Policy</h1>
                <Row>
                    {singleData?.privacyPolicy?.length > 0 ? <>
                        {singleData?.privacyPolicy?.map((item, i) =>
                            <Col lg={12} key={i}>
                                <div className='privacy_policy_txt'>
                                    <h4> {item?.name} </h4>
                                    <div dangerouslySetInnerHTML={{__html: (item?.description)}}/>
                                    <span> Last Update {`${item?.updated_at ? new Date(item?.updated_at).toLocaleString() : ''}`}</span>
                                </div>

                            </Col>
                        )}

                    </> : <> </>}
                </Row>
            </Container>
        </div>)
}

export default PrivacyPolicy
