import React, {useEffect, useState} from 'react'
import {Empty, Input, Rate, Select} from 'antd';
import Iframe from 'react-iframe'
import { apiImgUrl } from "../Helper/BaseUrl";
import axios from "../Helper/Config";
import {RiPhoneFill} from "react-icons/ri";
import { Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function NeearShopMap() {
    const { Search } = Input;
    const { Option } = Select;
    const [storeShow, setStoreShow] = useState(false)
    const [latitude, setlatitude] = useState('');
    const [longitude, setlongitude] = useState('');
    const [disance, setDisance] = useState('');
    const [search, setSearch] = useState('');
    const [shop, setShop] = useState('');
    const [isupdate, setisupdate] = React.useState(0);
    const [viewlatitude, viewsetlatitude] = useState('');
    const [viewlongitude, viewsetlongitude] = useState('');

    if (navigator.geolocation) {
        navigator.geolocation.watchPosition(function(position) {
            setlatitude(position.coords.latitude);
            setlongitude(position.coords.longitude);
        });
    }
    const dataSHow = (e) => {
        setStoreShow(true)

        if (e === undefined) {
            setDisance('100')
        }else{
            setDisance(100)
        }
    }
//     const dataSHow = (e) => {
//         setStoreShow(true)
//         console.log('eee')
//         console.log(e)
//         if (e === undefined) {
//             setStoreShow(false)
//             console.log('eee2')
//
//         setDisance('100')
//     }else{
//         setDisance(e)
//     }
// }
    const searchLocation = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        getShop();
    }, [isupdate]);
    const filteLocation= () =>{
        setisupdate(isupdate + 1);
    }

    const getShop = () => {
        axios.get(`/common/company/shopLocation?latitude=${latitude}&longitude=${longitude}&disance=${disance}`)
            .then((res) => {
                if(res?.data?.data.length > 0){
                    setStoreShow(true)
                }else{
                    setStoreShow(false)
                }
                setShop(res?.data?.data)
            }).catch((err) => {
            console.log(err)
        })
    }

    const viewLocation = (item) =>{
        viewsetlatitude(item.latitude);
        viewsetlongitude(item.longitude);
        console.log({viewlatitude})
        console.log({viewlongitude})
    }
    // map start
    const GoogleMap = ({coords, width}) => {
        return (
            <Iframe src={`https://maps.google.com/maps?q=${viewlatitude}, ${viewlongitude}&z=15&output=embed`} width="100%" height="650" frameborder="0" style="border:0"></Iframe>
        );
    };

    // map
    return (
        <div div className='neer_shop_area'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 sNSearch'>
                        <div className='sNSearch_box py-5'>
                            <div className='sNSearch_box_search mb-4'>
                                {/* <Search allowClear placeholder="input search text" onChange={searchLocation} /> */}
                                {/* <input class="form-control  mb-3" type="text" placeholder="input search text" aria-label="default input example" onChange={searchLocation} /> */}

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="input search text" aria-label="input search text" aria-describedby="button-addon2" onChange={searchLocation} />
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={searchLocation}>
                                        <SearchIcon />
                                    </button>
                                    </div>
                                
                                <Stack direction="row" spacing={2}>
                                    <select class="form-select" aria-label="Default select" onChange={dataSHow} >
                                        <option value="1">1 Mile</option>
                                        <option value="10">10 Mile</option>
                                        <option value="20">20 Mile</option>
                                        <option value="30">30 Mile</option>
                                        <option value="40">40 Mile</option>
                                        <option value="50">50 Mile</option>
                                        <option value="100">100 Mile</option>
                                    </select>
                                    <button type="button" class="btn btn-primary w-100" onClick={() => filteLocation()}>Find a Store</button>
                                </Stack>                          

                                {/* <Select
                                    defaultValue="Search Within"
                                    className='sNSearch_box_search_select_width'
                                    onChange={dataSHow}
                                    allowClear
                                >
                                    <Option value="1">1 Mile</Option>
                                    <Option value="10">10 Mile</Option>
                                    <Option value="20">20 Mile</Option>
                                    <Option value="30">30 Mile</Option>
                                    <Option value="40">40 Mile</Option>
                                    <Option value="50">50 Mile</Option>
                                    <Option value="100">100 Mile</Option>
                                </Select> */}
                                
                                {/* <button className='find_stor_btn' onClick={() => filteLocation()}> Find a Store </button> */}
                            </div>
                            {/*<h3> We are unable to access your exact location </h3>*/}
                            <p> To find a Starbucks store, use the search feature, navigate using the map, or turn on location services. </p>
                            {storeShow === false ? <> <p className='store__p'>Store Not Found . . .  </p> </> : <>
                                <div className='kilo_mile_area'>
                                    {shop?.length > 0 ? <>
                                        {shop?.map((item, i) =>
                                            <div className='location__vox' key={i}  onClick={() => viewLocation(item)}>
                                                <div className='l_box_cntn'>
                                                    <h3> {item?.shop_name}</h3>
                                                    <p> {item?.address}</p>
                                                    <strong><RiPhoneFill/> {item?.phone}</strong>
                                                </div>
                                                <div className='l_box_cntn_img' style={{maxWidth: '35%'}}>
                                                    <img src={apiImgUrl+item?.shop_logo} alt={item?.shop_name} />
                                                    <Rate value={3} />
                                                </div>
                                            </div>
                                        )}
                                    </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                                </div>
                                <p className='store__p'>Found {shop?.length} Store </p>
                            </>}
                        </div>
                    </div>
                    <div className='col-lg-8 sNSearch'>
                        <div className='py-5'>
                            <GoogleMap/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NeearShopMap
