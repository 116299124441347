import React from "react";
import { DatePicker } from "antd";
import Headline from "../../../../../../Components/common/Headline";

const BookingStepContent = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="mt-50">
      <Headline
        title="Booking Details"
        endItem={<button className="btn btn-google">Check Availability</button>}
      />
      <div class="row mb-15">
        <div className="col-xl-3">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Full Name
          </label>

          <input
            type="text"
            class="form-control form-control-sm h-auto rounded-1"
            id="address"
            placeholder="Enter Your Full Name"
          />
        </div>
        <div className="col-xl-3">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Mobile Number
          </label>

          <input
            type="tel"
            class="form-control form-control-sm h-auto rounded-1"
            id="address"
            placeholder="Enter your mobile number"
          />
        </div>
        <div className="col-xl-3">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Address
          </label>

          <input
            type="text"
            class="form-control form-control-sm h-auto rounded-1"
            id="address"
            placeholder="Enter your Address"
          />
        </div>
        <div className="col-xl-3">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Preferred Date
          </label>

          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-4">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Make
              </label>

              <select
                class="form-select form-select-sm mb-20"
                aria-label="Default select example"
              >
                <option selected>Toyota</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-xl-4">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Model
              </label>

              <select
                class="form-select form-select-sm mb-20"
                aria-label="Default select example"
              >
                <option selected>Corolla</option>
                <option value="1">Manual</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-xl-4">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Year
              </label>

              <select
                class="form-select form-select-sm mb-20"
                aria-label="Default select example"
              >
                <option selected>2021</option>
                <option value="1">Manual</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

            <div className="col-xl-12">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Notes
              </label>

              <div class="form-floating">
                <textarea
                  class="form-control h-100"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                ></textarea>
                <label for="floatingTextarea2">Comments</label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">Day</th>
                  <th scope="col">10:00AM</th>
                  <th scope="col">11:00AM</th>
                  <th scope="col">12:00PM</th>
                  <th scope="col">1:00PM</th>
                  <th scope="col">2:00PM</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="row">Sunday</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Monday</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Tuesday</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Wednesday</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-xl-6"></div>
      </div>
    </div>
  );
};

export default BookingStepContent;
