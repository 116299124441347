import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../Helper/Auth.Services';
import iconCart from '../../assets/imgs/theme/icons/icon-cart.svg';
import iconHeadphone from '../../assets/imgs/theme/icons/icon-headphone.svg';
import iconHeart from '../../assets/imgs/theme/icons/icon-heart.svg';
import iconlocationSearch from '../../assets/imgs/theme/icons/icon-location.svg';
import iconUser from '../../assets/imgs/theme/icons/icon-user.svg';
import Logo from '../../assets/imgs/theme/logo.jpg';

import useAxiosPrivate from '../../Helper/useAxiosPrivate';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { totalCartItem } from '../../Helper/utils/function';
import svg6 from '../../assets/imgs/theme/icons/Car Parts & Accessories.png';
import svg2 from '../../assets/imgs/theme/icons/brake.png';
import svg3 from '../../assets/imgs/theme/icons/cultivator.png';
import svg5 from '../../assets/imgs/theme/icons/engineering.png';
import svg4 from '../../assets/imgs/theme/icons/machinery.png';
import svg7 from '../../assets/imgs/theme/icons/piston.png';
import svg1 from '../../assets/imgs/theme/icons/tractor.png';
import CartDropDown from '../common/CartDropDown';

function Header() {
    const { cart } = useSelector((state) => state);
    const cartItemTotal = totalCartItem(cart);
    const userActive = AuthService.getCurrentUser();
    const axiosPrivate = useAxiosPrivate();
    const redirect = useNavigate();
    const [getCate, getCategory] = useState([0]);
    const [ActiveToken, setActiveToken] = useState(false);
    const [cUserName, setUserNAme] = useState([]);
    const location = useLocation();
    const string = location.pathname.includes('product-categories');
    if (string === true) {
        console.log('00000000 00000 00000000');
    } else {
        localStorage.removeItem('categories_id');
        localStorage.removeItem('locationDefault');
        localStorage.removeItem('locationId');
        localStorage.removeItem('make_id');
        localStorage.removeItem('model_id');
        localStorage.removeItem('max_price');
        localStorage.removeItem('min_price');
        localStorage.removeItem('parts_type');
        localStorage.removeItem('subcategory_id');
    }
    // const axiosPrivate = useAxiosPrivate();
    useEffect(() => {
        if (Number(userActive) === 0) {
            setActiveToken(false);
        } else {
            setActiveToken(true);
            const UserName = localStorage.getItem('Username') || ' ';
            setUserNAme(UserName);
        }
    }, [userActive]);

    const LogoutFunction = () => {
        // console.log(userLogout, "userLogoutuserLogout")
        axiosPrivate.post('/auth/logout').then((res) => {
            console.log(res.data.message);
            toast.dark(res.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            AuthService.logout();
            redirect('/');
        });
    };
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: <Link to='/'> Account Setting </Link>,
                },
                {
                    key: '2',
                    label: (
                        <button onClick={LogoutFunction} className='logout__btn'>
                            {' '}
                            Logout{' '}
                        </button>
                    ),
                },
            ]}
        />
    );

    function setCategory(cat) {
        if (cat === 0) {
            getCategory(1);
        } else {
            getCategory(0);
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <header className='header-area header-style-1 header-height-2'>
                <div className='header-middle header-middle-ptb-1 d-none d-lg-block'>
                    <div className='container'>
                        <div className='header-wrap'>
                            <div className='logo logo-width-1'>
                                <Link to='/'>
                                    <img src={Logo} alt='logo' />
                                </Link>
                            </div>
                            <div className='header-right'>
                                <div className='search-style-2'>
                                    <form action='#'>
                                        <select className='select-active'>
                                            <option>All Categories</option>
                                            <option>Milks and Dairies</option>
                                            <option>Wines & Alcohol</option>
                                            <option>Clothing & Beauty</option>
                                            <option>Pet Foods & Toy</option>
                                            <option>Fast food</option>
                                            <option>Baking material</option>
                                            <option>Vegetables</option>
                                            <option>Fresh Seafood</option>
                                            <option>Noodles & Rice</option>
                                            <option>Ice cream</option>
                                        </select>
                                        <input type='text' placeholder='Search for items...' />
                                    </form>
                                </div>
                                <div className='header-action-right'>
                                    <div className='header-action-2'>
                                        <div className='header-action-icon-2'>
                                            <Link to='neearest-shop-locations'>
                                                <img className='svgInject' alt='AdvanceSearch' src={iconlocationSearch} />
                                                {/*<span className="pro-count blue">6</span>*/}
                                            </Link>
                                            <Link to='/neearest-shop-locations'>
                                                <span className='lable'>Advance Search</span>
                                            </Link>
                                        </div>
                                        <div className='header-action-icon-2'>
                                            <Link to='shop-wishlist.html'>
                                                <img className='svgInject' alt='AdvanceSearch' src={iconHeart} />
                                                <span className='pro-count blue'>6</span>
                                            </Link>
                                            <Link to='/shop-wishlist.html'>
                                                <span className='lable'>Wishlist</span>
                                            </Link>
                                        </div>
                                        <div className='header-action-icon-2'>
                                            <Link className='mini-cart-icon' to='/cart'>
                                                <img alt='Cart' src={iconCart} />
                                                {cartItemTotal > 0 && <span className='pro-count blue'>{cartItemTotal}</span>}
                                            </Link>
                                            <Link to='/cart'>
                                                <span className='lable'>Cart</span>
                                            </Link>

                                            <CartDropDown />
                                        </div>
                                        <div className='header-action-icon-2'>
                                            <Link to='/my-account'>
                                                <img className='svgInject' alt='Account' src={iconUser} />
                                            </Link>
                                            <Link to='/my-account'>
                                                <span className='lable ml-0'>Account</span>
                                            </Link>
                                            <div className='cart-dropdown-wrap cart-dropdown-hm2 account-dropdown'>
                                                <ul>
                                                    <li>
                                                        <Link to='/my-account'>
                                                            <i className='fi fi-rs-user mr-10'></i>My Account
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='page-account.html'>
                                                            <i className='fi fi-rs-location-alt mr-10'></i>Order Tracking
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='page-account.html'>
                                                            <i className='fi fi-rs-label mr-10'></i>My Voucher
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='shop-wishlist.html'>
                                                            <i className='fi fi-rs-heart mr-10'></i>My Wishlist
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='page-account.html'>
                                                            <i className='fi fi-rs-settings-sliders mr-10'></i>Setting
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='page-login.html'>
                                                            <i className='fi fi-rs-sign-out mr-10'></i>Sign out
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='header-bottom header-bottom-bg-color sticky-bar'>
                    <div className='container'>
                        <div className='header-wrap header-space-between position-relative'>
                            <div className='logo logo-width-1 d-block d-lg-none'>
                                <Link to='#'>
                                    <img src='assets/imgs/theme/logo.svg' alt='logo' />
                                </Link>
                            </div>
                            <div className='header-nav d-none d-lg-flex'>
                                <div className='main-categori-wrap d-none d-lg-block'>
                                    <Link
                                        className={getCate === 1 ? 'categories-button-active open' : 'categories-button-active'}
                                        to='#'
                                        onClick={() => setCategory(getCate)}
                                    >
                                        <span className='fi-rs-apps'></span> All Categories
                                        <i className='fi-rs-angle-down'></i>
                                    </Link>
                                    <div
                                        className={
                                            getCate === 1
                                                ? 'categories-dropdown-wrap categories-dropdown-active-large font-heading open'
                                                : 'categories-dropdown-wrap categories-dropdown-active-large font-heading'
                                        }
                                    >
                                        <div className='d-flex categori-dropdown-inner'>
                                            <ul>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg1} alt='' />
                                                        Agriculture Machinery
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg2} alt='' />
                                                        Bike Parts & Accessories
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg3} alt='' />
                                                        Heavy Vehicle Parts
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg4} alt='' />
                                                        Industrial Machinery
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg5} alt='' />
                                                        Construction & Engineering
                                                    </Link>
                                                </li>
                                            </ul>
                                            <ul className='end'>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg6} alt='' />
                                                        Car Parts & Accessories
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/product-categories/'>
                                                        {' '}
                                                        <img src={svg7} alt='' />
                                                        Car Servicing & Maintenance
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading'>
                                    <nav>
                                        <ul>
                                            <li>
                                                <Link className='active' to='/'>
                                                    Home{' '}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/product-categories/'>Parts & Accesories</Link>
                                            </li>
                                            <li>
                                                <Link to='/services-product'>Service Center</Link>
                                            </li>
                                            <li>
                                                <Link to='/booking/driver'>Driver</Link>
                                            </li>
                                            <li>
                                                <Link to='/rent-car-booking'>Rent</Link>
                                            </li>
                                            <li>
                                                <Link to='/booking/mechanic'>Mechanic</Link>
                                            </li>
                                            <li>
                                                <Link to='/user-bidding-list-v2'>Bidding</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className='hotline d-none d-lg-flex'>
                                <img src={iconHeadphone} alt='hotline' />
                                <p style={{ fontSize: '16px' }}>
                                    +880-1538020500<span>24/7 Support Center</span>
                                </p>
                            </div>
                            <div className='header-action-icon-2 d-block d-lg-none'>
                                <div className='burger-icon burger-icon-white'>
                                    <span className='burger-icon-top'></span>
                                    <span className='burger-icon-mid'></span>
                                    <span className='burger-icon-bottom'></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
