import React from "react";
import CompareLayout from "./CompareLayout";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";

const RentCarCompare = () => {
  const images = [
    "https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80",
    "https://images.unsplash.com/photo-1549927681-0b673b8243ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1511919884226-fd3cad34687c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  ];
  return (
    <CompareLayout>
      <div class="col-xl-10 col-lg-12 m-auto">
        <h1 class="heading-2 mb-10">Comparison For: Rent A Car</h1>
        <h6 class="text-body mb-40">
          There are <span class="text-brand">3</span> products to compare
        </h6>
        <div class="table-responsive">
          <table class="table text-center table-compare">
            <tbody>
              {/* Image */}
              <tr class="pr_image">
                <td class="text-muted font-sm fw-600 font-heading mw-200">
                  Preview
                </td>
                <td class="row_img">
                  <img src={images[0]} alt="compare-img" />
                </td>
                <td class="row_img">
                  <img src={images[1]} alt="compare-img" />
                </td>
                <td class="row_img">
                  <img src={images[2]} alt="compare-img" />
                </td>
              </tr>
              {/* Title */}
              <tr class="pr_title">
                <td class="text-muted font-sm fw-600 font-heading">
                  Vehicle Type
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Sedan
                    </a>
                  </h6>
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Suv
                    </a>
                  </h6>
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Hatchback
                    </a>
                  </h6>
                </td>
              </tr>
              {/* Make */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Make</td>
                <td>Toyota</td>
                <td>Nissan</td>
                <td>Kia</td>
              </tr>
              {/* Model */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Model</td>
                <td>Corolla</td>
                <td>Sunny</td>
                <td>Sportage</td>
              </tr>
              {/* Transmission */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Transmission
                </td>
                <td>Automatic</td>
                <td>Automatic</td>
                <td>Automatic</td>
              </tr>
              {/* Fuel */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Fuel</td>
                <td>CNG</td>
                <td>CNG</td>
                <td>CNG</td>
              </tr>
              {/* No of Seats */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  No of Seats
                </td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
              </tr>

              {/* Rates */}
              <tr class="pr_price">
                <td class="text-muted font-sm fw-600 font-heading">Rates</td>
                <td class="product_price">
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Hourly</h6>
                    <h6 class="price text-brand">$150 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Half Day</h6>
                    <h6 class="price text-brand">$500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Daily</h6>
                    <h6 class="price text-brand">$4000 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Weekend Daily</h6>
                    <h6 class="price text-brand">$1500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Weekly</h6>
                    <h6 class="price text-brand">$6500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Monthly</h6>
                    <h6 class="price text-brand">$20000 BDT</h6>
                  </div>
                </td>
                <td class="product_price">
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Hourly</h6>
                    <h6 class="price text-brand">$150 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Half Day</h6>
                    <h6 class="price text-brand">$500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Daily</h6>
                    <h6 class="price text-brand">$4000 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Weekend Daily</h6>
                    <h6 class="price text-brand">$1500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Weekly</h6>
                    <h6 class="price text-brand">$6500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Monthly</h6>
                    <h6 class="price text-brand">$20000 BDT</h6>
                  </div>
                </td>
                <td class="product_price">
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Hourly</h6>
                    <h6 class="price text-brand">$150 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Half Day</h6>
                    <h6 class="price text-brand">$500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Daily</h6>
                    <h6 class="price text-brand">$4000 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Weekend Daily</h6>
                    <h6 class="price text-brand">$1500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Weekly</h6>
                    <h6 class="price text-brand">$6500 BDT</h6>
                  </div>
                  <div className="d-flex justify-content-between px-5 mb-5">
                    <h6>Monthly</h6>
                    <h6 class="price text-brand">$20000 BDT</h6>
                  </div>
                </td>
              </tr>

              {/* Rent A Car Company */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Rent A Car Company
                </td>
                <td>
                  <h6 className="mb-5">
                    <Link rel="stylesheet" to="#">
                      Shamoli Car Center
                    </Link>
                  </h6>

                  <p>123, Adabor, Ring Road, Dhaka - 1207</p>

                  <p>
                    <CallIcon sx={{ fontSize: 17 }} />
                    0175613546
                  </p>
                </td>
                <td>
                  <h6 className="mb-5">
                    <Link rel="stylesheet" to="#">
                      Euro Rent A Car
                    </Link>
                  </h6>

                  <p>123, Adabor, Ring Road, Dhaka - 1207</p>

                  <p>
                    <CallIcon sx={{ fontSize: 17 }} />
                    0175613546
                  </p>
                </td>
                <td>
                  <h6 className="mb-5">
                    <Link rel="stylesheet" to="#">
                      Fast Rent A Car
                    </Link>
                  </h6>

                  <p>123, Adabor, Ring Road, Dhaka - 1207</p>

                  <p>
                    <CallIcon sx={{ fontSize: 17 }} />
                    0175613546
                  </p>
                </td>
              </tr>

              {/* Near by me */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Near by me
                </td>
                <td>0.3 km</td>
                <td>0.3 km</td>
                <td>0.3 km</td>
              </tr>

              <tr class="pr_remove text-muted">
                <td class="text-muted font-md fw-600"></td>
                <td class="row_remove">
                  <Link to="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </Link>
                </td>
                <td class="row_remove">
                  <Link to="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </Link>
                </td>
                <td class="row_remove">
                  <Link to="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CompareLayout>
  );
};

export default RentCarCompare;
