import { apiSlice } from '../api/apiSlice';

export const serviceCenter = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getServiceCenter: builder.query({
            query: () => '/service/center/list',
        }),
    }),
});

export const { useGetServiceCenterQuery } = serviceCenter;
