import React from "react";
import Headline from "../../../Components/common/Headline";
import CustomPagination from "../../../Components/common/CustomPagination";
import ConfirmationStepContent from "../AddBidding/PartsBidding/stepContent/ConfirmationStepContent";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useState } from "react";
import CustomModal from "../../../Components/common/modal/CustomModal";
import PartsBiddingSelectModal from "../modals/PartsBiddingSelectModal";

const PartsBiddingDetails = () => {
  const [modal, setModal] = useState(false);

  return (
    <div className="container my-5">
      <Headline title="Parts Bidding Details" />

      <ConfirmationStepContent />

      <Headline title="Participant List" />

      <table class="table">
        <thead class="table-light">
          <tr>
            <th scope="col">Participation Date</th>
            <th scope="col">Participant</th>
            <th scope="col">Contact No</th>
            <th scope="col">Bidding Price</th>
            <th scope="col" style={{ width: "10%", textAlign: "center" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">10/1/2022</th>
            <td>Martin Luther</td>
            <td>01916549874</td>
            <td>7000</td>
            <td className="d-flex align-items-center justify-content-center gap-2">
              <EmojiEventsIcon sx={{ fontSize: 40 }} className="text-warning" />
              <h6>Winner</h6>
            </td>
          </tr>
          <tr>
            <th scope="row">15/1/2022</th>
            <td>Kalim Uddin</td>
            <td>0171654987</td>
            <td>7500</td>
            <td className="d-flex justify-content-center">
              <button
                className="btn btn-secondary btn-sm ms-2"
                onClick={() => setModal(true)}
              >
                Details
              </button>
            </td>
          </tr>
          <tr>
            <th scope="row">4/1/2022</th>
            <td>Rasel Ahmed</td>
            <td>0135648895</td>
            <td>7600</td>
            <td className="d-flex justify-content-center">
              <button
                className="btn btn-secondary btn-sm ms-2"
                onClick={() => setModal(true)}
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <CustomPagination placement="center" />

      <CustomModal size="xl" footer onHide={() => setModal(false)} show={modal}>
        <PartsBiddingSelectModal />
      </CustomModal>
    </div>
  );
};

export default PartsBiddingDetails;
