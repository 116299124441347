import { createSlice } from '@reduxjs/toolkit';
import { loadCartData, saveCartData } from '../../Helper/utils/localStorage';

const initialState = loadCartData() || [];

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const { sellerSlug, sellerName, product } = action.payload || {}; // Default quantity to 1 if not provided
            const { quantity = 1 } = product;

            const existingSeller = state.find((item) => item.sellerSlug === sellerSlug);

            if (existingSeller) {
                const existingProduct = existingSeller.cartItem.find((item) => item.slug === product.slug);

                if (existingProduct) {
                    // If the product is already in the cart, increase its quantity
                    existingProduct.quantity += quantity;
                } else {
                    existingSeller.cartItem.push({ ...product, quantity, isSelected: true });
                }
            } else {
                // If the product is not in the cart, add it with the specified quantity
                state.push({ sellerSlug, sellerName, cartItem: [{ ...product, quantity, isSelected: true }] });
            }

            // Save the updated cart data to local storage
            saveCartData(state);
        },

        increase: (state, action) => {
            const slug = action.payload;

            state.map((item) =>
                item.cartItem.map((item) => {
                    if (item.slug === slug) {
                        return item.quantity++;
                    }
                    return item;
                })
            );

            // Save the updated cart data to local storage
            saveCartData(state);
        },

        decrease: (state, action) => {
            const slug = action.payload;

            state.map((item) =>
                item.cartItem.map((item) => {
                    if (item.slug === slug) {
                        return item.quantity--;
                    }
                    if (item.quantity < 1) {
                    }
                    return item;
                })
            );

            // Save the updated cart data to local storage
            saveCartData(state);
        },

        removeFromCart: (state, action) => {
            const { sellerSlug, productSlug } = action.payload;
            const sellerIndex = state.findIndex((seller) => seller.sellerSlug === sellerSlug);
            if (sellerIndex !== -1) {
                const seller = state[sellerIndex];
                const updatedCartItem = seller.cartItem.filter((item) => item.slug !== productSlug);
                if (updatedCartItem.length === 0) {
                    // If there are no items left in the seller's cart, remove the seller from the cart.
                    state.splice(sellerIndex, 1);
                } else {
                    // Otherwise, update the cart item for the seller.
                    state[sellerIndex].cartItem = updatedCartItem;
                }
            }

            saveCartData(state);
        },

        resetCart: () => {
            // Clear cart data from local storage
            localStorage.removeItem('cartData');
            // Reset the cart to an empty array
            return [];
        },

        toggleCartItemSelection: (state, action) => {
            const { sellerSlug, itemSlug } = action.payload;

            const sellerIndex = state.findIndex((seller) => seller.sellerSlug === sellerSlug);
            if (sellerIndex !== -1) {
                const seller = state[sellerIndex];
                const updatedCartItem = seller.cartItem.map((item) => {
                    if (item.slug === itemSlug) {
                        return {
                            ...item,
                            isSelected: !item.isSelected, // Toggle the isSelected property
                        };
                    }
                    return item;
                });
                state[sellerIndex].cartItem = updatedCartItem;
                // Save the updated cart to local storage
                saveCartData(state);
            }
        },
    },
});

export const { addToCart, increase, decrease, removeFromCart, resetCart, toggleCartItemSelection } = cartSlice.actions;
export default cartSlice.reducer;
