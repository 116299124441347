import { apiSlice } from '../api/apiSlice';

export const specialOffer = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSpecialOffer: builder.query({
            query: () => '/service/specialoffer/all',
        }),
    }),
});

export const { useGetSpecialOfferQuery } = specialOffer;
