import React from 'react';
import { Link } from 'react-router-dom';
import { apiImgUrl } from '../../../../Helper/BaseUrl';
import { DISCOUNT_TYPE } from '../../../../Helper/constant/coreConstant';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../../features/cart/cartSlice';

const PartsSellerCard = ({ sellerName, sellerSlug, product }) => {
    const dispatch = useDispatch();
    console.log(product);
    const { id, name, thumbnail, discount_type, discount, discount_price, selling_price, slug, dv_charge } = product || {};

    const cartData = {
        sellerName,
        sellerSlug,
        product: {
            id,
            slug,
            name,
            productImage: thumbnail,
            discount_price,
            selling_price,
            dv_charge,
        },
    };

    // const renderImage = () => {
    //     let content = null;
    //     if (all_image?.length > 0)
    //         all_image.map(
    //             (item) => (content = <img key={item.id} className='default-img-list' src={apiImgUrl + item.image} alt={item.name} />)
    //         );

    //     return content;
    // };

    // const discountRate = () => {
    //     const percentage = (discount / discount_price) * 100;
    //     if (percentage) {
    //         return percentage.toFixed(2);
    //     } else {
    //         return false;
    //     }
    // };

    let discountContent = null;

    if (Number(discount_type) === DISCOUNT_TYPE.DISCOUNT || Number(discount_type) === DISCOUNT_TYPE.PERCENT)
        discountContent = (
            <div className='product-price mt-10'>
                <span>${discount_price}</span>
                <span className='old-price'>${selling_price}</span>
            </div>
        );

    if (Number(discount_type) !== DISCOUNT_TYPE.PERCENT && Number(discount_type) !== DISCOUNT_TYPE.DISCOUNT)
        discountContent = (
            <div className='product-price mt-10'>
                <span>${selling_price}</span>
            </div>
        );

    return (
        <div className='product-cart-wrap'>
            {/* Product Image Section */}
            <div className='product-img-action-wrap'>
                <div className='product-img product-img-zoom'>
                    <Link to=''>
                        <img className='default-img-list' src={apiImgUrl + thumbnail} alt={thumbnail} />
                    </Link>
                </div>

                <div className='product-action-1'>
                    <Link aria-label='Quick view' className='action-btn small hover-up' to=''>
                        <i className='fi-rs-eye'></i>
                    </Link>
                    <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                        <i className='fi-rs-heart'></i>
                    </Link>
                    <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                        <i className='fi-rs-shuffle'></i>
                    </Link>
                </div>

                {discount_type === DISCOUNT_TYPE.PERCENT && (
                    <div className='product-badges product-badges-position product-badges-mrg'>
                        <span className='new'>Save {discount}%</span>
                    </div>
                )}
            </div>

            <div className='product-content-wrap'>
                {/* <div className='product-category'>
                    <Link className='text-ellipsis-1' to={`/parts-seller-details/${parts_seller?.slug}`}>
                        {parts_seller !== null || undefined ? parts_seller.shop_name : 'Parts Seller'}
                    </Link>
                </div> */}
                <h2 style={{ minHeight: 40, marginTop: 10 }}>
                    <Link className='text-ellipsis-2' to={`/product-details/${slug}`}>
                        {name}
                    </Link>
                </h2>
                <div className='product-rate d-inline-block'>
                    <div className='product-rating'></div>
                </div>

                {discountContent}
                {/* <div className='sold mt-15 mb-15'>
                    <div className='progress mb-5'>
                        <div className='progress-bar' role='progressbar' aria-valuemin='0' aria-valuemax='100'></div>
                    </div>
                    <span className='font-xs text-heading'> Sold: 90/120</span>
                </div> */}
                <Link to='' className='btn w-100 hover-up mt-10' onClick={() => dispatch(addToCart(cartData))}>
                    <i className='fi-rs-shopping-cart mr-5'></i>Add To Cart
                </Link>
            </div>
        </div>
    );
};

export default PartsSellerCard;
