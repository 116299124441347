import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SelectField } from '../../FormFields';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormikContext } from 'formik';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import EventData from "./events"
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment)
export default function ConfirmationForm(props) {
  console.log(EventData, "EventData EventData")
  const [age, setAge] = React.useState('');
  const [openSlot, setopenSlot] = useState(false)
  const { values: formValues } = useFormikContext();
  console.log(formValues, "formValues formValuesformValues")
  const handleSlotSelected = (slotInfo) => {
    console.log("Real slotInfo", slotInfo);
    setopenSlot(true)
  }
  const closemodal = () => {
    setopenSlot(false)
  }

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      Preferred Time
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Calendar
            localizer={localizer}
            events={EventData}
            views={["month", "week", "day",]}
            timeslots={2}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            selectable={true}
            onSelectSlot={slotInfo => handleSlotSelected(slotInfo)}
            style={{ height: 1000 }}
          />

          <Dialog onClose={closemodal} open={openSlot}>
            <form>
              <DialogTitle > Select You Services </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">Select Services</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={age}
                        onChange={handleChange}
                        label="Age"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Engine Repair</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <button className='ser__submit'> Submit </button>
                  </Grid>
                </Grid>
              </DialogContent>
            </form >
          </Dialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
