import React from 'react';
import { useGetServiceCenterQuery } from '../../../features/serviceCenter/serviceCenterApi';
import VendorListHeader from './VendorListHeader';
import VendorListItem from './VendorListItem';
import OwlCarousel from 'react-owl-carousel';

const options = {
    navText: ['Prev', 'Next'],
    responsive: {
        0: {
            items: 2,
        },
        450: {
            items: 2,
        },
        600: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1024: {
            items: 4,
        },
        1280: {
            items: 5,
        },
        1366: {
            items: 5,
        },
    },
};

function VendorList() {
    const { data, isSuccess } = useGetServiceCenterQuery();

    const { data: serviceCenter } = data?.data?.services_center || {};

    console.log(serviceCenter);

    let content = null;
    if (isSuccess) content = serviceCenter.map((vendor) => <VendorListItem vendor={vendor} />);

    return (
        <div>
            <div className='container'>
                <VendorListHeader />

                <div className='row vendor-grid'>
                    <OwlCarousel
                        className='owl-theme'
                        loop
                        margin={25}
                        nav={true}
                        dots={false}
                        autoplay={false}
                        autoplayTimeout={5000}
                        {...options}
                    >
                        {content}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
}

export default VendorList;
