import { apiSlice } from '../api/apiSlice';

export const productsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => '/seller/product/list',
        }),
    }),
});

export const { useGetProductsQuery } = productsApi;
