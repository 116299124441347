import React from "react";
import ProductGallery from "../../ProductDetails/ProductGallery";
import DriverDetailsInfo from "../../Services/booking/driver/DriverDetailsInfo";
import { Link } from "react-router-dom";

import CallIcon from "@mui/icons-material/Call";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
import ShareIcon from "@mui/icons-material/Share";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import GoogleMap from "../../../Components/common/GoogleMap";
import Headline from "../../../Components/common/Headline";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

const dataImg = [
  {
    image:
      "https://images.unsplash.com/photo-1473655587843-eda8944061e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
  },
];

const driverData = {
  id: 50,
  name: "Martin Luther",
  discount_price: 1000,
};

const RentCarBiddingSelectModal = () => {
  return (
    <div className="container mt-30">
      <div className="row">
        {/* col-lg-4-5 col-xl-10 col-lg-12 m-auto */}
        <div className="col-xl-12">
          <div className="product-detail accordion-detail">
            <div className="row mt-30">
              <div className="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                <ProductGallery dataImg={dataImg} />
              </div>

              <div className="col-md-8 col-sm-12 col-xs-12">
                <div className="detail-info pr-30 pl-30">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="title-detail">Toyota Corolla</h2>

                    <span className="d-flex align-items-center ">
                      <CallIcon
                        sx={{
                          fontSize: 22,
                          color: "black",
                        }}
                        className="text-brand"
                      />
                      <h5> 0174121447</h5>
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-4">
                    <div className="product-detail-rating pb-0">
                      <div className="product-rate-cover text-end">
                        <div className="product-rate d-inline-block">
                          <div className="product-rating"></div>
                        </div>
                        <Link to="#review" className="font-small ml-5">
                          {" "}
                          (32 reviews)
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex align-items-center flex-wrap gap-4">
                      <Link to="#" className="d-flex align-items-center gap-2">
                        <ShareIcon
                          sx={{
                            fontSize: 14,
                          }}
                          color="primary"
                        />
                        <p>Share</p>
                      </Link>

                      <Link to="#" className="d-flex align-items-center gap-2">
                        <FavoriteIcon
                          sx={{
                            fontSize: 14,
                            color: "red",
                          }}
                        />
                        <p>Favorite</p>
                      </Link>

                      <Link to="#" className="d-flex align-items-center gap-2">
                        <DirectionsCarIcon
                          sx={{
                            fontSize: 14,
                            color: "red",
                          }}
                        />
                        <p>Car Details</p>
                      </Link>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-xl-8">
                      <h5 className="mb-2">5 Seater luxury car</h5>
                      <h5 className="mb-2">Model Year: 2015</h5>
                      <h5 className="mb-2">Seat: 5</h5>
                      <h5 className="mb-2">Daily Rate: 1500</h5>

                      <div className="mb-2">
                        <span className="d-flex gap-2 mb-2">
                          <h5>
                            Location: 122, Ring Road, Adabor, Dhaka - 1207
                            <br />
                            <LocationOnIcon
                              sx={{
                                fontSize: 22,
                              }}
                              className="text-danger"
                            />{" "}
                            0.3km (Near by me)
                          </h5>
                        </span>
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <GoogleMap />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <Headline title="Bidding Details" />

          <div className="row">
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Bidding For
              </label>

              <h4>Rent A Car</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Trip
              </label>

              <h4>One way trip</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Vehicle Type
              </label>

              <h4>Heavy</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Select Vehicle
              </label>

              <h4>Minibus</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Pickup Date?
              </label>

              <h4>10/05/2023</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Time
              </label>

              <h4>10:00 AM</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                For how many days?
              </label>

              <h4>5</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                For which location?
              </label>

              <h4>Dhaka-Chittagong</h4>
            </div>

            <div className="col-xl-12 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Notes
              </label>

              <h4>Only Male Driver Allowed</h4>
            </div>
          </div>
        </div>

        <div className="col-xl-4  border-start">
          <Headline title="Bidder Price" />

          <div className="d-flex align-items-center justify-content-center flex-column h-75">
            <h3 className="mb-5">6000 BDT</h3>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Select as winner
              </label>
            </div>
          </div>
          {/* <table class="table">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Total Charges
            </label>

            <tbody>
              <tr>
                <td>Total Rate Charges</td>
                <td>4000</td>
              </tr>
              <tr>
                <td>Other Charges</td>
                <td>2000</td>
              </tr>
              <tr>
                <th scope="row">
                  <h6>Sub Total</h6>
                </th>
                <td className="fw-bold">
                  <h6>6000</h6>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};

export default RentCarBiddingSelectModal;
