import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'antd';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import ReactPlayer from "react-player";
import { VscClose } from "react-icons/vsc"
import axios from "../../Helper/Config";
import { apiImgUrl } from "../../Helper/BaseUrl";
import Product01 from "../../assets/images/product/p01.png";
import {RiEyeFill, RiHeartFill, RiShoppingCartLine} from "react-icons/ri";
import {DialogActions} from "@mui/material";

function ServicesSingleProductDetails({ }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState([])
    const options = {
        navText: ["Prev", "Next"],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 4,
            },
            1280: {
                items: 4,
            },
            1366: {
                items: 5,
            },
        }
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const closemodal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        axios.get("/service/active/all")
            .then((res) => {
                setData(res.data.data.services.data)
            }).catch((err) => {

            })
    }, [])
    const showModal = (id) => {
        setIsModalOpen(true);
        const dataFound = data?.filter(obj => { return obj.id === id })
        setModalData(dataFound[0])
    };
    return (
        <div>
            <div className='home_category_area'>
                <Container>
                    <Row>
                        <Col>
                            <div className='setion____title'>
                                <h3> Our <span> Services </span> </h3>
                            </div>
                            {data.length > 0 ? <>
                                <OwlCarousel className='owl-theme'
                                    // loop
                                    margin={25}
                                    nav={false}
                                    dots={false}
                                    autoplay={false}
                                    autoplayTimeout={5000}
                                    {...options}>
                                    {data.map((item, i) =>
                                        <div className='item' key={i}>
                                            <div className='single_parts_area'>
                                                <div className='image__body'>
                                                    <Link to={`/services-product-details/${item.slug}`}>
                                                        <img className='image__body img-fit' src={apiImgUrl + item?.images[0]?.content_path} alt={item?.name} />
                                                    </Link>
                                                </div>
                                                <div className='service_card__info' >
                                                    <div className='product-card__name'>
                                                        <Link to="/services-product"> {item?.name} </Link>
                                                    </div>
                                                </div>
                                                <div className='product-card__footer d-flex justify-content-between'>
                                                    <h3> ৳{item?.price} </h3>
                                                    <h3 onClick={() => showModal(item.id)}>Details</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </OwlCarousel>
                            </> : <>  </>}
                        </Col>
                    </Row>
                </Container>
                {/* SERVICES DETAILS  */}
                <Dialog  fullWidth={'md'} maxWidth={'md'} onClose={closemodal} open={isModalOpen}>
                    <form>
                        <DialogTitle className="dilog-title">
                            {modalData?.name}
                            <VscClose onClick={closemodal} className='m_cls_icon' />
                        </DialogTitle>
                        <div className='services__model_area'>
                            <DialogContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={8} sm={8}>
                                        <div dangerouslySetInnerHTML={{ __html: modalData?.description }}></div>
                                        <h3 className="text-danger"> Services Cost : {modalData?.price}/- </h3>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                      {modalData?.images === undefined ? <> </> : <> <img className="img-fit" src={apiImgUrl + modalData?.images[0]?.content_path} alt="" /> </>}  
                                    </Grid>
                                    {modalData?.videos?.length > 0 ? <>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <h3> Video's </h3>
                                                </Grid>
                                                {modalData?.videos?.map((vitem, i) =>
                                                    <Grid item xs={6} sm={6} key={i}>
                                                        <ReactPlayer
                                                            width="100%"
                                                            height="100%"
                                                            controls={true}
                                                            url={apiImgUrl + vitem?.content_path}
                                                            config={{ file: { attributes: { controlsList: "nodownload" } } }}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </> : <>  </>}
                                </Grid>
                            </DialogContent>
                            <DialogActions className="diloag-action">
                                <Button onClick={closemodal}>Close</Button>
                            </DialogActions>
                        </div>
                    </form >
                </Dialog>
            </div>
        </div>
    )
}

export default ServicesSingleProductDetails
