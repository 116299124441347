import React from "react";

const BasicCard = ({ title, children, image = "", classes, badge }) => {
  let badgeColor = null;
  let badgeTitle = null;

  switch (badge) {
    case "car":
      badgeColor = "bg-warning";
      badgeTitle = "Car Buy/Sell";
      break;

    case "garage":
      badgeColor = "bg-info";
      badgeTitle = "Garage";
      break;

    case "parts":
      badgeColor = "bg-success";
      badgeTitle = "Parts Seller";
      break;

    case "rent":
      badgeColor = "bg-danger";
      badgeTitle = "Rent A Car";
      break;

    case "mechanic":
      badgeColor = "bg-primary";
      badgeTitle = "Mechanic";
      break;

    default:
      badgeColor = "bg-brand";
      badgeTitle = "Driver";
      break;
  }
  return (
    <div className="card border rounded-2">
      <span className="position-relative">
        <img src={image} className="card-img-top" alt="..." />

        {badge && (
          <span
            className={`position-absolute top-0 start-0 ml-10 mt-10 badge rounded-pill ${badgeColor}  py-2 px-3`}
          >
            {badgeTitle}
          </span>
        )}
      </span>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default BasicCard;
