import React, { useEffect, useState } from 'react';

const SearchInput = ({ placeholder = 'Search', onSearch }) => {
    const [searchText, setSearchText] = useState('');

    const handleInputChange = (event) => {
        setSearchText(event.target.value);
    };

    useEffect(() => {
        // Trigger the search whenever the searchText changes
        onSearch(searchText);
    }, [searchText, onSearch]);

    return (
        <div>
            <input
                style={{ height: '45px', margin: ' 0 0 20px' }}
                type='text'
                placeholder={placeholder}
                value={searchText}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default SearchInput;
