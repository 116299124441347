import React, { useState } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import BookingStepContent from "./stepContent/BookingStepContent";
import ConfirmationStepContent from "./stepContent/ConfirmationStepContent";
import FinalStep from "./stepContent/FinalStep";
import BookingSuccess from "./stepContent/BookingSuccess";

const steps = ["Booking", "Confirmation", "Success"];

export default function BookingSteps({ alterLabel = false }) {
  const [activeStep, setActiveStep] = useState(0);
  console.log(activeStep);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <BookingStepContent />;
      case 1:
        return <ConfirmationStepContent />;
      case 2:
        return <FinalStep handleNext={handleNext} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel={alterLabel}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <BookingSuccess />
        </>
      ) : (
        <>
          {renderStepContent(activeStep)}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <button
              color="inherit"
              className="btn btn-secondary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </button>
            <Box sx={{ flex: "1 1 auto" }} />

            <button className="btn" onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </button>
          </Box>
        </>
      )}
    </Box>
  );
}
