import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../features/api/apiSlice';
import productSliceReducer from '../features/products/productsSlice';
import cartReducer from '../features/cart/cartSlice';

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        products: productSliceReducer,
        cart: cartReducer,
    },
    middleware: (getDefaultMiddleWares) => getDefaultMiddleWares().concat(apiSlice.middleware),
    devTools: process.env.NODE_ENV !== 'production',
});
