import React from "react";
import CompareLayout from "./CompareLayout";
import CallIcon from "@mui/icons-material/Call";
import { Link } from "react-router-dom";

const CarPartsCompare = () => {
  const images = [
    "https://images.unsplash.com/photo-1537462715879-360eeb61a0ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=938&q=80",
    "https://images.unsplash.com/photo-1429772011165-0c2e054367b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1486262715619-67b85e0b08d3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80",
  ];
  return (
    <CompareLayout>
      <div class="col-xl-10 col-lg-12 m-auto">
        <h1 class="heading-2 mb-10">Comparison For: Car Parts</h1>
        <h6 class="text-body mb-40">
          There are <span class="text-brand">3</span> products to compare
        </h6>
        <div class="table-responsive">
          <table class="table text-center table-compare">
            <tbody>
              {/* Image */}
              <tr class="pr_image">
                <td class="text-muted font-sm fw-600 font-heading mw-200">
                  Preview
                </td>
                <td class="row_img">
                  <img src={images[0]} alt="compare-img" />
                </td>
                <td class="row_img">
                  <img src={images[1]} alt="compare-img" />
                </td>
                <td class="row_img">
                  <img src={images[2]} alt="compare-img" />
                </td>
              </tr>
              {/* Title */}
              <tr class="pr_title">
                <td class="text-muted font-sm fw-600 font-heading">Title</td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Toyota Camry: 1MZ (DUAL VVT-I) Engine
                    </a>
                  </h6>
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Rear Window Side Spoiler
                    </a>
                  </h6>
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Android Navigation
                    </a>
                  </h6>
                </td>
              </tr>
              {/* description */}
              <tr class="description">
                <td class="text-muted font-sm fw-600 font-heading">
                  Description
                </td>
                <td class="row_text font-xs">
                  <p class="font-sm text-muted">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and
                  </p>
                </td>
                <td class="row_text font-xs">
                  <p class="font-sm text-muted">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and
                  </p>
                </td>
                <td class="row_text font-xs">
                  <p class="font-sm text-muted">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and
                  </p>
                </td>
              </tr>

              {/* Make */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Make</td>
                <td>Toyota</td>
                <td>Nissan</td>
                <td>KIA</td>
              </tr>

              {/* Model */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Model</td>
                <td>Corolla</td>
                <td>Sunny</td>
                <td>Sportage</td>
              </tr>

              {/* Manufacturing Year */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Manufacturing Year
                </td>
                <td>2015</td>
                <td>2011</td>
                <td>2010</td>
              </tr>

              {/* Registration Year */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Registration Year
                </td>
                <td>2018</td>
                <td>2016</td>
                <td>2012</td>
              </tr>

              {/* Location */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Location</td>
                <td>Dhaka</td>
                <td>Dhaka</td>
                <td>Chittagong</td>
              </tr>

              {/* Sold By */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Sold By</td>
                <td>
                  <h6 className="mb-5">
                    <Link rel="stylesheet" to="#">
                      Euro Car Parts
                    </Link>
                  </h6>

                  <p>123, Adabor, Ring Road, Dhaka - 1207</p>

                  <p>
                    <CallIcon sx={{ fontSize: 17 }} />
                    0175613546
                  </p>
                </td>
                <td>
                  <h6 className="mb-5">
                    <Link rel="stylesheet" to="#">
                      Euro Car Parts
                    </Link>
                  </h6>

                  <p>123, Adabor, Ring Road, Dhaka - 1207</p>

                  <p>
                    <CallIcon sx={{ fontSize: 17 }} />
                    0175613546
                  </p>
                </td>
                <td>
                  <h6 className="mb-5">
                    <Link rel="stylesheet" to="#">
                      Euro Car Parts
                    </Link>
                  </h6>

                  <p>123, Adabor, Ring Road, Dhaka - 1207</p>

                  <p>
                    <CallIcon sx={{ fontSize: 17 }} />
                    0175613546
                  </p>
                </td>
              </tr>

              {/* Price */}
              <tr class="pr_price">
                <td class="text-muted font-sm fw-600 font-heading">Price</td>
                <td class="product_price">
                  <h4 class="price text-brand">$50000</h4>
                </td>
                <td class="product_price">
                  <h4 class="price text-brand">$1534</h4>
                </td>
                <td class="product_price">
                  <h4 class="price text-brand">$5000</h4>
                </td>
              </tr>
              <tr class="pr_remove text-muted">
                <td class="text-muted font-md fw-600"></td>
                <td class="row_remove">
                  <a href="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </a>
                </td>
                <td class="row_remove">
                  <a href="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </a>
                </td>
                <td class="row_remove">
                  <a href="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CompareLayout>
  );
};

export default CarPartsCompare;
