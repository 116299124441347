import React from 'react';
import { Link } from 'react-router-dom';
import { apiImgUrl } from '../../../Helper/BaseUrl';
import { useDispatch } from 'react-redux';
import { removeFromCart } from '../../../features/cart/cartSlice';

const DropdownCartItem = ({ item }) => {
    console.log(item);
    const { slug, name, productImage, discount_price, quantity } = item?.cartItem || {};
    const dispatch = useDispatch();

    const handleRemoveFromCart = () => {
        dispatch(removeFromCart({ sellerSlug: item.sellerSlug, productSlug: slug }));
    };

    return (
        <li>
            <div className='shopping-cart-img'>
                <Link to='shop-product-right.html'>
                    <img alt='Nest' src={apiImgUrl + productImage} />
                </Link>
            </div>
            <div className='shopping-cart-title'>
                <h4 className='text-ellipsis-2' style={{ maxWidth: 155 }}>
                    <Link to='shop-product-right.html'>{name}</Link>
                </h4>
                <h4>
                    <span>{quantity} × </span>${discount_price}
                </h4>
            </div>
            <div className='shopping-cart-delete' onClick={handleRemoveFromCart}>
                <Link to='#'>
                    <i className='fi-rs-cross-small'></i>
                </Link>
            </div>
        </li>
    );
};

export default DropdownCartItem;
