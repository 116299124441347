import React from "react";

const CartCoupon = () => {
  return (
    <div class="col-lg-5">
      <div class="p-40">
        <h4 class="mb-10">Apply Coupon</h4>
        <p class="mb-30">
          <span class="font-lg text-muted">Using A Promo Code?</span>
        </p>
        <form action="#">
          <div class="d-flex justify-content-between">
            <input
              class="font-medium mr-15 coupon"
              name="Coupon"
              placeholder="Enter Your Coupon"
            />
            <button class="btn">
              <i class="fi-rs-label mr-10"></i>Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CartCoupon;
