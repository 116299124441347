import React from 'react';

const ProductTabNav = () => {
    return (
        <ul className='nav nav-tabs text-uppercase'>
            <li className='nav-item'>
                <a className='nav-link /* active */' id='Description-tab' data-bs-toggle='tab' href='#Description'>
                    Description
                </a>
            </li>
            {/* <li className="nav-item">
        <a
          className="nav-link"
          id="Additional-info-tab"
          data-bs-toggle="tab"
          href="#Additional-info"
        >
          Additional info
        </a>
      </li> */}
            <li className='nav-item'>
                <a className='nav-link' id='Vendor-info-tab' data-bs-toggle='tab' href='#Vendor-info'>
                    Shop Info
                </a>
            </li>
            <li className='nav-item'>
                <a className='nav-link' id='Reviews-tab' data-bs-toggle='tab' href='#Reviews'>
                    Reviews (3)
                </a>
            </li>
        </ul>
    );
};

export default ProductTabNav;
