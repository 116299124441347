import React from "react";
import { Form, Select } from "antd";
import { Link } from "react-router-dom";
import HomeSlider from "../../../Components/Slider/HomeSlider";
import Headline from "../../../Components/common/Headline";

const BiddingDriverLanding = () => {
  return (
    <div>
      <HomeSlider />

      <div className="container">
        <Headline
          title="Recent Posted Bidding"
          endItem={
            <div className="d-flex align-items-center justify-content-center gap-3">
              <input
                type="text"
                style={{ height: "35px", width: 300 }}
                placeholder={`Search for...`}
              />

              <span>
                <select
                  class="form-select form-select-sm"
                  aria-label="Small select example"
                >
                  <option selected>Recently Added</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </span>
            </div>
          }
        />

        <div className="row">
          <div className="col-xl-2">
            <div className="primary-sidebar">
              <div className="sidebar-widget price_range range p-3 mb-30">
                <Form /* form={form} */ layout="vertical">
                  <Form.Item label="Category" tooltip="Select You Category">
                    <Select
                      className="w-100"
                      // onClick={(e) => categoryFunction(e)}
                      // onChange={(e) => categoryOnChangeFunction(e)}
                      allowClear
                      placeholder="Select your Category"
                    >
                      {/* {categoryList?.map((item, i) => (
                <Option key={i} value={`${item?.id}`}>
                  {item?.name}
                </Option>
              ))} */}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Sub Category"
                    tooltip="Select You Sub Category"
                  >
                    <Select
                      mode="multiple"
                      className="w-100"
                      // onChange={(e) => subCategoryFunction(e)}
                      placeholder="Select your Category"
                    >
                      {/* {subCategoryList?.map((item, i) => (
                <Option key={i} value={`${item?.id}`}>
                  {item?.name}
                </Option>
              ))} */}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Parts Type" tooltip="Select Parts Type">
                    <Select
                      className="w-100"
                      // onChange={(e) => partsTypeFunction(e)}
                      allowClear
                      placeholder="Select Parts type"
                    >
                      {/* {partsTypesList?.map((item, i) => (
                <Option key={i} value={`${item?.id}`}>
                  {item?.name}
                </Option>
              ))} */}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Brand" tooltip="Select Brand">
                    <Select
                      className="w-100"
                      // onChange={(e) => makeListOnchangFunction(e)}
                      placeholder="Brand"
                      allowClear
                      // options={makeList}
                    />
                  </Form.Item>
                  <Form.Item label="Model" tooltip="Select Model">
                    <Select
                      className="w-100"
                      // onChange={(e) => modelOnChangFunction(e)}
                      placeholder="Model"
                      allowClear
                      // options={modelList}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>

          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-6">
                <div
                  class="card mb-25"
                  style={{ background: "rgba(59, 183, 126, 0.05)" }}
                >
                  <div class="row g-0">
                    <div class="col-md-4">
                      <img
                        src="https://images.unsplash.com/photo-1614977645540-7abd88ba8e56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80"
                        class="img-fluid rounded-start h-100"
                        alt="..."
                      />
                    </div>

                    <div class="col-md-5">
                      <div class="card-body">
                        <div className="border-bottom pb-15 mb-10">
                          <h5 class="card-title mb-0">
                            <Link
                              className="text-heading"
                              to="/bidding/driver/details"
                            >
                              Honda Civic 2010
                            </Link>
                          </h5>

                          <h6>
                            <Link to="#">Car Engine</Link>
                          </h6>
                        </div>

                        <div>
                          <p className="card-text mb-0">
                            <strong>Condition:</strong> Used
                          </p>
                          <p className="card-text mb-0">
                            <strong>Milage:</strong> 79k
                          </p>
                          <p className="card-text mb-0">
                            <strong>Warranty:</strong> 48 Month
                          </p>
                          <p className="card-text mb-0">
                            <strong>Note: </strong> I want Warranty in this
                            product
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 border-start">
                      <span className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h5 className="mb-5">Current Bid</h5>
                        <h6 className="text-brand">BDT 70000</h6>

                        <Link
                          to="/bidding/driver/details"
                          className="btn btn-xs mt-3"
                        >
                          Bid Now
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div
                  class="card mb-25"
                  style={{ background: "rgba(59, 183, 126, 0.05)" }}
                >
                  <div class="row g-0">
                    <div class="col-md-4">
                      <img
                        src="https://images.unsplash.com/photo-1614977645540-7abd88ba8e56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80"
                        class="img-fluid rounded-start h-100"
                        alt="..."
                      />
                    </div>

                    <div class="col-md-5">
                      <div class="card-body">
                        <div className="border-bottom pb-15 mb-10">
                          <h5 class="card-title mb-0">
                            <Link
                              className="text-heading"
                              to="/bidding/driver/details"
                            >
                              Honda Civic 2010
                            </Link>
                          </h5>

                          <h6>
                            <Link to="#">Car Engine</Link>
                          </h6>
                        </div>

                        <div>
                          <p className="card-text mb-0">
                            <strong>Condition:</strong> Used
                          </p>
                          <p className="card-text mb-0">
                            <strong>Milage:</strong> 79k
                          </p>
                          <p className="card-text mb-0">
                            <strong>Warranty:</strong> 48 Month
                          </p>
                          <p className="card-text mb-0">
                            <strong>Note: </strong> I want Warranty in this
                            product
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 border-start">
                      <span className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h5 className="mb-5">Current Bid</h5>
                        <h6 className="text-brand">BDT 70000</h6>

                        <Link
                          to="/bidding/driver/details"
                          className="btn btn-xs mt-3"
                        >
                          Bid Now
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div
                  class="card mb-25"
                  style={{ background: "rgba(59, 183, 126, 0.05)" }}
                >
                  <div class="row g-0">
                    <div class="col-md-4">
                      <img
                        src="https://images.unsplash.com/photo-1614977645540-7abd88ba8e56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80"
                        class="img-fluid rounded-start h-100"
                        alt="..."
                      />
                    </div>

                    <div class="col-md-5">
                      <div class="card-body">
                        <div className="border-bottom pb-15 mb-10">
                          <h5 class="card-title mb-0">
                            <Link
                              className="text-heading"
                              to="/bidding/driver/details"
                            >
                              Honda Civic 2010
                            </Link>
                          </h5>

                          <h6>
                            <Link to="#">Car Engine</Link>
                          </h6>
                        </div>

                        <div>
                          <p className="card-text mb-0">
                            <strong>Condition:</strong> Used
                          </p>
                          <p className="card-text mb-0">
                            <strong>Milage:</strong> 79k
                          </p>
                          <p className="card-text mb-0">
                            <strong>Warranty:</strong> 48 Month
                          </p>
                          <p className="card-text mb-0">
                            <strong>Note: </strong> I want Warranty in this
                            product
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 border-start">
                      <span className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h5 className="mb-5">Current Bid</h5>
                        <h6 className="text-brand">BDT 70000</h6>

                        <Link
                          to="/bidding/driver/details"
                          className="btn btn-xs mt-3"
                        >
                          Bid Now
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div
                  class="card mb-25"
                  style={{ background: "rgba(59, 183, 126, 0.05)" }}
                >
                  <div class="row g-0">
                    <div class="col-md-4">
                      <img
                        src="https://images.unsplash.com/photo-1614977645540-7abd88ba8e56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80"
                        class="img-fluid rounded-start h-100"
                        alt="..."
                      />
                    </div>

                    <div class="col-md-5">
                      <div class="card-body">
                        <div className="border-bottom pb-15 mb-10">
                          <h5 class="card-title mb-0">
                            <Link
                              className="text-heading"
                              to="/bidding/driver/details"
                            >
                              Honda Civic 2010
                            </Link>
                          </h5>

                          <h6>
                            <Link to="#">Car Engine</Link>
                          </h6>
                        </div>

                        <div>
                          <p className="card-text mb-0">
                            <strong>Condition:</strong> Used
                          </p>
                          <p className="card-text mb-0">
                            <strong>Milage:</strong> 79k
                          </p>
                          <p className="card-text mb-0">
                            <strong>Warranty:</strong> 48 Month
                          </p>
                          <p className="card-text mb-0">
                            <strong>Note: </strong> I want Warranty in this
                            product
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 border-start">
                      <span className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h5 className="mb-5">Current Bid</h5>
                        <h6 className="text-brand">BDT 70000</h6>

                        <Link
                          to="/bidding/driver/details"
                          className="btn btn-xs mt-3"
                        >
                          Bid Now
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div class="loop-grid loop-list  mb-30 mt-30">
                  <article
                    class="wow fadeIn animated hover-up mb-30 animated"
                    style={{ background: "rgba(59, 183, 126, 0.05)" }}
                  >
                    <div
                      class="post-thumb"
                      style={{
                        backgroundImage:
                          "url('https://images.unsplash.com/photo-1614977645540-7abd88ba8e56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80')",
                        minHeight: 300,
                      }}
                    ></div>

                    <div class="py-2 px-4 pl-40 w-100">
                      <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 mb-3">
                        <span>
                          <h3 class="post-title mb-10">
                            <Link to="/bidding/driver/details">
                              Honda Civic 2010
                            </Link>
                          </h3>

                          <h5>
                            <Link to="#">Car Engine</Link>
                          </h5>
                        </span>

                        <span className="d-flex flex-column align-items-center">
                          <h5 className="mb-5">Current Bid</h5>
                          <h5 className="text-brand">BDT 70000</h5>
                        </span>
                      </div>

                      <hr />

                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <span>
                          <p>
                            <strong>Condition:</strong> Used
                          </p>
                          <p>
                            <strong>Milage:</strong> 79k
                          </p>
                          <p>
                            <strong>Warranty:</strong> 48 Month
                          </p>
                          <p>
                            <strong>Note: </strong> I want Warranty in this
                            product
                          </p>
                        </span>
                        <Link
                          to="/bidding/driver/details"
                          className="btn btn-xs mt-3 align-self-end"
                        >
                          Bid Now
                          <i className="fi-rs-arrow-small-right"></i>
                        </Link>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiddingDriverLanding;
