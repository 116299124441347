import React from 'react';
import HotDeals from './HotDeals';
import RecentProducts from './RecentProducts';
import SpecialOffer from './SpecialOffer';

function Deals() {
    return (
        <div>
            <section className='section-padding  mt-65 mb-65'>
                <div className='container'>
                    <div className='row'>
                        <HotDeals />
                        <SpecialOffer />
                        <RecentProducts />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Deals;
