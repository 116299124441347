import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from '../../Helper/Config';
import BiddingIcon from '../../assets/images/catagories/bidding_icon.jpg';
import driver from '../../assets/images/catagories/driver.webp';
import mechanic from '../../assets/images/catagories/mechanic2.png';
import parts_accessories from '../../assets/images/catagories/parts_accessories2.jpg';
import rent from '../../assets/images/catagories/rent2.jpg';
import serviceCenterLogo from '../../assets/images/catagories/serviceCenterLogo.jpg';

function ShopCategories() {
    const { slug } = useParams();
    const [data, setData] = useState([]);
    const options = {
        navText: ['Prev', 'Next'],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 4,
            },
            1280: {
                items: 4,
            },
            1366: {
                items: 10,
            },
        },
    };
    useEffect(() => {
        axios
            .get('/seller/parts/category')
            .then((res) => {
                setData(res.data.data.category);
            })
            .catch((err) => {});
    }, []);

    return (
        <div>
            <section className='banners mb-25'>
                <div className='container'>
                    <div className='section-title'>
                        <div className='title'>
                            <h4>Categories</h4>
                        </div>
                        <div
                            className='slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow'
                            id='carausel-10-columns-arrows'
                        ></div>
                    </div>
                    <div className='row'>
                        {/* New Featured Category */}
                        <div className='col-md-6 col-lg-4 pe-0'>
                            <div
                                className='card-2 mb-0 rounded-0 product-color-white wow animate__animated animate__fadeInUp item'
                                data-wow-delay='.1s'
                            >
                                <figure className='img-hover-scale overflow-hidden'>
                                    <Link to='/product-categories/2/all'>
                                        <img src={parts_accessories} alt='' />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to='/product-categories/'>Parts & Accessories</Link>
                                </h6>
                                <span>Total Parts & Accessories: 26</span>
                            </div>
                        </div>

                        <div className='col-md-6 col-lg-4 px-0'>
                            <div
                                className='card-2 mb-0 rounded-0 product-color-white wow animate__animated animate__fadeInUp'
                                data-wow-delay='.2s'
                            >
                                <figure className='img-hover-scale overflow-hidden'>
                                    <Link to='/services-product'>
                                        <img src={serviceCenterLogo} alt='' />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to='/services-product'>Service Center</Link>
                                </h6>
                                <span>Total Service Center: 28</span>
                            </div>
                        </div>

                        <div className='col-md-6 col-lg-4 ps-0'>
                            <div
                                className='card-2 mb-0 rounded-0 product-color-white wow animate__animated animate__fadeInUp'
                                data-wow-delay='.3s'
                            >
                                <figure className='img-hover-scale overflow-hidden'>
                                    <Link to='/booking/driver'>
                                        <img src={driver} alt='' />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to='/booking/driver'>Driver</Link>
                                </h6>
                                <span>Total Driver: 14</span>
                            </div>
                        </div>

                        <div className='col-md-6 col-lg-4 pe-0'>
                            <div
                                className='card-2 mb-0 rounded-0 product-color-white wow animate__animated animate__fadeInUp'
                                data-wow-delay='.4s'
                            >
                                <figure className='img-hover-scale overflow-hidden'>
                                    <Link to='/rent-car-booking'>
                                        <img src={rent} alt='' />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to='/rent-car-booking'>Rent</Link>
                                </h6>
                                <span>Total Vehicle: 54</span>
                            </div>
                        </div>

                        <div className='col-md-6 col-lg-4 px-0'>
                            <div
                                className=' card-2 mb-0 rounded-0 product-color-white wow animate__animated animate__fadeInUp'
                                data-wow-delay='.5s'
                            >
                                <figure className='img-hover-scale overflow-hidden'>
                                    <Link to='/booking/mechanic'>
                                        <img src={mechanic} alt='' />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to='/booking/mechanic'>Mechanic</Link>
                                </h6>
                                <span>Total Mechanic: 56</span>
                            </div>
                        </div>

                        <div className='col-md-6 col-lg-4 ps-0'>
                            <div
                                className=' card-2 mb-0 rounded-0 product-color-white wow animate__animated animate__fadeInUp'
                                data-wow-delay='.6s'
                            >
                                <figure className='img-hover-scale overflow-hidden'>
                                    <Link to='/user-bidding-list-v2'>
                                        <img src={BiddingIcon} alt='' />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to='/user-bidding-list-v2'>Bidding</Link>
                                </h6>
                                <span>Total Bid: 72</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ShopCategories;

/* <section className="popular-categories section-padding">
        <div className="container wow animate__animated animate__fadeIn">
          <div className="section-title">
            <div className="title">
              <h3>Categories</h3>
            </div>
            <div
              className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
              id="carausel-10-columns-arrows"
            ></div>
          </div>
          <div className="carausel-10-columns-cover position-relative">
            <div className="carausel-10-columns" id="carausel-10-columns">
              <OwlCarousel
                className="owl-theme"
                loop
                nav={true}
                dots={false}
                autoplay={false}
                autoplayTimeout={5000}
                {...options}
              >
                <div
                  className="card-2 bg-9 wow animate__animated animate__fadeInUp item"
                  data-wow-delay=".1s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat13} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Cake & Milk</Link>
                  </h6>
                  <span>26 items</span>
                </div>

                <div
                  className="card-2 bg-10 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat12} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Oganic Kiwi</Link>
                  </h6>
                  <span>28 items</span>
                </div>

                <div
                  className="card-2 bg-11 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".3s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat11} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Peach</Link>
                  </h6>
                  <span>14 items</span>
                </div>

                <div
                  className="card-2 bg-12 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".4s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat9} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Red Apple</Link>
                  </h6>
                  <span>54 items</span>
                </div>

                <div
                  className="card-2 bg-13 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat14} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Snack</Link>
                  </h6>
                  <span>56 items</span>
                </div>

                <div
                  className="card-2 bg-14 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".6s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat1} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Vegetables</Link>
                  </h6>
                  <span>72 items</span>
                </div>

                <div
                  className="card-2 bg-15 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".7s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat2} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Strawberry</Link>
                  </h6>
                  <span>36 items</span>
                </div>
                <div
                  className="card-2 bg-12 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".8s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat4} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Black plum</Link>
                  </h6>
                  <span>123 items</span>
                </div>
                <div
                  className="card-2 bg-10 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".9s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat5} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Custard apple</Link>
                  </h6>
                  <span>34 items</span>
                </div>
                <div
                  className="card-2 bg-12 wow animate__animated animate__fadeInUp"
                  data-wow-delay="1s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat14} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Coffe & Tea</Link>
                  </h6>
                  <span>89 items</span>
                </div>
                <div
                  className="card-2 bg-11 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0s"
                >
                  <figure className="img-hover-scale overflow-hidden">
                    <Link to="shop-grid-right.html">
                      <img src={cat5} alt="" />
                    </Link>
                  </figure>
                  <h6>
                    <Link to="shop-grid-right.html">Headphone</Link>
                  </h6>
                  <span>87 items</span>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section> */
