import React from 'react';
import { Link } from 'react-router-dom';
import { apiImgUrl } from '../../../Helper/BaseUrl';
import { DISCOUNT_TYPE } from '../../../Helper/constant/coreConstant';
import { addToCart } from '../../../features/cart/cartSlice';
import { useDispatch } from 'react-redux';

const ProductCategoryCard = ({ product }) => {
    const dispatch = useDispatch();
    const { id, name, slug, all_image, discount_type, discount, discount_price, selling_price, thumbnail, parts_seller, dv_charge } =
        product || {};

    const cartData = {
        sellerName: parts_seller.shop_name,
        sellerSlug: parts_seller.slug,
        product: {
            id,
            slug,
            name,
            productImage: all_image[0].image,
            discount_price,
            selling_price,
            dv_charge,
        },
    };

    console.log(product);

    let discountContent = null;

    if (Number(discount_type) === DISCOUNT_TYPE.DISCOUNT || Number(discount_type) === DISCOUNT_TYPE.PERCENT)
        discountContent = (
            <div className='product-price'>
                <span>{discount_price}</span>
                <span className='old-price'>${selling_price}</span>
            </div>
        );

    if (Number(discount_type) !== DISCOUNT_TYPE.PERCENT && Number(discount_type) !== DISCOUNT_TYPE.DISCOUNT)
        discountContent = (
            <div className='product-price'>
                <span>${selling_price}</span>
            </div>
        );

    return (
        <div key={slug} className='col-lg-3 col-md-4 col-12 col-sm-6'>
            <div className='product-cart-wrap mb-30 wow animate__animated'>
                <div className='product-img-action-wrap mb-15'>
                    <div className='product-img product-img-zoom'>
                        <Link to={`/product-details/${slug}`}>
                            <img className='default-img' src={apiImgUrl + thumbnail} alt='' />
                            <img className='hover-img' src={apiImgUrl + thumbnail} alt='' />
                        </Link>
                    </div>
                    <div className='product-action-1'>
                        <Link aria-label='Add To Wishlist' className='action-btn' to=''>
                            <i className='fi-rs-heart'></i>
                        </Link>
                        <Link aria-label='Compare' className='action-btn' to=''>
                            <i className='fi-rs-shuffle'></i>
                        </Link>
                        <Link aria-label='Quick view' className='action-btn' data-bs-toggle='modal' data-bs-target='#quickViewModal'>
                            <i className='fi-rs-eye'></i>
                        </Link>
                    </div>
                    <div className='product-badges product-badges-position product-badges-mrg'>
                        <span className='hot'>Hot</span>
                    </div>
                </div>
                <div className='product-content-wrap'>
                    <h2 className='text-ellipsis-2' style={{ minHeight: 40 }}>
                        <Link to={`/product-details/${slug}`}>{name}</Link>
                    </h2>
                    <div className='product-rate-cover'>
                        <div className='product-rate d-inline-block'>
                            <div className='product-rating'></div>
                        </div>
                        <span className='font-small ml-5 text-muted'> (5.0)</span>
                    </div>
                    <div>
                        <div className='font-small text-muted text-ellipsis-1'>
                            By <Link  to={`/parts-seller-details/${parts_seller.slug}`}>{parts_seller.shop_name}</Link>
                        </div>
                    </div>
                    <div className='product-card-bottom'>
                        {discountContent}

                        <div className='add-cart'>
                            <Link className='add' to='' onClick={() => dispatch(addToCart(cartData))}>
                                <i className='fi-rs-shopping-cart mr-5'></i>
                                Add{' '}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCategoryCard;
