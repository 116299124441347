import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useGetProductsQuery } from '../../../features/products/productsApi';
import LandingProductCard from '../../common/card/homePage/LandingProductCard';

const options = {
    navText: ['Prev', 'Next'],
    responsive: {
        0: {
            items: 2,
        },
        450: {
            items: 2,
        },
        600: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1024: {
            items: 4,
        },
        1280: {
            items: 5,
        },
        1366: {
            items: 5,
        },
    },
};

function PartsInventory() {
    const { data, isSuccess } = useGetProductsQuery();
    const products = data?.data?.product?.data;

    let content = null;
    if (isSuccess) content = products?.map((part) => <LandingProductCard key={part.id} product={part} />);

    return (
        <div>
            <section className='section-padding pb-5'>
                <div className='container'>
                    <div className='section-title wow animate__animated animate__fadeIn'>
                        <h4 className=''> Product Inventory </h4>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 wow animate__animated animate__fadeIn' data-wow-delay='.4s'>
                            <OwlCarousel
                                className='owl-theme'
                                loop
                                margin={25}
                                nav={true}
                                dots={false}
                                autoplay={false}
                                autoplayTimeout={5000}
                                {...options}
                            >
                                {content}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PartsInventory;
