import React from 'react'
import { Link } from 'react-router-dom'
import { Input, Form } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "../../Helper/Config";

function GeneralUserReg() {
    // const { Option } = Select;
    const [form] = Form.useForm();
    const redirect = useNavigate();
    const onFinish = (values) => {
        const obj = {
            first_name: `${values.first_name}`,
            last_name: `${values.last_name}`,
            username: `${values.username}`,
            email: `${values.email}`,
            phone_number: Number(`${values.phone_number}`),
            password: `${values.confirm_password}`
        }
        console.log(obj, "obj obj")
        axios.post("/auth/general-user-registration", obj, {
            header: {
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(res => {
                toast.dark(res.data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                // redirect("/login")
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        {
            errorInfo.errorFields.map((item) => toast.error(item.errors[0], {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            )
        }
    };
    return (
        <div className="login-21 ger___from__21">
            <div className="ger___from">
                <h3 className="page-title">General User Registration Form</h3>
                <Form
                    name="basic"
                    layout="vertical"
                    className='from_home_area'
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Form.Item
                                label="First Name"
                                name="first_name"
                                className="form-group form-box"
                                rules={[
                                    {
                                        max: 40,
                                        message: "Value should be less than 40 character",
                                    },
                                    {
                                        pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                        message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Item
                                label="Last Name"
                                name="last_name"
                                className="form-group form-box"
                                rules={[
                                    {
                                        max: 40,
                                        message: "Value should be less than 40 character",
                                    },
                                    {
                                        pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                        message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input Last Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Item
                                label="User Name"
                                name="username"
                                className="form-group form-box"
                                rules={[
                                    {
                                        max: 40,
                                        message: "Value should be less than 40 character",
                                    },
                                    {
                                        pattern: /^[a-zA-Z][A-Za-z0-9_]+$/,
                                        message: "You can introduce just alphabetical characters, underscore, number but no spaces"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input User Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Item
                                label="Email"
                                name="email"
                                className="form-group form-box"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        pattern: /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/,
                                        message: "Invalid email"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input Email!',
                                    },
                                ]}
                            >
                                <Input className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Item
                                label="Contact No"
                                name="phone_number"
                                maxLength={11}
                                className="form-group form-box"
                                rules={[
                                    { min: 11, message: "Please input valid Contact No" },
                                    { max: 11, message: "Please input valid Contact No" },
                                    {
                                        required: true,
                                        message: 'Please input Contact No!',
                                    },
                                ]}
                            >
                                <Input type="number" className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Item
                                name="password"
                                label="Password"
                                className="form-group form-box"
                                maxLength='5'
                                rules={[
                                    {
                                        max: 15,
                                        message: "Please input max 15 digit",
                                    },
                                    {
                                        min: 6,
                                        message: "Please input 6 digit",
                                    },
                                    {
                                        pattern: /(?=.*[a-z])/,
                                        message: "Please input one small letter"
                                    },
                                    {
                                        pattern: /(?=.*[A-Z])/,
                                        message: "Please input one capital"
                                    },
                                    {
                                        pattern: /(?=.*\d)/,
                                        message: "Please input one number"
                                    },
                                    {
                                        pattern: /[a-zA-Z\d]+/,
                                        message: "Only letters and digits allowed "
                                    },
                                    {
                                        pattern: /(?=.*?[#?!@$%^&*-])/,
                                        message: 'At least one special character!',
                                    },
                                    {
                                        validator: (_, value) =>
                                            // Two Space
                                            !value.includes(" ")
                                                ? Promise.resolve()
                                                : Promise.reject(new Error("No spaces allowed"))
                                    },
                                ]}
                                hasFeedback>
                                <Input.Password className="form-control" />
                            </Form.Item>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Item
                                name="confirm_password"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn-md btn-theme regBTn">Submit</button>
                            <p className='from__bottom_txt'>Don't have an account? <Link to="/login" className="thembo"> Login here</Link></p>
                        </div>
                    </div>
                </Form>
                <div className="clearfix"></div>
            </div>
        </div>
    )
}

export default GeneralUserReg
