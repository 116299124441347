import React from 'react'
import { Input, Form } from 'antd';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import axios from "../Helper/Config";
import { baseurl } from "../Helper/BaseUrl"
function ForgotPassword() {
    const [form] = Form.useForm();
    console.log(baseurl, "currentURL currentURL")
    const onFinish = (e) => {
        const sender_email = e.sender_email
        const obj = {
            email: sender_email,
            base_url: baseurl
        }
        axios.post("/auth/forget-password/request", obj)
            .then((res) => {
                const reset_email = baseurl + res.data.data.token
                // emailjs.send('service_gr0bk95', 'template_lgmk988', { reset_email }, 'F3_D4kE_5garNRmzy')
                emailjs.send('service_gr0bk95', 'template_lgmk988', { sender_email, reset_email }, 'F3_D4kE_5garNRmzy')
                    .then((result) => {
                        console.log(result.text);
                        toast.dark("Please Check Your Gmail", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }, (error) => {
                        toast.error(error.text, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                if (res.data.code === 404) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }

            }).catch((err) => {
                console.log(err.res.data.message)
                toast.error(err.res.data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
    };
    // emailjs.send('service_gr0bk95', 'template_lgmk988', { reset_email}, 'F3_D4kE_5garNRmzy')
    // .then((result) => {
    //     console.log(result.text);
    //     toast.dark("Please Check Your Gmail", {
    //         position: toast.POSITION.BOTTOM_RIGHT
    //     });
    // }, (error) => {
    //     toast.error(error.text, {
    //         position: toast.POSITION.BOTTOM_RIGHT
    //     });
    // });
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div className='forget__area__'>
            <div className='forgot_password_area'>
                <h3> Reset Your Password </h3>
                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  </p>
                <Form
                    name="basic"
                    layout="vertical"
                    className='from_home_area'
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Form.Item
                                name="sender_email"
                                className="form-group form-box"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Email!',
                                    },
                                ]}
                            >
                                <Input placeholder='Email' className="form-control" />
                            </Form.Item>
                        </div>
                        <button className='button_login'> Submit </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword