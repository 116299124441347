import React, { useState } from "react";
import OtpModal from "../../../../../../Components/common/modal/OtpModal";
import ConfirmationStepContent from "./ConfirmationStepContent";

const FinalStep = ({ handleNext }) => {
  const [modal, setModal] = useState(true);

  const handleCLick = () => {
    handleNext();
    setModal(false);
  };

  return (
    <span>
      <ConfirmationStepContent />
      {modal && (
        <>
          <OtpModal onClose={handleCLick} onSubmit={handleCLick} />
        </>
      )}
    </span>
  );
};

export default FinalStep;
