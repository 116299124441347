import React from "react";

const CompareLayout = ({ children }) => {
  return (
    <main class="main">
      <div class="page-header breadcrumb-wrap">
        <div class="container">
          <div class="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i class="fi-rs-home mr-5"></i>Home
            </a>
            <span></span> Shop <span></span> Compare
          </div>
        </div>
      </div>
      <div class="container mb-80 mt-50">
        <div class="row">{children}</div>
      </div>
    </main>
  );
};

export default CompareLayout;
