import React from 'react';
import { useGetSpecialOfferQuery } from '../../../features/specialOffer/specialOfferApi';
import DealsCard from '../../common/card/homePage/DealsCard';

const SpecialOffer = () => {
    const { data, isSuccess } = useGetSpecialOfferQuery();
    const { special_offer } = data?.data || {};

    let content = null;

    if (isSuccess) content = special_offer.slice(0, 3).map((item) => <DealsCard key={item.id} product={item} />);
    return (
        <div className='col-xl-4 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp' data-wow-delay='0'>
            <h4 className='section-title style-1 mb-30 animated animated'> Special Offer </h4>
            <div className='product-list-small animated animated'>
                {content}
                {/* <article className='row align-items-center hover-up'>
                    <figure className='col-md-4 mb-0'>
                        <Link to=''>
                            <img src={product1} alt='' />
                        </Link>
                    </figure>
                    <div className='col-md-8 mb-0'>
                        <h6>
                            <Link to='shop-product-right.html'>car engine parts offer</Link>
                        </h6>
                        <div className='product-rate-cover'>
                            <div className='product-rate d-inline-block'>
                                <div className='product-rating'></div>
                            </div>
                            <span className='font-small ml-5 text-muted'> (4.0)</span>
                        </div>
                        <div className='product-price'>
                            <span>$32.85</span>
                            <span className='old-price'>$33.8</span>
                        </div>
                    </div>
                </article>
                <article className='row align-items-center hover-up'>
                    <figure className='col-md-4 mb-0'>
                        <Link to=''>
                            <img src={product4} alt='' />
                        </Link>
                    </figure>
                    <div className='col-md-8 mb-0'>
                        <h6>
                            <Link to=''>car engine parts offer</Link>
                        </h6>
                        <div className='product-rate-cover'>
                            <div className='product-rate d-inline-block'>
                                <div className='product-rating'></div>
                            </div>
                            <span className='font-small ml-5 text-muted'> (4.0)</span>
                        </div>
                        <div className='product-price'>
                            <span>$32.85</span>
                            <span className='old-price'>$33.8</span>
                        </div>
                    </div>
                </article>
                <article className='row align-items-center hover-up'>
                    <figure className='col-md-4 mb-0'>
                        <Link to=''>
                            <img src={product2} alt='' />
                        </Link>
                    </figure>
                    <div className='col-md-8 mb-0'>
                        <h6>
                            <Link to=''>car engine parts offer</Link>
                        </h6>
                        <div className='product-rate-cover'>
                            <div className='product-rate d-inline-block'>
                                <div className='product-rating'></div>
                            </div>
                            <span className='font-small ml-5 text-muted'> (4.0)</span>
                        </div>
                        <div className='product-price'>
                            <span>$32.85</span>
                            <span className='old-price'>$33.8</span>
                        </div>
                    </div>
                </article> */}
            </div>
        </div>
    );
};

export default SpecialOffer;
