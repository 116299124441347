import React from 'react';
import { useDispatch } from 'react-redux';
import { decrease, increase, removeFromCart, toggleCartItemSelection } from '../../../features/cart/cartSlice';
import { apiImgUrl } from '../../../Helper/BaseUrl';

const SummaryItem = ({ item }) => {
    const dispatch = useDispatch();

    console.log(item);

    const { slug, name, productImage, discount_price, quantity } = item.cartItem || {};

    // console.log(item);

    const handleQuantityIncrease = () => {
        dispatch(increase(slug));
    };
    const handleQuantityDecrease = () => {
        if (quantity > 1) {
            dispatch(decrease(slug));
        }
    };
    const handleRemoveItem = () => {
        dispatch(removeFromCart({ sellerSlug: item.sellerSlug, productSlug: slug }));
    };

    const handleToggleSelection = ({ sellerSlug, itemSlug }) => {
        dispatch(toggleCartItemSelection({ sellerSlug, itemSlug }));
    };

    return (
        <tr>
            <td class='custome-checkbox pl-30'>
                <input
                    class='form-check-input'
                    type='checkbox'
                    name='checkbox'
                    id={`checkbox_${slug}`}
                    // value='asd'
                    checked={item?.cartItem.isSelected}
                    onClick={() => handleToggleSelection({ sellerSlug: item.sellerSlug, itemSlug: slug })}
                />
                <label class='form-check-label' for={`checkbox_${slug}`}></label>
            </td>
            <td class='image product-thumbnail pt-40'>
                <img src={apiImgUrl + productImage} alt={name} />
            </td>
            <td class='product-des product-name'>
                <h6 class='mb-5'>
                    <a class='product-name mb-10 text-heading' href='shop-product-right.html'>
                        {name}
                    </a>
                </h6>
                {/* <div class='product-rate-cover'>
                    <div class='product-rate d-inline-block'>
                        <div class='product-rating' style={{ width: '90%' }}></div>
                    </div>
                    <span class='font-small ml-5 text-muted'> (4.0)</span>
                </div> */}
            </td>
            <td class='price' data-title='Price'>
                <h4 class='text-body'>${discount_price} </h4>
            </td>
            <td class='text-center detail-info' data-title='Stock'>
                <div class='detail-extralink mr-15'>
                    <div class='detail-qty border radius'>
                        <a href='#down' class='qty-down' onClick={handleQuantityDecrease}>
                            <i class='fi-rs-angle-small-down'></i>
                        </a>
                        <input type='text' name='quantity' class='qty-val' value={quantity} min='1' />

                        <a href='#up' class='qty-up' onClick={handleQuantityIncrease}>
                            <i class='fi-rs-angle-small-up'></i>
                        </a>
                    </div>
                </div>
            </td>
            <td class='price' data-title='Price'>
                <h4 class='text-brand'>${discount_price * quantity} </h4>
            </td>
            <td class='action text-center' data-title='Remove' onClick={handleRemoveItem}>
                <a href='#remove' class='text-body'>
                    <i class='fi-rs-trash'></i>
                </a>
            </td>
        </tr>
    );
};

export default SummaryItem;
