import { apiSlice } from '../api/apiSlice';

export const recentProducts = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRecentProducts: builder.query({
            query: () => '/seller/product/recent/list',
        }),
    }),
});

export const { useGetRecentProductsQuery } = recentProducts;
