import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { RiCloseLine, RiHome2Line, RiUser3Line } from "react-icons/ri";
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Space } from 'antd';
import AuthService from "../../Helper/Auth.Services"
import TopBanner from "../../assets/images/top_banner.jpg"
import Logo from "../../assets/images/logo.png"
import useAxiosPrivate from "../../Helper/useAxiosPrivate"
import { BiSearch } from "react-icons/bi";
// import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from 'react-toastify';
// COMPONENTS
import Cart from "../Products/Cart"
import Search from "./Search"
import MobileCatagories from "./MobileCatagories"
function HeaderOld() {
    const userActive = AuthService.getCurrentUser();
    const axiosPrivate = useAxiosPrivate();
    const redirect = useNavigate()
    const [BannerShow, setBannerShow] = useState(true)
    const [searchRfile, setsearchRfile] = useState(false)
    const [ActiveToken, setActiveToken] = useState(false)
    const [cUserName, setUserNAme] = useState([])
    const location = useLocation()
    const string = location.pathname.includes("product-categories");
    if(string === true) {
        console.log("00000000 00000 00000000")
    } else {
        localStorage.removeItem("categories_id"); 
        localStorage.removeItem("locationDefault"); 
        localStorage.removeItem("locationId"); 
        localStorage.removeItem("make_id"); 
        localStorage.removeItem("model_id"); 
        localStorage.removeItem("max_price"); 
        localStorage.removeItem("min_price"); 
        localStorage.removeItem("parts_type"); 
        localStorage.removeItem("subcategory_id"); 
    }
    // const axiosPrivate = useAxiosPrivate();
    useEffect(() => {
        if (Number(userActive) === 0) {
            setActiveToken(false)
        } else {
            setActiveToken(true)
            const UserName = localStorage.getItem("Username") || " "
            setUserNAme(UserName)
        }
    }, [userActive])
    const bannerOff = () => {
        setBannerShow(false)
    }
    const searchBtn = () => {
        setsearchRfile(!searchRfile)
    }
    const LogoutFunction = () => {
        // console.log(userLogout, "userLogoutuserLogout")
        axiosPrivate.post("/auth/logout")
            .then(res => {
                console.log(res.data.message)
                toast.dark(res.data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                AuthService.logout()
                redirect("/")
            })
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link to="/"> Account Setting </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <button onClick={LogoutFunction} className='logout__btn'> Logout </button>
                    ),
                },
            ]}
        />
    );
    // console.log(ActiveToken, "ActiveToken ActiveToken")

    return (
        <div style={{ width: "100%" }}>
            {BannerShow ? <>
                <div className='top_banner_area'>
                    <Container>
                        <Row>
                            <Col lg={12} style={{ position: "relative" }}>
                                <img src={TopBanner} alt="" />
                                <RiCloseLine onClick={bannerOff} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </> : <>  </>}
            <div className='logo_bottom_menu_area'>
                <div>
                    <div className='logo_serach_area'>
                        <Container>
                            <Row>
                                <Col xs={8} lg={4}>
                                    <div className='header_logo'>
                                        <Link to="/"> <img className='img-fit' src={Logo} alt="" /></Link>
                                        <Link to="/neearest-shop-locations">
                                            <div className='location_btn'>
                                                <span className="location-description">Your Location</span>
                                                <div className="location_text">Neearest Shop Locations</div>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={4} lg={8}>
                                    <div className='rasponsive__menu_icon'>
                                        <MobileCatagories />
                                    </div>
                                    <div className='rasponsive__search_icon' onClick={searchBtn}>
                                        <BiSearch />
                                    </div>

                                    <div className='search_all_area__full'>
                                        <Search />
                                    </div>

                                </Col>
                                {searchRfile ? <>
                                    <Col xs={12} lg={12}>
                                        <div className='movile_view_search'>
                                            <Search />
                                        </div>
                                    </Col>
                                </> : <>  </>}
                            </Row>
                        </Container>
                    </div>
                    <div className='top_header_menu'>
                        <Container>
                            <ul>
                                <li><Link className='color__hdr' to="/"> Home</Link></li>
                                <li><Link className='color__hdr' to="/about-us">About</Link></li>
                                <li><Link to="/services-product">Services</Link></li>
                                <li><Link to="/">Shop</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                {ActiveToken === true ? <>
                                    <div className='profile_menu_header'>
                                        <Dropdown overlay={menu}>
                                            <Space>
                                                {cUserName}
                                                <DownOutlined />
                                            </Space>
                                        </Dropdown>
                                    </div>
                                </> : <>
                                    <li><Link to="/login">Login</Link></li>
                                    <li><Link to="/registration">Registration</Link></li>
                                </>}
                            </ul>
                        </Container>
                    </div>
                    <div className='movile__bottom_fixted_menu'>
                        <Container>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='box__one'>
                                    <Link to="/"> <RiHome2Line /> <br></br> Home </Link>
                                </div>
                                <div className='box__one'>
                                    <Link to="/cart">

                                        <Cart /><br></br> Cart </Link>
                                </div>
                                <div className='box__one'>
                                    <Link to="/"> <RiUser3Line /> <br></br> Profile </Link>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderOld
