import React from 'react';
import FeaturedProducts from '../Components/Products/FeaturedProducts';
import FlashSale from '../Components/Products/FlashSale';
import PartsInventory from '../Components/Products/PartsInventory';
import ShopCategories from '../Components/Products/ShopCategories';
import VendorList from '../Components/Products/VendorList';
import HomeSlider from '../Components/Slider/HomeSlider';
import Deals from '../Components/Products/Deals';

function HomePages() {
    return (
        <div>
            <HomeSlider />
            <ShopCategories />
            {/* <HomeProducts /> */}
            <FeaturedProducts />
            {/*<Brands />*/}
            {/*<HotDeals />*/}
            <FlashSale />
            <PartsInventory />
            <Deals />
            <VendorList />
            {/*<CarService />*/}
        </div>
    );
}

export default HomePages;
