import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { useGetFeaturedProductsQuery } from '../../features/featuredProducts/featuredProductsApi';
import { FEATURED_PRODUCTS_OPTION } from '../../Helper/constant/coreConstant';
import LandingProductCard from '../common/card/homePage/LandingProductCard';

function FeaturedProducts() {
    const { data, isSuccess } = useGetFeaturedProductsQuery();
    const { Product } = data?.data || {};

    let content = null;
    if (isSuccess && Product.length > 0) content = Product.map((product) => <LandingProductCard key={product.id} product={product} />);

    return (
        <div>
            <section className='section-padding pb-5'>
                <div className='container'>
                    <div className='section-title wow animate__animated animate__fadeIn'>
                        <h4 className=''> Featured Products </h4>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 d-none d-lg-flex wow animate__animated animate__fadeIn'>
                            <div className='banner-img style-2' style={{height : '480px'}}>
                                <div className='banner-text'>
                                    <h2 className='mb-100' style={{ color: '#3BB77E' }}>
                                        Let's make life easy
                                    </h2>
                                    <Link to='shop-grid-right.html' className='btn btn-xs'>
                                        Shop Now <i className='fi-rs-arrow-small-right'></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-12 wow animate__animated animate__fadeIn' data-wow-delay='.4s'>
                            <OwlCarousel
                                className='owl-theme'
                                loop
                                margin={25}
                                nav={true}
                                dots={false}
                                autoplay={false}
                                autoplayTimeout={5000}
                                {...FEATURED_PRODUCTS_OPTION}
                            >
                                {content}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FeaturedProducts;
