import React from "react";
import Headline from "../Headline";

const PaymentStepContent = () => {
  return (
    <div className="mt-50">
      <Headline title="Payment Details" />

      <div className="product-info">
        <div className="tab-style3">
          <ul className="nav nav-tabs text-uppercase">
            <li className="nav-item">
              <a
                className="nav-link /* active */"
                id="Description-tab"
                data-bs-toggle="tab"
                href="#Rates"
              >
                Pay Now
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                id="Additional-info-tab"
                data-bs-toggle="tab"
                href="#Additional-info"
              >
                Pay Later
              </a>
            </li>
          </ul>

          <div className="tab-content shop_info_tab entry-main-content">
            <div className="tab-pane fade show active" id="Rates">
              <div className="w-50">
                <div className="d-flex flex-column flex-wrap gap-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Full Payment
                      </label>
                    </div>

                    <h5>9000 BDT</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Advance Payment
                      </label>
                    </div>

                    <div className="text-end">
                      <h5>900 BDT</h5>
                      <span>(10% of Full Payment)</span>
                    </div>
                  </div>
                </div>

                <div>
                  <h6 className="mb-3">Payment Type</h6>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>bKash</option>
                    <option value="1">Card</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="Additional-info">
              <div className="w-50">
                <div className="d-flex flex-column flex-wrap gap-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Full Payment
                      </label>
                    </div>

                    <h5>9000 BDT</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Advance Payment
                      </label>
                    </div>

                    <div className="text-end">
                      <h5>900 BDT</h5>
                      <span>(10% of Full Payment)</span>
                    </div>
                  </div>
                </div>

                <div>
                  <h6 className="mb-3">Payment Type</h6>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>bKash</option>
                    <option value="1">Card</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStepContent;
