import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/imgs/theme/logo.jpg";
import Banner9 from "../../assets/imgs/banner/banner-9.png";
import Icon1 from "../../assets/imgs/theme/icons/icon-1.svg";
import Icon2 from "../../assets/imgs/theme/icons/icon-2.svg";
import Icon3 from "../../assets/imgs/theme/icons/icon-3.svg";
import Icon4 from "../../assets/imgs/theme/icons/icon-4.svg";
import Icon5 from "../../assets/imgs/theme/icons/icon-5.svg";
import Address from "../../assets/imgs/theme/icons/icon-location.svg";
import Contact from "../../assets/imgs/theme/icons/icon-contact.svg";
import Email from "../../assets/imgs/theme/icons/icon-email-2.svg";
import Clock from "../../assets/imgs/theme/icons/icon-clock.svg";
import PhoneCall from "../../assets/imgs/theme/icons/phone-call.svg";
import Facebook from "../../assets/imgs/theme/icons/icon-facebook-white.svg";
import Lnkedin from "../../assets/imgs/theme/icons/linkdin2.png";
import Instagram from "../../assets/imgs/theme/icons/icon-instagram-white.svg";
import Pinterest from "../../assets/imgs/theme/icons/icon-pinterest-white.svg";
import Youtube from "../../assets/imgs/theme/icons/icon-youtube-white.svg";
import banner1 from "../../assets/sliderImg/bannerfooter1.png";
import banner2 from "../../assets/sliderImg/footerSlider3.png";
function Footer() {
  return (
    <div>
      <footer className="main">
        <section className="newsletter mb-15 wow animate__animated animate__fadeIn">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="position-relative newsletter-inner">
                  <div className="newsletter-content">
                    <h2 className="mb-20">
                      Most of the vehicles get damaged <br />
                      just because of maintenance
                    </h2>
                    <p className="mb-45">
                      Start You'r Journey with{" "}
                      <span className="text-brand">ZontropaTI</span>
                    </p>
                    {/* <form className="form-subcriber d-flex">
                        <input type="email" placeholder="Your emaill address"/>
                        <button className="btn" type="submit">Subscribe</button>
                      </form> */}
                    <Link to="/registration" className="btn rounded-pill">
                      Register Now
                    </Link>
                  </div>
                  <img
                    src={banner2}
                    alt="newsletter"
                    style={{ height: "100%", width: "95%" ,maxWidth : "46%",right:"0px"}}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-padding footer-mid">
          <div className="container pt-15 pb-20">
            <div className="row">
              <div className="col">
                <div
                  className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0"
                >
                  <div className="logo mb-30">
                    <a href="index.html" className="mb-15">
                      <img src={Logo} alt="logo" />
                    </a>
                  </div>
                  <ul className="contact-infor">
                    <li>
                      <img src={Address} alt="" />
                      <strong>Address: </strong>{" "}
                      <span>
                        House#383 (4th Floor), Road#28, Mohakhali DOSH, Dhaka-1206, Bangladesh
                      </span>
                    </li>
                    <li>
                      <img src={Contact} alt="" />
                      <strong>Call Us:</strong>
                      <span>(+880) - 1538020500</span>
                    </li>
                    <li>
                      <img src={Email} alt="" />
                      <strong>Email:</strong>
                      <span>info@itech.net</span>
                    </li>
                    <li>
                      <img src={Clock} alt="" />
                      <strong>Hours:</strong>
                      <span>10:00 - 18:00, Mon - Sat</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="footer-link-widget col wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                <h4 className=" widget-title">Company</h4>
                <ul className="footer-list mb-sm-5 mb-md-0">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="/privacy-policy">Privacy Policy</Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="#">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="#">Careers</Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
              {/*<div*/}
              {/*  className="footer-link-widget col wow animate__animated animate__fadeInUp"*/}
              {/*  data-wow-delay=".2s"*/}
              {/*>*/}
                {/*<h4 className="widget-title">Account</h4>*/}
                {/*<ul className="footer-list mb-sm-5 mb-md-0">*/}
                {/*  <li>*/}
                {/*    <Link to="#">Sign In</Link>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <Link to="#">View Cart</Link>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <Link to="#">My Wishlist</Link>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <Link to="#">Track My Order</Link>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <Link to="#">Help Ticket</Link>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <Link to="#">Shipping Details</Link>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <Link to="#">Compare products</Link>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              {/*</div>*/}
              <div
                className="footer-link-widget col wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
              >
                <h4 className="widget-title">Terms & Conditions</h4>
                <ul className="footer-list mb-sm-5 mb-md-0">
                  <li>
                    <Link to="#">Terms &amp; Conditions</Link>
                  </li>
                </ul>
              </div>




              <div
                className="footer-link-widget col wow animate__animated animate__fadeInUp"
                data-wow-delay=".4s"
              >
                <h4 className="widget-title">Privacy Policy</h4>
                <ul className="footer-list mb-sm-5 mb-md-0">
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </section>
        <div
          className="container pb-30 wow animate__animated animate__fadeInUp"
          data-wow-delay="0"
        >
          <div className="row align-items-center">
            <div className="col-12 mb-30">
              <div className="footer-bottom"></div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <p className="font-sm mb-0">
                &copy; 2022, <strong className="text-brand">iTechss</strong> - Information Technology Solution & Services<br />
                All rights reserved
              </p>
            </div>
            <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
              <div className="hotline d-lg-inline-flex">
                <img src={PhoneCall} alt="hotline" />
                <p style={{fontSize : '18px'}}>
                  +880-1538020500<span>24/7 Support Center</span>
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
              <div className="mobile-social-icon">
                <h6>Follow Us</h6>
                <Link to="https://www.facebook.com/iTechss.3P">
                  <img src={Facebook} alt="" />
                </Link>
                <Link to="https://www.linkedin.com/company/itechss/">
                  <img src={Lnkedin} alt="" />
                </Link>
                {/*<Link to="#">*/}
                {/*  <img src={Instagram} alt="" />*/}
                {/*</Link>*/}
                {/*<Link to="#">*/}
                {/*  <img src={Pinterest} alt="" />*/}
                {/*</Link>*/}
                {/*<Link to="#">*/}
                {/*  <img src={Youtube} alt="" />*/}
                {/*</Link>*/}
              </div>
              <p className="font-sm">
                Up to 15% discount on your first subscribe
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
