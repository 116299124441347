import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import ServiceDetailsModal from "../../../Components/common/modal/ServiceDetailsModal";
import SpecialOfferItem from "./SpecialOfferItem";
import OfferDetailsModal from "../../../Components/common/modal/OfferDetailsModal";
const DUMMY_DATA = [
  {
    id: 1,
    title: "50 percent discount",
    image:
      "https://images.unsplash.com/photo-1607082349566-187342175e2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus inventore doloremque ab earum aliquid pariatur vitae corporis sed dolores cumque adipisci ullam tempora, perferendis sit maxime voluptate vel, soluta eos placeat, deleniti laudantium nam dolor numquam est. Blanditiis cumque sequi nostrum. Doloremque ullam necessitatibus, reprehenderit libero dolor laudantium unde explicabo!",
    price: 3500,
  },
  {
    id: 2,
    title: "Huge Sale",
    image:
      "https://images.unsplash.com/photo-1607082352121-fa243f3dde32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus inventore doloremque ab earum aliquid pariatur vitae corporis sed dolores cumque adipisci ullam tempora, perferendis sit maxime voluptate vel, soluta eos placeat, deleniti laudantium nam dolor numquam est. Blanditiis cumque sequi nostrum. Doloremque ullam necessitatibus, reprehenderit libero dolor laudantium unde explicabo!",
    price: 5500,
  },
  {
    id: 3,
    title: "Special Deal",
    image:
      "https://images.unsplash.com/photo-1527264935190-1401c51b5bbc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus inventore doloremque ab earum aliquid pariatur vitae corporis sed dolores cumque adipisci ullam tempora, perferendis sit maxime voluptate vel, soluta eos placeat, deleniti laudantium nam dolor numquam est. Blanditiis cumque sequi nostrum. Doloremque ullam necessitatibus, reprehenderit libero dolor laudantium unde explicabo!",
    price: 10000,
  },
];

const SpecialOffer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClick = (service) => {
    setData(service);
    setIsModalOpen(true);
  };

  return (
    <>
      <h5 class="section-title style-1 mb-30">Special Offer</h5>

      {isModalOpen && (
        <OfferDetailsModal data={data} onClose={() => setIsModalOpen(false)} />
      )}
      <Container>
        <Row class="vendor-grid">
          {DUMMY_DATA.map((service) => (
            <SpecialOfferItem
              key={service.id}
              onClick={handleClick}
              service={service}
            />
          ))}
        </Row>
      </Container>
    </>
  );
};

export default SpecialOffer;
