import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DISCOUNT_TYPE } from '../../Helper/constant/coreConstant';
import { addToCart, decrease, increase } from '../../features/cart/cartSlice';
import { useDispatch } from 'react-redux';

const DetailInfo = ({ product }) => {
    const dispatch = useDispatch();
    const {
        id,
        name,
        discount,
        discount_type,
        discount_price,
        selling_price,
        short_description,
        product_code,
        stock,
        returnAvailable,
        warranty,
        make,
        parts_type,
        slug,
        all_image,
        parts_seller,
        dv_charge,
    } = product;

    const [quantity, setQuantity] = useState(1);

    console.log(quantity);

    const cartData = {
        sellerName: parts_seller.shop_name,
        sellerSlug: parts_seller.slug,
        product: {
            id,
            slug,
            name,
            productImage: all_image[0].image,
            discount_price,
            selling_price,
            dv_charge,
            quantity,
        },
    };

    const handleQuantityIncrease = () => {
        setQuantity((prevState) => prevState + 1);
        // dispatch(increase(slug));
    };

    const handleQuantityDecrease = () => {
        if (quantity > 1) {
            setQuantity((prevState) => prevState - 1);
            // dispatch(decrease(slug));
        }
    };

    let discountContent = null;

    if (Number(discount_type) === DISCOUNT_TYPE.DISCOUNT)
        discountContent = (
            <div className='clearfix product-price-cover'>
                <div className='product-price primary-color float-left'>
                    <span className='current-price text-brand'>${discount_price}</span>
                    <span>
                        {/* <span className='save-price font-md color3 ml-15'>26% Off</span> */}
                        <span className='old-price font-md ml-15'>${selling_price}</span>
                    </span>
                </div>
            </div>
        );

    if (Number(discount_type) === DISCOUNT_TYPE.PERCENT)
        discountContent = (
            <div className='clearfix product-price-cover'>
                <div className='product-price primary-color float-left'>
                    <span className='current-price text-brand'>${discount_price}</span>
                    <span>
                        <span className='save-price font-md color3 ml-15'>{discount}% Off</span>
                        <span className='old-price font-md ml-15'>${selling_price}</span>
                    </span>
                </div>
            </div>
        );

    if (Number(discount_type) !== DISCOUNT_TYPE.PERCENT && discount_price === 0)
        discountContent = (
            <div className='clearfix product-price-cover'>
                <div className='product-price primary-color float-left'>
                    <span className='current-price text-brand'>${selling_price}</span>
                </div>
            </div>
        );

    return (
        <div className='detail-info pr-30 pl-30'>
            {discount_price && <span className='stock-status out-stock'> Sale Off </span>}

            <h3 className='title-detail'>{name}</h3>
            <div className='product-detail-rating'>
                <div className='product-rate-cover text-end'>
                    <div className='product-rate d-inline-block'>
                        <div className='product-rating'></div>
                    </div>
                    <span className='font-small ml-5 text-muted'> (32 reviews)</span>
                </div>
            </div>

            {discountContent}

            {/* <div className='clearfix product-price-cover'>
                <div className='product-price primary-color float-left'>
                    {discount_price && <span className='current-price text-brand'>${discount_price}</span>}
                    <span>
                        {discount_type === DISCOUNT_TYPE.PERCENT && (
                            <span className='save-price font-md color3 ml-15'>{discount}% Off</span>
                        )}
                        {selling_price && <span className='old-price font-md ml-15'>${selling_price}</span>}
                    </span>
                </div>
            </div> */}

            <div className='short-desc mb-30'>
                <p className='font-lg'>{short_description}</p>
            </div>

            {/* <div className='attr-detail attr-size mb-30'>
                <strong className='mr-10'>Size / Weight: </strong>
                <ul className='list-filter size-filter font-small'>
                    <li>
                        <Link to='#'>50g</Link>
                    </li>
                    <li className='active'>
                        <Link to='#'>60g</Link>
                    </li>
                    <li>
                        <Link to='#'>80g</Link>
                    </li>
                    <li>
                        <Link to='#'>100g</Link>
                    </li>
                    <li>
                        <Link to='#'>150g</Link>
                    </li>
                </ul>
            </div> */}

            <div className='detail-extralink mb-50'>
                <div className='detail-qty border radius'>
                    <Link href='#' className='qty-down' onClick={handleQuantityDecrease}>
                        <i className='fi-rs-angle-small-down'></i>
                    </Link>
                    <input type='text' name='quantity' className='qty-val' value={quantity} min='1' />
                    <Link to='#' className='qty-up' onClick={handleQuantityIncrease}>
                        <i className='fi-rs-angle-small-up'></i>
                    </Link>
                </div>
                <div className='product-extra-link2'>
                    <button type='submit' className='button button-add-to-cart' onClick={() => dispatch(addToCart(cartData))}>
                        <i className='fi-rs-shopping-cart'></i>Add to cart
                    </button>

                    <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                        <i className='fi-rs-heart'></i>
                    </Link>

                    <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                        <i className='fi-rs-shuffle'></i>
                    </Link>
                </div>
            </div>
            <div className='font-xs'>
                <ul className='mr-50 float-start'>
                    <li className='mb-5'>
                        Stock:
                        <span className='in-stock text-brand ml-5'>
                            {stock > 1 ? `${stock} Items` : `${stock} Item`} {/* In Stock */}
                        </span>
                    </li>
                    <li className='mb-5'>
                        Type:<span className='text-brand'> {parts_type?.name}</span>
                    </li>
                    <li>
                        Brand: <span className='text-brand'>{make?.name}</span>
                    </li>
                </ul>
                <ul className='float-start'>
                    <li className='mb-5'>
                        SKU: <span className='text-brand'>{product_code}</span>
                    </li>
                    <li className='mb-5'>
                        Warranty: <span className='text-brand'>{warranty ? warranty : 'No'}</span>
                    </li>

                    <li>
                        Return Available:
                        <span className='in-stock text-brand ml-5'>{returnAvailable ? returnAvailable : 'No'}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DetailInfo;
