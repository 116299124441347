import React from "react";
import CompareLayout from "./CompareLayout";
import { Link } from "react-router-dom";

const BuySellCompare = () => {
  const images = [
    "https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80",
    "https://images.unsplash.com/photo-1549927681-0b673b8243ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1511919884226-fd3cad34687c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
  ];
  return (
    <CompareLayout>
      <div class="col-xl-10 col-lg-12 m-auto">
        <h1 class="heading-2 mb-10">Comparison For: Buy/Sell Car</h1>
        <h6 class="text-body mb-40">
          There are <span class="text-brand">3</span> products to compare
        </h6>
        <div class="table-responsive">
          <table class="table text-center table-compare">
            <tbody>
              {/* Image */}
              <tr class="pr_image">
                <td class="text-muted font-sm fw-600 font-heading mw-200">
                  Preview
                </td>
                <td class="row_img">
                  <img src={images[0]} alt="compare-img" />
                </td>
                <td class="row_img">
                  <img src={images[1]} alt="compare-img" />
                </td>
                <td class="row_img">
                  <img src={images[2]} alt="compare-img" />
                </td>
              </tr>
              {/* Title */}
              <tr class="pr_title">
                <td class="text-muted font-sm fw-600 font-heading">
                  Vehicle Type
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Sedan
                    </a>
                  </h6>
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Suv
                    </a>
                  </h6>
                </td>
                <td class="product_name">
                  <h6>
                    <a href="shop-product-full.html" class="text-heading">
                      Hatchback
                    </a>
                  </h6>
                </td>
              </tr>
              {/* Model */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Model</td>
                <td>Corolla</td>
                <td>Sunny</td>
                <td>Sportage</td>
              </tr>

              {/* Transmission */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Transmission
                </td>
                <td>Automatic</td>
                <td>Automatic</td>
                <td>Automatic</td>
              </tr>

              {/* Fuel */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Fuel</td>
                <td>CNG</td>
                <td>CNG</td>
                <td>CNG</td>
              </tr>

              {/* No of Seats */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  No of Seats
                </td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
              </tr>

              {/* Manufacturing Year */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Manufacturing Year
                </td>
                <td>2015</td>
                <td>2011</td>
                <td>2010</td>
              </tr>

              {/* Registration Year */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Registration Year
                </td>
                <td>2018</td>
                <td>2016</td>
                <td>2012</td>
              </tr>

              {/* KM */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">KM</td>
                <td>22000</td>
                <td>32000</td>
                <td>50000</td>
              </tr>

              {/* Color */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Color</td>
                <td>White</td>
                <td>Red Wine</td>
                <td>Red</td>
              </tr>

              {/* CC */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">CC</td>
                <td>1500</td>
                <td>1800</td>
                <td>1500</td>
              </tr>

              {/* Location */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Location</td>
                <td>Dhaka</td>
                <td>Dhaka</td>
                <td>Chittagong</td>
              </tr>

              {/* Price */}
              <tr class="pr_price">
                <td class="text-muted font-sm fw-600 font-heading">Price</td>
                <td class="product_price">
                  <h4 class="price text-brand">$2200000</h4>
                </td>
                <td class="product_price">
                  <h4 class="price text-brand">$1500000</h4>
                </td>
                <td class="product_price">
                  <h4 class="price text-brand">$900000</h4>
                </td>
              </tr>
              <tr class="pr_remove text-muted">
                <td class="text-muted font-md fw-600"></td>
                <td class="row_remove">
                  <Link to="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </Link>
                </td>
                <td class="row_remove">
                  <Link to="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </Link>
                </td>
                <td class="row_remove">
                  <Link to="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CompareLayout>
  );
};

export default BuySellCompare;
