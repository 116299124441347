import { apiSlice } from '../api/apiSlice';

export const productsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPartsAccessories: builder.query({
            query: ({ search, make, model, category, subcategory, partstype, location, min, max, pages }) =>
                `/seller/product/list?search=${search}&make_id=${make}&model_id=${model}&categories_id=${category}&subcategory_id=${subcategory}&parts_type=${partstype}&district_id=${location}&min_price=${min}&max_price=${max}&page=${pages}`,
        }),

        getPartAccessory: builder.query({
            query: (slug) => {
                console.log('Received slug:', slug); // Add this line
                return `/seller/product/details/${slug}`;
            },
        }),
    }),
});

export const { useGetPartsAccessoriesQuery, useGetPartAccessoryQuery } = productsApi;
