import React from 'react';
import { apiImgUrl } from '../../../Helper/BaseUrl';
import AuthorTwo from '../../../assets/imgs/blog/author-2.png';

const userPic = {
    userOne:
        'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80',
    userTwo:
        'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80',
    userThree:
        'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2048&q=80',
};

const ProductTabContent = ({ product }) => {
    const { description, parts_seller, title, slug } = product;

    console.log(`${apiImgUrl}${parts_seller?.shop_logo}`);
    return (
        <div className='tab-content shop_info_tab entry-main-content'>
            <div className='tab-pane fade show active' id='Description'>
                <div className=''>
                    <p>{description}</p>

                    <h4 className='mt-30'>Packaging & Delivery</h4>
                    <hr className='wp-block-separator is-style-wide' />
                    <p>
                        Less lion goodness that euphemistically robin expeditiously bluebird smugly scratched far while thus cackled
                        sheepishly rigid after due one assenting regarding censorious while occasional or this more crane went more as this
                        less much amid overhung anathematic because much held one exuberantly sheep goodness so where rat wry well
                        concomitantly.
                    </p>
                    <p>
                        Scallop or far crud plain remarkably far by thus far iguana lewd precociously and and less rattlesnake contrary
                        caustic wow this near alas and next and pled the yikes articulate about as less cackled dalmatian in much less well
                        jeering for the thanks blindly sentimental whimpered less across objectively fanciful grimaced wildly some wow and
                        rose jeepers outgrew lugubrious luridly irrationally attractively dachshund.
                    </p>
                    <h4 className='mt-30'>Suggested Use</h4>
                    <ul className='product-more-infor mt-30'>
                        <li>Refrigeration not necessary.</li>
                        <li>Stir before serving</li>
                    </ul>
                    <h4 className='mt-30'>Other Ingredients</h4>
                    <ul className='product-more-infor mt-30'>
                        <li>Organic raw pecans, organic raw cashews.</li>
                        <li>This butter was produced using a LTG (Low Temperature Grinding) process</li>
                        <li>Made in machinery that processes tree nuts but does not process peanuts, gluten, dairy or soy</li>
                    </ul>
                    <h4 className='mt-30'>Warnings</h4>
                    <ul className='product-more-infor mt-30'>
                        <li>Oil separation occurs naturally. May contain pieces of shell.</li>
                    </ul>
                </div>
            </div>
            <div className='tab-pane fade' id='Additional-info'>
                <table className='font-md'>
                    <tbody>
                        <tr className='stand-up'>
                            <th>Stand Up</th>
                            <td>
                                <p>35″L x 24″W x 37-45″H(front to back wheel)</p>
                            </td>
                        </tr>
                        <tr className='folded-wo-wheels'>
                            <th>Folded (w/o wheels)</th>
                            <td>
                                <p>32.5″L x 18.5″W x 16.5″H</p>
                            </td>
                        </tr>
                        <tr className='folded-w-wheels'>
                            <th>Folded (w/ wheels)</th>
                            <td>
                                <p>32.5″L x 24″W x 18.5″H</p>
                            </td>
                        </tr>
                        <tr className='door-pass-through'>
                            <th>Door Pass Through</th>
                            <td>
                                <p>24</p>
                            </td>
                        </tr>
                        <tr className='frame'>
                            <th>Frame</th>
                            <td>
                                <p>Aluminum</p>
                            </td>
                        </tr>
                        <tr className='weight-wo-wheels'>
                            <th>Weight (w/o wheels)</th>
                            <td>
                                <p>20 LBS</p>
                            </td>
                        </tr>
                        <tr className='weight-capacity'>
                            <th>Weight Capacity</th>
                            <td>
                                <p>60 LBS</p>
                            </td>
                        </tr>
                        <tr className='width'>
                            <th>Width</th>
                            <td>
                                <p>24″</p>
                            </td>
                        </tr>
                        <tr className='handle-height-ground-to-handle'>
                            <th>Handle height (ground to handle)</th>
                            <td>
                                <p>37-45″</p>
                            </td>
                        </tr>
                        <tr className='wheels'>
                            <th>Wheels</th>
                            <td>
                                <p>12″ air / wide track slick tread</p>
                            </td>
                        </tr>
                        <tr className='seat-back-height'>
                            <th>Seat back height</th>
                            <td>
                                <p>21.5″</p>
                            </td>
                        </tr>
                        <tr className='head-room-inside-canopy'>
                            <th>Head room (inside canopy)</th>
                            <td>
                                <p>25″</p>
                            </td>
                        </tr>
                        <tr className='pa_color'>
                            <th>Color</th>
                            <td>
                                <p>Black, Blue, Red, White</p>
                            </td>
                        </tr>
                        <tr className='pa_size'>
                            <th>Size</th>
                            <td>
                                <p>M, S</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='tab-pane fade' id='Vendor-info'>
                <div className='vendor-logo d-flex mb-30'>
                    <img height={50} src={`${apiImgUrl}${parts_seller?.shop_logo}`} alt={title} />
                    <div className='vendor-name ml-15'>
                        <h6>
                            <a href={`/parts-seller-details/${slug}`}>{parts_seller?.shop_name}</a>
                        </h6>
                        <div className='product-rate-cover'>
                            {/*  text-end */}
                            <div className='product-rate d-inline-block'>
                                <div className='product-rating' style={{ width: '90%' }}></div>
                            </div>
                            <span className='font-small ml-5 text-muted'> (32 reviews)</span>
                        </div>
                    </div>
                </div>
                <ul className='contact-information mb-50'>
                    <li>
                        {/* <img src="assets/imgs/theme/icons/icon-location.svg" alt="" /> */}
                        <strong>Address: </strong>
                        <span>{parts_seller?.address}</span>
                    </li>
                    <li>
                        {/* <img src="assets/imgs/theme/icons/icon-contact.svg" alt="" /> */}
                        <strong>Contact Seller: </strong>
                        <span> {parts_seller?.phone}</span>
                    </li>
                </ul>
                <div className='d-flex mb-55'>
                    <div className='mr-30'>
                        <p className='text-brand font-xs'>Rating</p>
                        <h4 className='mb-0'>92%</h4>
                    </div>
                    <div className='mr-30'>
                        <p className='text-brand font-xs'>Ship on time</p>
                        <h4 className='mb-0'>100%</h4>
                    </div>
                    <div>
                        <p className='text-brand font-xs'>Chat response</p>
                        <h4 className='mb-0'>89%</h4>
                    </div>
                </div>
                <p>
                    Noodles & Company is an American fast-casual restaurant that offers international and American noodle dishes and pasta
                    in addition to soups and salads. Noodles & Company was founded in 1995 by Aaron Kennedy and is headquartered in
                    Broomfield, Colorado. The company went public in 2013 and recorded a $457 million revenue in 2017.In late 2018, there
                    were 460 Noodles & Company locations across 29 states and Washington, D.C.
                </p>
            </div>
            <div className='tab-pane fade' id='Reviews'>
                <div className='comments-area'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h4 className='mb-30'>Customer questions & answers</h4>
                            <div className='comment-list'>
                                <div className='single-comment justify-content-between d-flex mb-30'>
                                    <div className='user justify-content-between d-flex'>
                                        <div className='thumb text-center'>
                                            <img height={80} width={80} src={userPic.userOne} alt='' />
                                            <a href='#reply' className='font-heading text-brand'>
                                                Sienna
                                            </a>
                                        </div>
                                        <div className='desc'>
                                            <div className='d-flex justify-content-between mb-10'>
                                                <div className='d-flex align-items-center'>
                                                    <span className='font-xs text-muted'>December 4, 2022 at 3:12 pm </span>
                                                </div>
                                                {/* <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style="width: 100%"
                          ></div>
                        </div> */}
                                            </div>
                                            <p className='mb-10'>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, suscipit exercitationem
                                                accusantium obcaecati quos voluptate nesciunt facilis itaque modi commodi dignissimos sequi
                                                repudiandae minus ab deleniti totam officia id incidunt?{' '}
                                            </p>

                                            <a href='#reply' className='reply'>
                                                Reply
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='single-comment justify-content-between d-flex mb-30 ml-30'>
                                    <div className='user justify-content-between d-flex'>
                                        <div className='thumb text-center'>
                                            <img height={80} width={80} src={userPic.userTwo} alt='' />
                                            <a href='#reply' className='font-heading text-brand'>
                                                Brenna
                                            </a>
                                        </div>
                                        <div className='desc'>
                                            <div className='d-flex justify-content-between mb-10'>
                                                <div className='d-flex align-items-center'>
                                                    <span className='font-xs text-muted'>December 4, 2022 at 3:12 pm </span>
                                                </div>
                                                {/* <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style="width: 80%"
                          ></div>
                        </div> */}
                                            </div>
                                            <p className='mb-10'>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, suscipit exercitationem
                                                accusantium obcaecati quos voluptate nesciunt facilis itaque modi commodi dignissimos sequi
                                                repudiandae minus ab deleniti totam officia id incidunt?{' '}
                                            </p>
                                            <a href='#reply' className='reply'>
                                                Reply
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='single-comment justify-content-between d-flex'>
                                    <div className='user justify-content-between d-flex'>
                                        <div className='thumb text-center'>
                                            <img height={80} width={80} src={userPic.userThree} alt='' />
                                            <a href='#reply' className='font-heading text-brand'>
                                                Gemma
                                            </a>
                                        </div>
                                        <div className='desc'>
                                            <div className='d-flex justify-content-between mb-10'>
                                                <div className='d-flex align-items-center'>
                                                    <span className='font-xs text-muted'>December 4, 2022 at 3:12 pm </span>
                                                </div>
                                                {/* <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style="width: 80%"
                          ></div>
                        </div> */}
                                            </div>
                                            <p className='mb-10'>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, suscipit exercitationem
                                                accusantium obcaecati quos voluptate nesciunt facilis itaque modi commodi dignissimos sequi
                                                repudiandae minus ab deleniti totam officia id incidunt?{' '}
                                            </p>
                                            <a href='#reply' className='reply'>
                                                Reply
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <h4 className='mb-30'>Customer reviews</h4>
                            <div className='mb-30'>
                                <div className='product-rate d-inline-block mr-15'>
                                    <div className='product-rating' style={{ width: '90%' }}></div>
                                </div>
                                <h6>4.8 out of 5</h6>
                            </div>

                            <div className='progress'>
                                <span>5 star</span>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: '50%' }}
                                    aria-valuenow='50'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                >
                                    50%
                                </div>
                            </div>
                            <div className='progress'>
                                <span>4 star</span>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: '25%' }}
                                    aria-valuenow='25'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                >
                                    25%
                                </div>
                            </div>
                            <div className='progress'>
                                <span>3 star</span>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: '45%' }}
                                    aria-valuenow='45'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                >
                                    45%
                                </div>
                            </div>
                            <div className='progress'>
                                <span>2 star</span>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: '65%' }}
                                    aria-valuenow='65'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                >
                                    65%
                                </div>
                            </div>
                            <div className='progress mb-30'>
                                <span>1 star</span>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: '85%' }}
                                    aria-valuenow='85'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                >
                                    85%
                                </div>
                            </div>
                            <a href='#reply' className='font-xs text-muted'>
                                How are ratings calculated?
                            </a>
                        </div>
                    </div>
                </div>

                <div className='comment-form'>
                    <h4 className='mb-15'>Add a review</h4>
                    <div className='product-rate d-inline-block mb-30'></div>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12'>
                            <form className='form-contact comment_form' action='#' id='commentForm'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group'>
                                            <textarea
                                                className='form-control w-100'
                                                name='comment'
                                                id='comment'
                                                cols='30'
                                                rows='9'
                                                placeholder='Write Comment'
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <input className='form-control' name='name' id='name' type='text' placeholder='Name' />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <input className='form-control' name='email' id='email' type='email' placeholder='Email' />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='form-group'>
                                            <input className='form-control' name='website' id='website' type='text' placeholder='Website' />
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <button type='submit' className='button button-contactForm'>
                                        Submit Review
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductTabContent;
