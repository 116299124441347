import React from "react";
import { Link } from "react-router-dom";

import CallIcon from "@mui/icons-material/Call";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import GoogleMap from "../../../Components/common/GoogleMap";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const MechanicProfileInfo = ({ product }) => {
  const { name } = product;



  return (
    <div className="detail-info pr-30 pl-30">
      {/* <span className="stock-status out-stock"> Sale Off </span> */}
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="title-detail">{name}</h2>

        <span className="d-flex align-items-center gap-1">
          <CallIcon
            sx={{
              fontSize: 24,
              color: "black",
            }}
            className="text-brand"
          />
          <h4> 0174121447</h4>
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap gap-4 pt-3">
        <div className="product-detail-rating pb-0 pt-0">
          <div className="product-rate-cover text-end">
            <div className="product-rate d-inline-block">
              <div className="product-rating"></div>
            </div>
            <Link to="#review" className="font-small ml-5">
              {" "}
              (32 reviews)
            </Link>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap gap-4">
          <Link
            to="shop-grid-right.html"
            className="d-flex align-items-center gap-2"
          >
            <ShareIcon
              sx={{
                fontSize: 14,
              }}
              color="primary"
            />
            <p>Share</p>
          </Link>

          <Link
            to="shop-grid-right.html"
            className="d-flex align-items-center gap-2"
          >
            <FavoriteIcon
              sx={{
                fontSize: 14,
                color: "red",
              }}
            />
            <p>Favorite</p>
          </Link>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-xl-8">
          <h4 className="mb-2"><AccountCircleIcon className="text-primary" /> Years of experience: 2</h4>
          <span className="d-flex align-items-center gap-2 mb-2">
            <TaskAltIcon
              sx={{
                fontSize: 24,
              }}
              className="text-brand"
            />
            <h4>Jobs: 50</h4>
          </span>
          <div className="mb-2">
            <span className="d-flex align-items-start gap-2 mb-2">
              <LocationOnIcon
                sx={{
                  fontSize: 24,
                }}
                className="text-danger"
              />
              <h4>Location: 122, Ring Road, Adabor, Dhaka - 1207
              <br />
              0.3 Km (Near by me)

              </h4>
            </span>
          </div>
        </div>

        <div className="col-xl-4">
          <GoogleMap />
        </div> 
      </div>
    </div>
  );
};

export default MechanicProfileInfo;
