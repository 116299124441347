import React from "react";
import { Link, useParams } from "react-router-dom";

const ServiceDetailsModal = ({
  modalTitle = "Service Detail",
  onClose,
  onSubmit,
  data,
  type,
}) => {
  const { slug } = useParams();

  const { title, image, description, price } = data;

  const modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0, .7)",
  };

  console.log(data);
  return (
    <div class="modal fade show" style={modalStyle}>
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{title}</h3>

            <div>
              <Link
                className="hover-up btn btn-sm"
                to={`/services-booking/${slug}`}
              >
                <span> Book Service Schedule </span>
              </Link>

              {/* <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={onClose}
              ></button> */}
            </div>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-8">
                  <p className="mb-30">{description}</p>
                  <p className="mb-30">{description}</p>

                  <h6 className="mb-60">Service Cost: {price}</h6>

                  <h6 className="mb-15">Video</h6>

                  <div className="d-flex gap-3">
                    <img
                      className="border-radius-10"
                      src={image}
                      alt={title}
                      width={200}
                    />{" "}
                    <img
                      className="border-radius-10"
                      src={image}
                      alt={title}
                      width={200}
                    />
                  </div>
                </div>
                <div class="col-4">
                  <img className="border-radius-10" src={image} alt={title} />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsModal;
