import React from "react";
import { Link } from "react-router-dom";

import CallIcon from "@mui/icons-material/Call";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PaidIcon from "@mui/icons-material/Paid";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";

const RentCarItem = () => {
  return (
    <div class="loop-grid loop-list pr-30 mb-30 mt-30">
      <article class="wow fadeIn animated hover-up mb-30 animated">
        <div
          class="post-thumb"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80')",
            minHeight: 300,
          }}
        >
          <div class="entry-meta">
            <Link class="entry-meta meta-2" to="/rent-car-booking/details">
              <i class="fi-rs-play-alt"></i>
            </Link>
          </div>
        </div>
        <div class="py-2 px-4 pl-40 w-100">
          <h3 class="post-title mb-10">
            <Link to="/rent-car-booking/details">Toyota Axio 2017</Link>
          </h3>

          <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 mb-3">
            <span>
              <h5>
                <Link to="/rent-car-booking">Shamoli Rent A car</Link>
              </h5>
            </span>

            <span className="d-flex align-items-center">
              <CallIcon
                sx={{
                  fontSize: 18,
                  color: "black",
                }}
              />
              <h6>12144657</h6>
            </span>
          </div>

          <hr />

          <span className="d-flex align-items-center justify-content-between flex-wrap mb-2">
            <h5>5 Seater Luxury car</h5>
            <div className="product-rate">
              <div className="product-rating"></div>
            </div>
          </span>

          <div className="mb-2">
            <span className="d-flex align-items-center gap-2 mb-2">
              <PersonIcon
                sx={{
                  fontSize: 18,
                  color: "black",
                }}
              />
              <h6>Seat: 5</h6>
            </span>
            <span className="d-flex align-items-center gap-2 mb-2">
              <DirectionsCarIcon
                sx={{
                  fontSize: 18,
                  color: "black",
                }}
              />
              <h6>Model year: 2005</h6>
            </span>
            <span className="d-flex align-items-center gap-2 mb-2">
              <PaidIcon
                sx={{
                  fontSize: 18,
                  color: "black",
                }}
              />
              <h6>Daily rate: 1000</h6>
            </span>
          </div>

          <div class="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-4">
              <Link
                to="shop-grid-right.html"
                className="d-flex align-items-center gap-2"
              >
                <ShareIcon
                  sx={{
                    fontSize: 14,
                  }}
                  color="primary"
                />
                <p>Share</p>
              </Link>

              <Link
                to="shop-grid-right.html"
                className="d-flex align-items-center gap-2"
              >
                <FavoriteIcon
                  sx={{
                    fontSize: 14,
                    color: "red",
                  }}
                />
                <p>Favorite</p>
              </Link>
            </div>

            <Link to="/rent-car-booking/details" className="btn btn-xs mt-3">
              View Details <i className="fi-rs-arrow-small-right"></i>
            </Link>
            {/* <a
                    href="blog-post-right.html"
                    class="text-brand font-heading font-weight-bold"
                  >
                    View Details <i class="fi-rs-arrow-right"></i>
                  </a> */}
          </div>
        </div>
      </article>
    </div>
  );
};

export default RentCarItem;
