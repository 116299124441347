import React from "react";
import { Link } from "react-router-dom";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PaidIcon from "@mui/icons-material/Paid";
import PersonIcon from "@mui/icons-material/Person";

const BookingDetailsInfo = ({ product, setIsBooking }) => {
  const { name, discount_price } = product;

  return (
    <div className="detail-info pr-30 pl-30">
      {/* <span className="stock-status out-stock"> Sale Off </span> */}
      <h3 className="title-detail">{name}</h3>

      <div className="product-detail-rating pb-0">
        <div className="product-rate-cover text-end">
          <div className="product-rate d-inline-block">
            <div className="product-rating"></div>
          </div>
          <Link to="#review" className="font-small ml-5">
            {" "}
            (32 reviews)
          </Link>
        </div>
      </div>

      <hr />

      <span className="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <h5>5 Seater Luxury car</h5>
      </span>

      <div className="mb-2">
        <span className="d-flex align-items-center gap-2 mb-2">
          <PersonIcon
            sx={{
              fontSize: 18,
              color: "black",
            }}
          />
          <h6>Seat: 5</h6>
        </span>
        <span className="d-flex align-items-center gap-2 mb-2">
          <DirectionsCarIcon
            sx={{
              fontSize: 18,
              color: "black",
            }}
          />
          <h6>Model year: 2005</h6>
        </span>
        <span className="d-flex align-items-center gap-2 mb-2">
          <PaidIcon
            sx={{
              fontSize: 18,
              color: "black",
            }}
          />
          <h6>Daily rate: {discount_price}</h6>
        </span>
      </div>

      <div className="clearfix product-price-cover">
        <div className="product-price primary-color float-left">
          <span className="current-price text-brand">${discount_price}</span>
        </div>
      </div>

      {/* <div className="short-desc mb-30">
        <p className="font-lg">{short_description}</p>
      </div> */}

      {/* <div className="attr-detail attr-size mb-30">
        <strong className="mr-10">Size / Weight: </strong>
        <ul className="list-filter size-filter font-small">
          <li>
            <Link to="#">50g</Link>
          </li>
          <li className="active">
            <Link to="#">60g</Link>
          </li>
          <li>
            <Link to="#">80g</Link>
          </li>
          <li>
            <Link to="#">100g</Link>
          </li>
          <li>
            <Link to="#">150g</Link>
          </li>
        </ul>
      </div> */}

      <div className="detail-extralink mb-50">
        <div className="product-extra-link2">
          <button
            type="submit"
            className="button button-add-to-cart"
            onClick={() => setIsBooking(true)}
          >
            Book Now
          </button>
          <Link
            aria-label="Add To Wishlist"
            className="action-btn hover-up"
            to="shop-wishlist.html"
          >
            <i className="fi-rs-heart"></i>
          </Link>
          <Link
            aria-label="Compare"
            className="action-btn hover-up"
            to="shop-compare.html"
          >
            <i className="fi-rs-shuffle"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsInfo;
