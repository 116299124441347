import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import image1 from "../../assets/sliderImg/banner08.png";
import image2 from "../../assets/sliderImg/slider2.png";
import image3 from "../../assets/sliderImg/slider1.png";
import image4 from "../../assets/sliderImg/banner04.png";


function HomeSlider() {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <div>
      <section className="home-slider position-relative mb-30">
        <div className="container">
          <div className="home-slide-cover mt-30">
            <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
              <Slider {...settings}>
                <div className="single-hero-slider single-animation-wrap">
                  <img src={image1} className="sliderimg img-fit" alt="" />
                  <div className="slider-content">
                    <h1 className="display-2 mb-40" style={{ color: "white" }}>
                      Wellcome To
                      <br />
                      ZontropaTI
                    </h1>
                    <p className="mb-65">Sign up for the daily newsletter</p>
                    {/* <form className="form-subcriber d-flex">
                      <input type="email" placeholder="Your emaill address" />
                      <button className="btn" type="submit">
                        Register
                      </button>
                    </form> */}

                    <Link to="/registration" className="btn rounded-pill">
                      Register Now
                    </Link>
                  </div>
                </div>

                <div className="single-hero-slider single-animation-wrap">
                  <img src={image2} className="sliderimg img-fit" alt="" />
                  <div className="slider-content">
                    <h1
                      className="display-2 mb-40"
                      style={{ marginLeft: "750px", color: "whitesmoke" }}
                    >
                      Let's Start <br />
                      New Journey
                    </h1>
                    <p className="mb-65" style={{ marginLeft: "750px" }}>
                      Save up to 50% off on your first order
                    </p>
                    {/* <form className="form-subcriber d-flex">
                      <input type="email" placeholder="Your emaill address" />
                      <button className="btn" type="submit">
                        Subscribe
                      </button>
                    </form> */}

                    <Link to="/registration" className="btn rounded-pill">
                      Register Now
                    </Link>
                  </div>
                </div>

                {/*<div className="single-hero-slider single-animation-wrap">*/}
                {/*  <img src={image3} className="sliderimg img-fit" alt="" />*/}
                {/*  <div className="slider-content">*/}
                {/*    <h1 className="display-2 mb-40" style={{ color: "white" }}>*/}
                {/*      Wellcome To*/}
                {/*      <br />*/}
                {/*      ZontropaTI*/}
                {/*    </h1>*/}
                {/*    <p className="mb-65">Sign up for the daily newsletter</p>*/}
                {/*    /!* <form className="form-subcriber d-flex">*/}
                {/*      <input type="email" placeholder="Your emaill address" />*/}
                {/*      <button className="btn" type="submit">*/}
                {/*        Register*/}
                {/*      </button>*/}
                {/*    </form> *!/*/}

                {/*    <Link to="/registration" className="btn rounded-pill">*/}
                {/*      Register Now*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="single-hero-slider single-animation-wrap">
                  <img src={image4} className="sliderimg img-fit" alt="" />
                  <div className="slider-content">
                    <h1 className="display-2 mb-40" style={{ color: "white" }}>
                      Wellcome To
                      <br />
                      ZontropaTI
                    </h1>
                    <p className="mb-65">Sign up for the daily newsletter</p>
                    {/* <form className="form-subcriber d-flex">
                      <input type="email" placeholder="Your emaill address" />
                      <button className="btn" type="submit">
                        Register
                      </button>
                    </form> */}

                    <Link to="/registration" className="btn rounded-pill">
                      Register Now
                    </Link>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="slider-arrow hero-slider-1-arrow"></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeSlider;
