import React, { useEffect, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { Cascader, Empty, Form, Pagination, Select } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import axios from '../Helper/Config';
import PCBreadcrambs from '../Components/ProductCategory/PCBreadcrambs';
import Loader from '../Helper/Loader';
import category1 from '../assets/imgs/theme/icons/category-1.svg';
import category2 from '../assets/imgs/theme/icons/category-2.svg';
import category3 from '../assets/imgs/theme/icons/category-3.svg';
import category4 from '../assets/imgs/theme/icons/category-4.svg';
import category5 from '../assets/imgs/theme/icons/category-5.svg';
import { useGetPartsAccessoriesQuery } from '../features/partsAccessories/partsAccessoriesApi';
import ProductCategoryCard from '../Components/common/card/ProductCategoryCard';

function ProductCategories() {
    const { id } = useParams();
    const [isupdate, setisupdate] = React.useState(0);
    const [pages, setPages] = useState(1);
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [partstype, setPartsType] = useState('');
    const [category, setCategoryId] = useState(id);
    const [subcategory, setSubCategoryId] = useState('');
    const [location, setLocation] = useState('');
    const [search, setOnSearch] = useState('');
    const [min, setMinValue] = useState('');
    const [max, setMaxValue] = useState('');

    // Our States
    const [partsTypesList, setPartsTypes] = useState([]);
    const [categoryList, setCategory] = useState([]);
    const [subCategoryList, setSubCategory] = useState([]);
    const [loactionList, setLoactionList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [makeList, setMakeList] = useState([]);
    const { Option } = Select;
    const [form] = Form.useForm();

    const { data, isSuccess, isLoading } = useGetPartsAccessoriesQuery({
        search,
        make,
        model,
        category,
        subcategory,
        partstype,
        location,
        min,
        max,
        pages,
    });

    const products = data?.data?.product?.data;

    useEffect(() => {
        // Parts Types
        partsType();
        // Location Area
        locationFunction();
        // Make
        makeFunction();
        // Model
        modelFunction();
        // category Function
        categoryFunction();
    }, []);

    const partsType = () => {
        axios
            .get('/seller/parts/type')
            .then((res) => {
                setPartsTypes(res?.data?.data?.type);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const locationFunction = () => {
        axios
            .get('/common/division-district/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    children: category?.districts.map((districts) => ({
                        value: districts?.id,
                        label: districts?.name,
                    })),
                }));
                setLoactionList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Make onClick Function Area
    const makeFunction = () => {
        axios
            .get('/common/vehicle/make/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setMakeList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Model onClick Function Area
    const modelFunction = () => {
        axios
            .get('/common/vehicle/model/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setModelList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const categoryFunction = (e) => {
        axios
            .get('/seller/parts/category')
            .then((res) => {
                setCategory(res?.data?.data?.category);
            })
            .catch((err) => {});
    };
    const pagesChange = (element) => {
        setPages(element);
        setisupdate(isupdate + 1);
    };
    const makeListOnchangFunction = (e) => {
        setMake(e);
        setisupdate(isupdate + 1);
    };
    const modelOnChangFunction = (e) => {
        setModel(e);
        setisupdate(isupdate + 1);
    };
    const partsTypeFunction = (e) => {
        setPartsType(e);
        setisupdate(isupdate + 1);
    };
    const categoryOnChangeFunction = (e) => {
        setCategoryId(e);
        axios
            .get(`/seller/parts/subcategory/${e}`)
            .then((res) => {
                setSubCategory(res?.data?.data?.subcategory);
            })
            .catch((err) => {});
        setisupdate(isupdate + 1);
    };
    const subCategoryFunction = (e) => {
        setSubCategoryId(e);
        setisupdate(isupdate + 1);
    };
    const locationOnChange = (value) => {
        const lastItem = value[value.length - 1];
        setLocation(lastItem);
        setisupdate(isupdate + 1);
    };
    const onSearch = (e) => {
        setOnSearch(e.target.value);
        setisupdate(isupdate + 1);
    };
    const rangeSelector = (event, newValue) => {
        const min_price = newValue[0];
        const max_price = newValue[1];
        setMinValue(min_price);
        setMaxValue(max_price);
        setisupdate(isupdate + 1);
    };

    let content = null;

    if (isSuccess && products.length > 0) content = products?.map((item, i) => <ProductCategoryCard key={item.id} product={item} />);

    if (isSuccess && products.length === 0)
        content = (
            <div className='dataEmtey'>
                <Empty />
            </div>
        );

    return (
        <>
            <PCBreadcrambs />
            <br />
            <div className='container mb-30'>
                <div className='row flex-row-reverse'>
                    <div className='col-lg-4-5'>
                        <div className='productCategories__area'>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className='productCategories__details_header'>
                                            <Row>
                                                <Col lg={12}>
                                                    <input
                                                        type='text'
                                                        style={{ height: '45px', marginBottom: 30 }}
                                                        placeholder={`Search for`}
                                                        onChange={(e) => onSearch(e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>{content}</Row>

                                        {products?.length > 0 && (
                                            <>
                                                <Pagination
                                                    onChange={(e) => pagesChange(e)}
                                                    defaultCurrent={1}
                                                    defaultPageSize={12}
                                                    total={pages}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                            <Loader loaderShow={isLoading} loaderHide={isLoading} />
                        </div>
                    </div>

                    <div className='col-lg-1-5 primary-sidebar sticky-sidebar'>
                        {/*<div className='sidebar-widget widget-category-2 mb-30'>*/}
                        {/*    <h5 className='section-title style-1 mb-30'>Category</h5>*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <Link to='shop-grid-right.html'>*/}
                        {/*                <img src={category1} alt='' />*/}
                        {/*                Milks & Dairies*/}
                        {/*            </Link>*/}
                        {/*            <span className='count'>30</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to='shop-grid-right.html'>*/}
                        {/*                <img src={category2} alt='' />*/}
                        {/*                Clothing*/}
                        {/*            </Link>*/}
                        {/*            <span className='count'>35</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to='shop-grid-right.html'>*/}
                        {/*                <img src={category3} alt='' />*/}
                        {/*                Pet Foods{' '}*/}
                        {/*            </Link>*/}
                        {/*            <span className='count'>42</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to='shop-grid-right.html'>*/}
                        {/*                <img src={category4} alt='' />*/}
                        {/*                Baking material*/}
                        {/*            </Link>*/}
                        {/*            <span className='count'>68</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to='shop-grid-right.html'>*/}
                        {/*                <img src={category5} alt='' />*/}
                        {/*                Fresh Fruit*/}
                        {/*            </Link>*/}
                        {/*            <span className='count'>87</span>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        <div className='sidebar-widget price_range range mb-30'>
                            <h5 className='section-title style-1 mb-30'>Filter Product</h5>
                            <Form form={form} layout='vertical'>
                                <Form.Item label='Location' tooltip='Select You Location'>
                                    <Cascader
                                        className='w-100'
                                        placeholder='Location'
                                        onChange={(e) => locationOnChange(e)}
                                        options={loactionList}
                                        changeOnSelect
                                    />
                                </Form.Item>
                                <Form.Item label='Price Range' tooltip='Select You Price Range'>
                                    <div className='price-filter'>
                                        <div className='price-filter-inner'>
                                            <Slider
                                                range={{ draggableTrack: true }}
                                                min={0}
                                                max={50000}
                                                step={100}
                                                onChange={rangeSelector}
                                                defaultValue={[0, 50000]}
                                            />
                                            <div className='d-flex justify-content-between'>
                                                <div className='caption'>
                                                    From: <strong className='text-brand'>{min ? min : 0}</strong>
                                                </div>
                                                <div className='caption'>
                                                    To: <strong className='text-brand'>{max ? max : 50000}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>
                                <Form.Item label='Category' tooltip='Select You Category'>
                                    <Select
                                        className='w-100'
                                        onClick={(e) => categoryFunction(e)}
                                        onChange={(e) => categoryOnChangeFunction(e)}
                                        allowClear
                                        placeholder='Select your Category'
                                    >
                                        {categoryList?.map((item, i) => (
                                            <Option key={i} value={`${item?.id}`}>
                                                {item?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Sub Category' tooltip='Select You Sub Category'>
                                    <Select
                                        mode='multiple'
                                        className='w-100'
                                        onChange={(e) => subCategoryFunction(e)}
                                        placeholder='Select your Category'
                                    >
                                        {subCategoryList?.map((item, i) => (
                                            <Option key={i} value={`${item?.id}`}>
                                                {item?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Parts Type' tooltip='Select Parts Type'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => partsTypeFunction(e)}
                                        allowClear
                                        placeholder='Select Parts type'
                                    >
                                        {partsTypesList?.map((item, i) => (
                                            <Option key={i} value={`${item?.id}`}>
                                                {item?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Make' tooltip='Select Parts Make'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => makeListOnchangFunction(e)}
                                        placeholder='Make'
                                        allowClear
                                        options={makeList}
                                    />
                                </Form.Item>
                                <Form.Item label='Model' tooltip='Select Parts Model'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => modelOnChangFunction(e)}
                                        placeholder='Model'
                                        allowClear
                                        options={modelList}
                                    />
                                </Form.Item>
                            </Form>

                            <a href='' className='btn btn-sm btn-default btn-block'>
                                <i className='fi-rs-filter mr-5'></i> Fillter
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductCategories;
