import React from 'react';
// import { getCartTotal } from "../../reduxServices/actions/CartActions";
import { Col, Row } from 'react-bootstrap';
import BreadCrumb from '../../Components/common/BreadCrumb';
import LandingProductCard from '../../Components/common/card/homePage/LandingProductCard';
import { useGetProductsQuery } from '../../features/products/productsApi';
import CartBody from './CartBody/CartBody';
import CartHeader from './CartHeader';

function Cart() {
    // const { data, isSuccess } = useGetProductsQuery();
    // const products = data?.data?.product?.data;

    // let content = null;
    // if (isSuccess)
    //     content = products.slice(0, 10)?.map((part) => (
    //         <Col key={part.id}>
    //             <LandingProductCard product={part} />
    //         </Col>
    //     ));

    return (
        <main class='main'>
            <BreadCrumb />

            <div class='container mb-80 mt-50'>
                {/* Remove after add to cart feature done */}
                {/* <Row>{content}</Row> */}
                {/* End */}

                {/* Cart Header */}
                <CartHeader />

                {/* Cart Body */}
                <CartBody />
            </div>
        </main>
    );
}

export default Cart;

// {productList?.map((item) => (
//     <Col key={item.id}>
//         <div className='product-cart-wrap'>
//             <div className='product-content-wrap'>
//                 <h2>{item.title}</h2>
//                 <div className='product-price mt-10'>
//                     <span>${item.price}</span>
//                 </div>

//                 {/* <Link to='' className='btn w-100 hover-up mt-10' onClick={() => dispatch(addToCart(item))}>
//                     <i className='fi-rs-shopping-cart mr-5'></i>Add To Cart
//                 </Link> */}
//                 <input type='number' onChange={(e) => setQuantity(+e.target.value)} />
//                 <button className='btn w-100 hover-up mt-10' onClick={() => dispatch(addToCart({ ...item, quantity }))}>
//                     <i className='fi-rs-shopping-cart mr-5'></i>Add To Cart
//                 </button>
//             </div>
//         </div>
//     </Col>
// ))}
