import React from 'react';
import { Link } from 'react-router-dom';
import { apiImgUrl } from '../../../../Helper/BaseUrl';

const FlashSaleCard = ({ item }) => {
    const { title, offerImage, discount, offerprice, total } = item || {};

    console.log('flashSale');
    console.log(item);

    // const discountRate = () => {
    //     const percentage = (discount / offerprice) * 100;
    //     if (percentage) {
    //         return percentage.toFixed(2);
    //     } else {
    //         return false;
    //     }
    // };

    return (
        <div className='product-cart-wrap'>
            {/* Product Image Section */}
            <div className='product-img-action-wrap mb-15'>
                {offerImage && (
                    <>
                        <div className='product-img product-img-zoom'>
                            <Link to=''>
                                <img key={item.id} className='default-img-list' src={apiImgUrl + offerImage} alt={title} />
                            </Link>
                        </div>

                        <div className='product-action-1'>
                            <Link aria-label='Quick view' className='action-btn small hover-up' to=''>
                                <i className='fi-rs-eye'></i>
                            </Link>
                            <Link aria-label='Add To Wishlist' className='action-btn small hover-up' to=''>
                                <i className='fi-rs-heart'></i>
                            </Link>
                            <Link aria-label='Compare' className='action-btn small hover-up' to=''>
                                <i className='fi-rs-shuffle'></i>
                            </Link>
                        </div>
                    </>
                )}

                {discount && (
                    <div className='product-badges product-badges-position product-badges-mrg'>
                        <span className='new'>Save ${discount}</span>
                    </div>
                )}
            </div>

            <div className='product-content-wrap'>
                <h2 style={{ minHeight: 40 }}>
                     {/*<Link className='text-ellipsis-2' to={`/product-details/${product.slug}`}>*/}
                    <Link className='text-ellipsis-2' to={`/product-categories/:slug`}>
                        {title}
                    </Link>
                </h2>
                <div className='product-rate d-inline-block'>
                    <div className='product-rating'></div>
                </div>
                <div className='product-price mt-10'>
                    <span>${offerprice} </span>
                    <span className='old-price'>${total}</span>
                </div>

                <Link to={`/product-categories/:slug`} className='btn w-100 hover-up mt-10'>
                    {/* <i className='fi-rs-shopping-cart mr-5'></i> */}View Details
                </Link>
            </div>
        </div>
    );
};

export default FlashSaleCard;
