import React from "react";
import BookingTabNav from "./BookingTabNav";
import BookingTabContent from "./BookingTabContent";

const BookingInfoTab = ({ product }) => {
  return (
    <div className="product-info">
      <div className="tab-style3">
        <BookingTabNav />

        <BookingTabContent product={product} />
      </div>
    </div>
  );
};

export default BookingInfoTab;
