import React from "react";
import { apiImgUrl } from "../../../../../Helper/BaseUrl";
import AuthorTwo from "../../../../../assets/imgs/blog/author-2.png";

const BookingTabContent = ({ product }) => {
  const { parts_seller } = product;

  console.log(`${apiImgUrl}${parts_seller?.shop_logo}`);
  return (
    <div className="tab-content shop_info_tab entry-main-content">
      <div className="tab-pane fade show active" id="Rates">
        <div className="d-flex flex-wrap gap-5">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <h5>Hybrid Engine Repair</h5>
                </td>
                <td>
                  <h5>Strong Engine Maintanence Record</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>EFI Engine Knowledge</h5>
                </td>
                <td>
                  <h5>Customer Service</h5>
                </td>
              </tr>

              <tr>
                <td>
                  <h5>Basic Knowledge on Car Engine</h5>
                </td>
                <td>
                  <h5>Expert Maintenance Knowledge </h5>
                </td>
              </tr>

              <tr>
                <td>
                  <h5>Multi Language Speaker</h5>
                </td>
                <td>
                  <h5>Trustworthy</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tab-pane fade" id="Additional-info">
        <h5 className="d-flex justify-content-between mb-3">
          <span>Mr. Jack's Import Repair - Uttar Badda, Dhaka</span>
          <span className="ml-auto">
            Automotive Technician - 10/2019 to 02/2023
          </span>
        </h5>

        <p className="mb-2">
          Conducted automotive inspections, diagnostics, and repairs on European
          imports to include engines, transmissions, differentials, brakes,
          clutches, starters, axles, steering gears, carburetors, fuel systems,
          cooling systems, and other mechanical and electrical systems.
          Maintained accurate hours and repair records.
        </p>

        <ul>
          <li>
            <p>
              Built solid reputation as highly skilled and quality-driven
              mechanic; entrusted with increased responsibility, often
              supervising other mechanics.
            </p>
          </li>
          <li>
            <p>
              Recognized by management for expertise and routinely called upon
              for recommendations; provided valuable guidance that led to
              avoidance of lost time and damaged parts.
            </p>
          </li>
        </ul>
      </div>

      <div className="tab-pane fade" id="Reviews">
        <div className="comments-area">
          <div className="row">
            <div className="col-lg-8">
              <h4 className="mb-30">Customer questions & answers</h4>
              <div className="comment-list">
                <div className="single-comment justify-content-between d-flex mb-30">
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src={AuthorTwo} alt="" />
                      <a href="#" className="font-heading text-brand">
                        Sienna
                      </a>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            December 4, 2022 at 3:12 pm{" "}
                          </span>
                        </div>
                        {/* <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style="width: 100%"
                            ></div>
                          </div> */}
                      </div>
                      <p className="mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Delectus, suscipit exercitationem accusantium
                        obcaecati quos voluptate nesciunt facilis itaque modi
                        commodi dignissimos sequi repudiandae minus ab deleniti
                        totam officia id incidunt?{" "}
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-comment justify-content-between d-flex mb-30 ml-30">
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src={AuthorTwo} alt="" />
                      <a href="#" className="font-heading text-brand">
                        Brenna
                      </a>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            December 4, 2022 at 3:12 pm{" "}
                          </span>
                        </div>
                        {/* <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style="width: 80%"
                            ></div>
                          </div> */}
                      </div>
                      <p className="mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Delectus, suscipit exercitationem accusantium
                        obcaecati quos voluptate nesciunt facilis itaque modi
                        commodi dignissimos sequi repudiandae minus ab deleniti
                        totam officia id incidunt?{" "}
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-comment justify-content-between d-flex">
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src={AuthorTwo} alt="" />
                      <a href="#" className="font-heading text-brand">
                        Gemma
                      </a>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            December 4, 2022 at 3:12 pm{" "}
                          </span>
                        </div>
                        {/* <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style="width: 80%"
                            ></div>
                          </div> */}
                      </div>
                      <p className="mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Delectus, suscipit exercitationem accusantium
                        obcaecati quos voluptate nesciunt facilis itaque modi
                        commodi dignissimos sequi repudiandae minus ab deleniti
                        totam officia id incidunt?{" "}
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <h4 className="mb-30">Customer reviews</h4>
              <div className="d-flex mb-30">
                {/* <div className="product-rate d-inline-block mr-15">
                    <div className="product-rating" style="width: 90%"></div>
                  </div> */}
                <h6>4.8 out of 5</h6>
              </div>

              {/* <div className="progress">
                  <span>5 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 50%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    50%
                  </div>
                </div>
                <div className="progress">
                  <span>4 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 25%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    25%
                  </div>
                </div>
                <div className="progress">
                  <span>3 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 45%"
                    aria-valuenow="45"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    45%
                  </div>
                </div>
                <div className="progress">
                  <span>2 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 65%"
                    aria-valuenow="65"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    65%
                  </div>
                </div>
                <div className="progress mb-30">
                  <span>1 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 85%"
                    aria-valuenow="85"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    85%
                  </div>
                </div> */}
              <a href="#" className="font-xs text-muted">
                How are ratings calculated?
              </a>
            </div>
          </div>
        </div>

        <div className="comment-form">
          <h4 className="mb-15">Add a review</h4>
          <div className="product-rate d-inline-block mb-30"></div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <form
                className="form-contact comment_form"
                action="#"
                id="commentForm"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="comment"
                        id="comment"
                        cols="30"
                        rows="9"
                        placeholder="Write Comment"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="website"
                        id="website"
                        type="text"
                        placeholder="Website"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="button button-contactForm">
                    Submit Review
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingTabContent;
