import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../Components/common/CustomPagination";

const Orders = () => {
  return (
    <div
      class="tab-pane fade"
      id="orders"
      role="tabpanel"
      aria-labelledby="orders-tab"
    >
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">Booking List</h3>
          <span>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control form-control-sm h-auto  rounded-1"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon2">
                <SearchIcon />
              </span>
            </div>
          </span>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Booking Ref id.</th>
                  <th>Booking Date</th>
                  <th>Booking For</th>
                  <th>Booking Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DRV100001001</td>
                  <td>12/3/2022</td>
                  <td>Driver</td>
                  <td>Martine Luther, Contact - 0189465165</td>
                  <td>
                    <span className="d-flex align-items-center gap-2 cursor-pointer">
                      <VisibilityIcon sx={{ fontSize: 16 }} />
                      <a href="#" class="btn-small d-block">
                        View
                      </a>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>DRV100001001</td>
                  <td>12/3/2022</td>
                  <td>Driver</td>
                  <td>Martine Luther, Contact - 0189465165</td>
                  <td>
                    <span className="d-flex align-items-center gap-2 cursor-pointer">
                      <VisibilityIcon sx={{ fontSize: 16 }} />
                      <a href="#" class="btn-small d-block">
                        View
                      </a>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>DRV100001001</td>
                  <td>12/3/2022</td>
                  <td>Driver</td>
                  <td>Martine Luther, Contact - 0189465165</td>
                  <td>
                    <span className="d-flex align-items-center gap-2 cursor-pointer">
                      <VisibilityIcon sx={{ fontSize: 16 }} />
                      <a href="#" class="btn-small d-block">
                        View
                      </a>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>DRV100001001</td>
                  <td>12/3/2022</td>
                  <td>Driver</td>
                  <td>Martine Luther, Contact - 0189465165</td>
                  <td>
                    <span className="d-flex align-items-center gap-2 cursor-pointer">
                      <VisibilityIcon sx={{ fontSize: 16 }} />
                      <a href="#" class="btn-small d-block">
                        View
                      </a>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>DRV100001001</td>
                  <td>12/3/2022</td>
                  <td>Driver</td>
                  <td>Martine Luther, Contact - 0189465165</td>
                  <td>
                    <span className="d-flex align-items-center gap-2 cursor-pointer">
                      <VisibilityIcon sx={{ fontSize: 16 }} />
                      <a href="#" class="btn-small d-block">
                        View
                      </a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <CustomPagination placement="center" />
        </div>
      </div>
    </div>
  );
};

export default Orders;
