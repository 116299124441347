import React from "react";
import Headline from "../Headline";

const ConfirmationStepContent = () => {
  return (
    <div className="mt-50">
      <Headline title="Booking Details" />

      <div className="d-flex align-items-center gap-5 mb-4">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="oneWay"
            id="oneWay"
            checked
            disabled
          />
          <label className="form-check-label" htmlFor="oneWay">
            One Way Trip
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="roundTrip"
            id="roundTrip"
            disabled
          />
          <label className="form-check-label" htmlFor="roundTrip">
            Round Trip
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="monthly"
            id="monthly"
            disabled
          />
          <label className="form-check-label" htmlFor="monthly">
            Monthly
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="Corporate"
            id="Corporate"
            disabled
          />
          <label className="form-check-label" htmlFor="Corporate">
            Corporate
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="Weeding"
            id="Weeding"
            disabled
          />
          <label className="form-check-label" htmlFor="Weeding">
            Weeding
          </label>
        </div>
      </div>

      <div class="d-flex align-items-center gap-5 mb-4">
        <div>
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder"
          >
            Pick Date
          </label>

          <h4>10/05/2022</h4>
        </div>

        <div>
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder"
          >
            Time
          </label>

          <h4>10:00 AM</h4>
        </div>

        <div>
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder"
          >
            For
          </label>

          <h4>5 Days</h4>
        </div>
      </div>

      <div className="mb-4">
        <label
          for="exampleFormControlInput1"
          class="form-label d-block fw-bolder"
        >
          Location Route (From - To)
        </label>

        <h4>Dhaka To Chittagong</h4>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="row">
            <div className="col-xl-12">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Other Facilities
              </label>
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  disabled
                  checked
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Driver
                </label>
              </div>

              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Notes
              </label>

              <h5>Please arrive at pick up location on time</h5>
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <table class="table">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Total Charges
            </label>

            <tbody>
              <tr>
                <td>Total Rate Charges</td>
                <td>4000</td>
              </tr>
              <tr>
                <td>Other Charges</td>
                <td>2000</td>
              </tr>
              <tr>
                <th scope="row">
                  <h6>Sub Total</h6>
                </th>
                <td className="fw-bold">
                  <h6>6000</h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStepContent;
