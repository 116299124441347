import React from 'react';
import { Link } from 'react-router-dom';
import { apiImgUrl } from '../../../../Helper/BaseUrl';

const DealsCard = ({ product }) => {
    const { title, name, slug, thumbnail } = product;
    return (
        <article className='row align-items-center hover-up'>
            <figure className='col-md-4 mb-0'>
                <Link to=''>
                    <img src={apiImgUrl + thumbnail} alt={title} />
                </Link>
            </figure>
            <div className='col-md-8 mb-0'>
                <h6>
                    <Link to={slug ? `/product-details/${slug}` : ''}>{title || name}</Link>
                </h6>
                <div className='product-rate-cover'>
                    <div className='product-rate d-inline-block'>
                        <div className='product-rating'></div>
                    </div>
                    <span className='font-small ml-5 text-muted'> (4.0)</span>
                </div>
                <div className='product-price'>
                    <span>$32.85</span>
                    <span className='old-price'>$33.8</span>
                </div>
            </div>
        </article>
    );
};

export default DealsCard;
