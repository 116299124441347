import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DropdownCartItem from './DropdownCartItem';
import { cartTotal } from '../../../Helper/utils/function';

const CartDropDown = () => {
    const { cart } = useSelector((state) => state);
    console.log(cart);
    let content = null;

    if (cart.length > 0)
        cart.map(
            (coreItem) =>
                (content = coreItem.cartItem.map((item, i) => (
                    <DropdownCartItem key={i} item={{ sellerSlug: coreItem.sellerSlug, cartItem: item }} />
                )))
        );

    if (cart.length === 0) content = <p>No item added in cart</p>;
    return (
        <div className='cart-dropdown-wrap cart-dropdown-hm2'>
            <ul>{content}</ul>

            <div className='shopping-cart-footer'>
                <div className='shopping-cart-total'>
                    <h4>
                        Total <span>${cartTotal(cart)}</span>
                    </h4>
                </div>
                <div className='shopping-cart-button'>
                    <Link to='/cart' className='outline'>
                        View cart
                    </Link>
                    <Link to='/checkout'>Checkout</Link>
                </div>
            </div>
        </div>
    );
};

export default CartDropDown;
