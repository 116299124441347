import React from "react";
import { Link } from "react-router-dom";

import CallIcon from "@mui/icons-material/Call";
import Headline from "../../../Components/common/Headline";

const dataImg = [
  {
    image:
      "https://images.unsplash.com/photo-1473655587843-eda8944061e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
  },
];

const driverData = {
  id: 50,
  name: "Martin Luther",
  discount_price: 1000,
};

const PartsBiddingSelectModal = () => {
  return (
    <div className="container mt-30">
      <div className="row">
        {/* col-lg-4-5 col-xl-10 col-lg-12 m-auto */}
        <div className="col-xl-12">
          <div className="product-detail accordion-detail">
            <div className="detail-info ">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="title-detail">Euro Car Parts</h2>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between flex-wrap gap-4">
                <div>
                  <div className="product-detail-rating pb-0 pt-1">
                    <div className="product-rate-cover text-end">
                      <div className="product-rate d-inline-block">
                        <div className="product-rating"></div>
                      </div>
                      <Link to="#review" className="font-small ml-5">
                        {" "}
                        (32 reviews)
                      </Link>
                    </div>
                  </div>

                  <span className="d-flex align-items-center py-1">
                    <CallIcon
                      sx={{
                        fontSize: 22,
                        color: "black",
                      }}
                      className="text-brand"
                    />
                    <h5> 0174121447</h5>
                  </span>

                  <h5>Location: 122, Ring Road, Adabor, Dhaka - 1207</h5>
                </div>
              </div>

              <hr />
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <Headline title="Bidder Response" />

          <table class="table">
            <thead class="table-light">
              <tr>
                <th scope="col">Product ID</th>
                <th scope="col">Image</th>
                <th scope="col">Product Name</th>
                <th scope="col">Category</th>
                <th scope="col">Qty.</th>
                <th scope="col">Warranty</th>
                <th scope="col" align="center">
                  Unit Price
                </th>
                <th scope="col" align="center">
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>
                  <img
                    src="https://images.unsplash.com/photo-1537462715879-360eeb61a0ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1538&q=80"
                    alt=""
                    width={100}
                  />
                </td>
                <td>Back Camera</td>
                <td>Electronic System</td>
                <td>1</td>
                <td>N/A</td>
                <td align="center">5500</td>
                <td align="center">5500</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>
                  <img
                    src="https://images.unsplash.com/photo-1578844251758-2f71da64c96f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80"
                    alt=""
                    width={100}
                  />
                </td>
                <td>Spoiler</td>
                <td>Body</td>
                <td>1</td>
                <td>N/A</td>
                <td align="center">2000</td>
                <td align="center">2000</td>
              </tr>

              <tr>
                <td colspan="5"></td>
                <td colspan="2">Shipping Charge</td>
                <td align="center">200</td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td colspan="2">Discount</td>
                <td align="center">0.00</td>
              </tr>

              <tr>
                <td colspan="5"></td>
                <td colspan="2">
                  <b>Grand Total</b>{" "}
                </td>
                <td align="center">
                  <b>7700</b>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Select as winner
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartsBiddingSelectModal;
