import React from "react";
import Styles from "./cardPayment.module.css";
import CardLogo from "../../../assets/images/card-payment.png";

const CardPayment = () => {
  return (
    <div className={Styles.wrapper}>
      <img src={CardLogo} alt="Cards Logo" />

      <div className={`${Styles.contentWrapper} p-4 `}>
        <div className={`${Styles.merchantInfo} p-3 mb-4 text-center`}>
          <h5 className="mb-2">Merchant: Shamoli Rent a Car</h5>
          <h5 className="mb-2">Invoice No: 21234521</h5>
          <h5>Amount: BDT 900</h5>
        </div>

        <div className={`${Styles.numberInput} text-center mb-50`}>
          <h4 className="mb-2">Your Card Number</h4>
          <div class="mb-3">
            <input
              type="number"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="e.g 01XXXXXXXXX"
            />
          </div>
        </div>

        <div
          className={`${Styles.contentFooter} d-flex flex-column align-items-center justify-content-center`}
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="termANDconditions"
            />

            <label class="form-check-label" for="termANDconditions">
              I agreed to{" "}
              <a href="#" className="text-decoration-underline">
                {" "}
                Terms and Condition
              </a>
            </label>
          </div>

          <div
            className={`d-flex align-items-center justify-content-center gap-4`}
          >
            <button className="btn">Proceed</button>
            <button className="btn">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPayment;
