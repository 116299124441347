import React from "react";
import Headline from "../../../common/Headline";

const BookingDetails = () => {
  return (
    <div>
      <Headline title="Your booking is done" />

      <table class="table table-bordered">
        <tbody>
          <tr>
            <td className="p-4">
              <div className="mb-20">
                <h6>Reference Id</h6>
                <h4>SER10001001</h4>
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <span>
                  <h6>Customer Name</h6>
                  <h4>Mr. Hasan Ahmed</h4>
                </span>

                <span>
                  <h6>Contact</h6>
                  <h4>01759178623</h4>
                </span>
              </div>
            </td>
            <td className="text-center">
              <h5>Car Details</h5>
              <h3>Toyota, Corolla</h3>
            </td>
          </tr>

          <tr>
            <td className="p-4">
              <div className="d-flex justify-content-between flex-wrap mb-20">
                <span>
                  <h6>Preferred Date</h6>
                  <h4>10/05/2022</h4>
                </span>

                <span>
                  <h6>Time</h6>
                  <h4>10:00AM</h4>
                </span>
              </div>

              <div className="mb-20">
                <h6>Problem Details</h6>
                <h4>N/A</h4>
              </div>

              <div className="">
                <h6>Service List</h6>
                <h4>Engine Repair, Tire Change, Car Paint, Car Wash</h4>
              </div>
            </td>
            <td className="text-center">
              <h5>Rent a Car Company Details</h5>
              <h3>Shamoli Rent A Car</h3>
              <p>123, Adabor, Ring Road</p>
              <p>Dhaka - 1207</p>
              <p>017565141354</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BookingDetails;
