import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCart } from '../../features/cart/cartSlice';

const CartHeader = () => {
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    // console.log(cart);

    const handleCartReset = () => {
        dispatch(resetCart());
    };

    return (
        <div class='row'>
            <div class='col-lg-8 mb-40'>
                <h1 class='heading-2 mb-10'>Your Cart</h1>
                <div class='d-flex justify-content-between'>
                    <h6 class='text-body'>
                        There are <span class='text-brand'>{cart?.length}</span> products in your cart
                    </h6>
                    <h6 class='text-body' onClick={handleCartReset}>
                        <a href='#a' class='text-muted'>
                            <i class='fi-rs-trash mr-5'></i>Clear Cart
                        </a>
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default CartHeader;
