import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({root = 'Home', category= "car", sub="parts"}) => {
  return (
    <div className="page-header breadcrumb-wrap">
      <div className="container">
        <div className="breadcrumb">
          <Link to="#" rel="nofollow">
            <i className="fi-rs-home mr-5"></i>
            {root}
          </Link>
          <span></span> <Link to="#">{category}</Link> <span></span>{" "}
          {sub}
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
