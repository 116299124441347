import React, { } from 'react'
import Modal from "@mui/material/Modal"
import loaderImg from "../assets/images/loader.gif"

function Loader(props) {
    return (
        <div>
            <Modal
                open={props.loaderShow}
                // onClose={props.loaderHide}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-dialog-centered'

            >
                <>
                    <div id="preloader-active">
                        <div className="preloader d-flex align-items-center justify-content-center">
                            <div className="preloader-inner position-relative">
                                <div className="text-center">
                                    <img src={loaderImg} height="100" alt="Loging"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    )
}

export default Loader
