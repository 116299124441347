import React from 'react';
import { Link } from 'react-router-dom';

const VendorListHeader = () => {
    return (
        <div className='section-title wow animate__animated animate__fadeIn' data-wow-delay='0'>
            <h4 className=''>Our All Vendor List </h4>
            <Link className='show-all' to='/services-product'>
                All Vendors
                <i className='fi-rs-angle-right'></i>
            </Link>
        </div>
    );
};

export default VendorListHeader;
