import { apiSlice } from '../api/apiSlice';

export const flashSale = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFlashSale: builder.query({
            query: () => '/seller/flashsale/all',
        }),
    }),
});

export const { useGetFlashSaleQuery } = flashSale;
