import React from 'react';
import { useGetRecentProductsQuery } from '../../../features/recentProducts/recentProductsApi';
import DealsCard from '../../common/card/homePage/DealsCard';

const RecentProducts = () => {
    const { data, isSuccess } = useGetRecentProductsQuery();
    const { Product } = data?.data || {};

    let content = null;
    if (isSuccess) content = Product.slice(0, 3).map((item) => <DealsCard key={item.id} product={item} />);

    return (
        <div className='col-xl-4 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp' data-wow-delay='0'>
            <h4 className='section-title style-1 mb-30 animated animated'> Recently added </h4>
            <div className='product-list-small animated animated'>{content}</div>
        </div>
    );
};

export default RecentProducts;
