import React from "react";
import { Typography, Grid, styled, Paper } from "@material-ui/core";
// import useStyles from './styles';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Col, DatePicker, Row } from "antd";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function ShippingDetails(props) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const { formValues } = props;
  // const classes = useStyles();
  console.log(formValues);
  const { firstName, lastName, username, email, contactNo } = formValues;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="r_comfram_area">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl size="small" sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">
              Select Services
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={personName}
              onChange={handleChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Select Services"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: 200 }}
          />

          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">Bay Number</th>
                  <th scope="col">10:00AM</th>
                  <th scope="col">11:00AM</th>
                  <th scope="col">12:00PM</th>
                  <th scope="col">1:00PM</th>
                  <th scope="col">2:00PM</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="row">Bay 1</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Bay 2</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Bay 3</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Bay 4</th>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-secondary btn-sm">booked</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                  <td>
                    <p className="btn btn-primary btn-sm">Open</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>

      {/* <Grid item container direction="column" xs={12} sm={12}>
        <Grid container>
          <>
            <Grid item xs={12}>
              <Typography gutterBottom> First Name : {firstName}</Typography>
            </Grid>
          </>
          <>
            <Grid item xs={12}>
              <Typography gutterBottom> Last Name : {lastName}</Typography>
            </Grid>
          </>
          <>
            <Grid item xs={12}>
              <Typography gutterBottom> User Name : {username}</Typography>
            </Grid>
          </>
          <>
            <Grid item xs={12}>
              <Typography gutterBottom> Email : {email}</Typography>
            </Grid>
          </>
          <>
            <Grid item xs={12}>
              <Typography gutterBottom> Phone Number : {contactNo}</Typography>
            </Grid>
          </>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default ShippingDetails;
