import React from "react";

const SpecialOfferItem = ({ service, onClick }) => {
  const { title, image } = service;

  const handleClick = () => {
    onClick(service);
  };

  return (
    <div
      class="col-lg-4 col-md-6 col-12 col-sm-6 cursor-pointer"
      onClick={handleClick}
    >
      <div class="vendor-wrap mb-40">
        {/* vendor-img-action-wrap */}
        <div class="">
          <div class="vendor-img">
            <img class="default-img" src={image} alt={title} />
            {/* <a href="vendor-details-1.html">
            </a> */}
          </div>
        </div>
        <div class="vendor-content-wrap">
          <div class="d-flex justify-content-center align-items-center">
            <h4 class="fs-5 mb-5">
              {title}
              {/* <a href="vendor-details-1.html"></a> */}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialOfferItem;
