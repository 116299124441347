import React from 'react';
import SummaryItem from './SummaryItem';

const SummaryList = ({ item }) => {
    console.log(item);
    let content = null;
    if (item.cartItem?.length > 0)
        content = item.cartItem.map((cartItem, i) => <SummaryItem key={i} item={{ sellerSlug: item.sellerSlug, cartItem }} />);

    return <tbody class='pt-30'>{content}</tbody>;
};

export default SummaryList;
