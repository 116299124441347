export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required',
      letterErrorMsg: 'Special character not allowed'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required',
      letterErrorMsg: 'Special character not allowed'
    },
    username: {
      name: 'username',
      label: 'User Name*',
      requiredErrorMsg: 'User Name is required',
      letterErrorMsg: 'Special character not allowed'
    },
    email: {
      name: 'email',
      label: 'Email*',
      requiredErrorMsg: 'Email is required'
    },
    contactNo: {
      name: 'contactNo',
      label: 'Contact No*',
      requiredErrorMsg: 'Contact No is required',
      massge: 'Please Includ Valid Phone Number'
    },
    password: {
      name: 'password',
      label: 'Password*',
      requiredErrorMsg: 'Password is required',
      msg1: 'Please input one small letter',
      msg2: 'Please input one capital',
      numberr: 'Please input one number',
      sCarter: 'At least one special character!',
      letterErrorMsg: 'Space not allowed'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Address*',
      requiredErrorMsg: 'Address is required'
    }
  }
};
