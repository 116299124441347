import CallIcon from "@mui/icons-material/Call";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import React from "react";
import { Link } from "react-router-dom";
import GoogleMap from "../../../Components/common/GoogleMap";


const RentCarProfileInfo = ({ product }) => {
  const { name } = product;

  return (
    <div className="detail-info pr-0 pl-30">
      {/* <span className="stock-status out-stock"> Sale Off </span> */}
      <div className="d-flex justify-content-between align-items-start">
        <span>       
          <h2 className="title-detail">{name}</h2>
          <h5>Shamoli Rent a Car</h5>
        </span>


        <span>
          
          <span className="d-flex align-items-center gap-1">
            <CallIcon
              sx={{
                fontSize: 24,
                color: "black",
              }}
              className="text-brand"
            />
            <h4> 0174121447</h4>
          </span>
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap gap-4 pt-3">
        <div className="product-detail-rating pb-0 pt-0">
          <div className="product-rate-cover text-end">
            <div className="product-rate d-inline-block">
              <div className="product-rating"></div>
            </div>
            <Link to="#review" className="font-small ml-5">
              {" "}
              (32 reviews)
            </Link>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap gap-4">
          <Link
            to="shop-grid-right.html"
            className="d-flex align-items-center gap-2"
          >
            <ShareIcon
              sx={{
                fontSize: 14,
              }}
              color="primary"
            />
            <p>Share</p>
          </Link>

          <Link
            to="shop-grid-right.html"
            className="d-flex align-items-center gap-2"
          >
            <FavoriteIcon
              sx={{
                fontSize: 14,
                color: "red",
              }}
            />
            <p>Favorite</p>
          </Link>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-xl-8">
          <h4 className="mb-2">5 Seater Luxery Car</h4>
          <h4 className="mb-2">Model Year: 2015</h4> 
          <h4 className="mb-2">Seat: 05</h4>
          <h4 className="mb-2">Location: 122, Ring Road, Adabor, Dhaka - 1207
              <br />
              0.3 Km (Near by me)
          </h4>
        </div>

        <div className="col-xl-4">
          <GoogleMap />
        </div> 
      </div>
    </div>
  );
};

export default RentCarProfileInfo;
