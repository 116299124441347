import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import GarageIcon from "@mui/icons-material/Garage";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { useState } from "react";
import DriverBidding from "./DriverBidding";
import MechanicBidding from "./MechanicBidding";
import RentCarBidding from "./RentCarBidding/RentCarBidding";
import PartsBidding from "./PartsBidding";

const AddBidding = () => {
  const [bidding, setBidding] = useState(0);

  let content = null;
  const AddNewContent = () => {
    return (
      <div>
        <h3 className="text-center mb-30">I'm bidding for</h3>

        <div className="row">
          <div
            className="col-xl-6 text-center cursor-pointer mb-50"
            onClick={() => setBidding(1)}
          >
            <AccountCircleIcon sx={{ fontSize: 100 }} />
            <h5>Driver</h5>
          </div>
          <div
            className="col-xl-6 text-center cursor-pointer mb-50"
            onClick={() => setBidding(2)}
          >
            <BuildCircleIcon sx={{ fontSize: 100 }} />
            <h5>Mechanic</h5>
          </div>
          <div
            className="col-xl-6 text-center cursor-pointer mb-50"
            onClick={() => setBidding(3)}
          >
            <GarageIcon sx={{ fontSize: 100 }} />
            <h5>Rent A Car</h5>
          </div>
          <div
            className="col-xl-6 text-center cursor-pointer mb-50"
            onClick={() => setBidding(4)}
          >
            <WidgetsIcon sx={{ fontSize: 100 }} />
            <h5>Parts Seller</h5>
          </div>
        </div>
      </div>
    );
  };

  switch (bidding) {
    case 1:
      content = <DriverBidding back={() => setBidding(0)} />;
      break;

    case 2:
      content = <MechanicBidding back={() => setBidding(0)} />;
      break;

    case 3:
      content = <RentCarBidding back={() => setBidding(0)} />;
      break;

    case 4:
      content = <PartsBidding back={() => setBidding(0)} />;
      break;

    default:
      content = <AddNewContent />;
      break;
  }

  return content;
};

export default AddBidding;
