import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Headline from "../../../../../Components/common/Headline";
import ConfirmationStepContent from "./ConfirmationStepContent";
import { Link } from "react-router-dom";

const BiddingSuccess = () => {
  return (
    <div className="mt-50">
      <h4 className="text-center mb-30">
        <TaskAltIcon
          sx={{ fontSize: 28, color: "red", mr: 2 }}
          className="text-brand"
        />
        Your bidding Submitted Successfully
      </h4>

      <div style={{ marginBottom: "-20px" }}>
        <Headline title="Bidding Details" />
      </div>

      <ConfirmationStepContent />

      <div className="text-end">
        <Link to="/bidding/mechanic/details" className="btn btn-primary">
          View Details
        </Link>
      </div>
    </div>
  );
};

export default BiddingSuccess;
