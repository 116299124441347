import React from 'react'
import { Input, Form } from 'antd';
import axios from "../Helper/Config";
import { baseurl } from "../Helper/BaseUrl"
function VerifyPassword() {
    const [form] = Form.useForm();
    // console.log(currentURL, "currentURL currentURL")
    console.log(baseurl, "currentURL currentURL")
    const onFinish = (e) => {
        const sender_email = e.sender_email
        const obj = {
            email: sender_email,
            base_url: baseurl
        }
        axios.post("/auth/forget-password/request", obj)
            .then(res => {
                console.log(res, "99999999999")
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div className='forget__area__'>
            <div className='forgot_password_area'>
                <h3> Reset Your Password </h3>
                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  </p>
                <Form
                    name="basic"
                    layout="vertical"
                    className='from_home_area'
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </div>
                        <div className='col-lg-12'>
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </div>
                        <button className='button_login'> Submit </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default VerifyPassword