import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { apiImgUrl } from '../../Helper/BaseUrl';
import { useGetPartAccessoryQuery } from '../../features/partsAccessories/partsAccessoriesApi';
import DetailInfo from './DetailInfo';
import ProductBreadcrumb from './ProductBreadcrumb';
import ProductGallery from './ProductGallery';
import ProductInfo from './ProductInfo/ProductInfo';
import RelatedProducts from './RelatedProducts';
import Loader from '../../Helper/Loader';
function ProductDetails() {
    const { slug } = useParams();
    const { data, isSuccess, isLoading } = useGetPartAccessoryQuery(slug);
    const product = data?.data?.product_details[0];

    const renderImages = () => {
        if (isSuccess && !isLoading) {
            const imageWithThumb = [
                {
                    image: product?.thumbnail,
                },
                ...product?.all_image,
            ];

            const dataImg = imageWithThumb?.map((item) => ({
                image: apiImgUrl + item.image,
            }));

            return dataImg;
        }
    };

    const dataImg = renderImages();

    console.log(product);
    console.log(dataImg);

    return (
        <div>
            <ProductBreadcrumb />

            {isSuccess && !isLoading && (
                <Container>
                    <div className='container mb-30'>
                        <div className='row'>
                            <div className='col-xl-10 col-lg-12 m-auto'>
                                <div className='product-detail accordion-detail'>
                                    <div className='row mb-50 mt-30'>
                                        <div className='col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5'>
                                            <ProductGallery dataImg={dataImg} />
                                        </div>
                                        <div className='col-md-6 col-sm-12 col-xs-12'>
                                            <DetailInfo product={product} />
                                        </div>
                                    </div>

                                    <ProductInfo product={product} />
                                    <RelatedProducts />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            )}

            <Loader loaderShow={isLoading} loaderHide={isLoading} />
        </div>
    );
}

export default ProductDetails;
