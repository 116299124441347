import React from "react";

const Headline = ({ startIcon = null, title = "This is title", endItem }) => {
  return (
    <h5 class="section-title style-1 mb-30">
      <div>
        {startIcon}
        {title}
      </div>
      {endItem ? endItem : ""}
    </h5>
  );
};

export default Headline;
