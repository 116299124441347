import React from "react";

const ServiceItem = ({ service, onClick }) => {
  const { title, image } = service;

  const handleOnClick = () => {
    onClick(service);
  };

  return (
    <div
      class="col-lg-4 col-md-6 col-12 col-sm-6 cursor-pointer"
      onClick={handleOnClick}
    >
      <div class="vendor-wrap mb-40">
        <div class="">
          <div class="vendor-img">
            <img class="default-img" src={image} alt={title} />
          </div>
        </div>
        <div class="vendor-content-wrap">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4 class="fs-5 mb-5">
                <a href="#">{title}</a>
              </h4>
            </div>
            <div class="">
              <button class="btn btn-xs">
                Details <i class="fi-rs-arrow-small-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
