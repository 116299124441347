import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'

import Catagori01 from "../assets/images/product/sc.png"
import Catagori02 from "../assets/images/product/sc.png"

import axios from "../Helper/Config";
import { apiImgUrl } from "../Helper/BaseUrl";

function SubCategoriesList() {
    const { slug } = useParams();
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get("/seller/parts/category")
            .then((res) => {
                const found = res.data?.data?.category?.find(obj => {
                    return obj.slug === `${slug}`;
                });
                axios.get(`seller/parts/subcategory/${found?.id}`)
                    .then((res) => {
                        setData(res.data.data.subcategory)
                    }).catch((err) => {

                    })
            }).catch((err) => {

            })
    }, [])

    return (
        <div className='home_category_area'>
            <Container>
                <Row>
                    <Col>
                        <div className='setion____title'>
                            <h3> Shop by <span> Categories </span> </h3>
                        </div>
                        <Row>
                            {data?.map((item, i) =>
                                <Col key={i} lg={2}>
                                    <div className='card card_slider service__full_box' style={{ marginBottom: '25px' }}>
                                        <Link to={`/product-categories/${item?.slug}`}>
                                            <div className='qodef_e_inner'>
                                                <h3 className="productCatagori_top">  {item?.name}  </h3>
                                                <img style={{ width: '100%' }} src={apiImgUrl + item?.image} alt="" />
                                                <div className='product__add_tocard'>
                                                    Details
                                                </div>
                                                <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to={`/product-categories/${item?.slug}`}></Link>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SubCategoriesList
