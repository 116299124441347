import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { apiImgUrl } from "../../Helper/BaseUrl";
import { Link } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';

import Catagori01 from "../../assets/images/product/p1.png"
import Catagori02 from "../../assets/images/product/p2.png"
import Catagori03 from "../../assets/images/product/p3.png"
import Catagori04 from "../../assets/images/product/p4.png"
import Catagori05 from "../../assets/images/product/p5.png"
import Catagori06 from "../../assets/images/product/p6.png"

function Gallery(props) {
    console.log(props?.imagesData)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const options = {
        navText: ["Prev", "Next"],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 2,
            },
            1280: {
                items: 2,
            },
            1366: {
                items: 2,
            },
        }
    };
    const showModal = () => {
        console.log("999999999999")
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const closemodal = () => {
        setIsModalOpen(false);
    };
    
    return (
        <div>
            <div className='home_category_area'>
                {/* {props?.imagesData?.leanth > 0 ? <> */}
                    <Container>
                        <Row>
                            <Col>
                                <div className='setion____title'>
                                    <h3> Our <span> Gallery </span> </h3>
                                    {props?.imagesData?.leanth }
                                </div>
                                <OwlCarousel className='owl-theme'
                                    loop
                                    margin={10}
                                    nav={false}
                                    dots={false}
                                    autoplay={true}
                                    autoplayTimeout={5000}
                                    {...options}>
                                    {props?.imagesData?.map((item) =>
                                        <div className='item'>
                                            <div className='card card_slider service__full_box' >
                                                <div className='qodef_e_inner'>
                                                    {/* <h3 className="productCatagori_top"> Services Name </h3> */}
                                                    <img src={apiImgUrl + item?.image_path} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </OwlCarousel>
                            </Col>
                        </Row>
                    </Container>
                {/* </> : <>  </>} */}
            </div>
        </div>
    )
}

export default Gallery