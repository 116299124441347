// utils/localStorage.js
export const loadCartData = () => {
    try {
        const serializedCartData = localStorage.getItem('cartData');
        return serializedCartData ? JSON.parse(serializedCartData) : undefined;
    } catch (error) {
        console.error('Error loading cart data from local storage:', error);
        return undefined;
    }
};

export const saveCartData = (cartData) => {
    try {
        const serializedCartData = JSON.stringify(cartData);
        localStorage.setItem('cartData', serializedCartData);
    } catch (error) {
        console.error('Error saving cart data to local storage:', error);
    }
};
