import { apiSlice } from '../api/apiSlice';

export const featuredProducts = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFeaturedProducts: builder.query({
            query: () => '/seller/product/featured/list',
        }),
    }),
});

export const { useGetFeaturedProductsQuery } = featuredProducts;
