import React from "react";
import { useState } from "react";
import ProductGallery from "../../../ProductDetails/ProductGallery";
import RelatedProducts from "../../../ProductDetails/RelatedProducts";
import MechanicDetailsInfo from "./MechanicDetailsInfo";
import { useRef } from "react";
import { useEffect } from "react";
import BookingInfoTab from "./BookingInfoTab";
import BookingSteps from "./BookingSteps/BookingSteps";

const dataImg = [
  {
    image:
      "https://images.unsplash.com/photo-1504222490345-c075b6008014?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
  },
  {
    image:
      "https://images.unsplash.com/photo-1473655587843-eda8944061e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
  },
];

const driverData = {
  id: 50,
  name: "Rasel Ahmed",
  discount_price: 1000,
};

const MechanicDetails = () => {
  const [isBooking, setIsBooking] = useState(false);
  const tabRef = useRef(null);

  useEffect(() => {
    if (isBooking) {
      tabRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isBooking]);

  const handleClick = () => {
    tabRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container mb-30 mt-30">
      <div className="row">
        {/* col-lg-4-5 col-xl-10 col-lg-12 m-auto */}
        <div className="col-xl-12">
          <div className="product-detail accordion-detail">
            <div className="row mb-50 mt-30">
              <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                <ProductGallery dataImg={dataImg} />
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <MechanicDetailsInfo
                  setIsBooking={setIsBooking}
                  product={driverData}
                  handleRef={handleClick}
                />
              </div>
            </div>

            {/* <ProductInfo product={driverData} /> */}

            {isBooking ? (
              <span ref={tabRef}>
                <BookingSteps alterLabel />
              </span>
            ) : (
              <BookingInfoTab product={driverData} />
            )}

            <RelatedProducts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MechanicDetails;
