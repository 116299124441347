import { Button } from "@material-ui/core";
import { Typography } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';

import React from "react";

const BiddingCategory = () => {
  return (
    <div className="container">
      <div className="row">
        <div class="col-md-6 col-lg-3">
          <div class="card bg-success mb-3">
            <div class="card-body">
              <Link to='/user-bidding-list'>
              {/* <Typography
                variant="h6"
                sx={{
                  color: "white",
                  width: "fit-content",
                  background: "rgba(0,0,0, .13)",
                  padding: "5px 20px",
                  borderRadius: "50rem",
                }}
              >
                Car Parts Bidding
              </Typography> */}
              <h5
                class="text-white rounded-pill mb-40"
                style={{
                  width: "fit-content",
                  background: "rgba(0,0,0, .13)",
                  padding: "5px 20px",
                }}
              >
                Car Parts Bidding
              </h5>

              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p class="text-white">Live Bidding: 512+</p>

                <button className="btn bg-warning" >Go</button>
              </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="card text-bg-secondary mb-3">
            <div class="card-body">
              <Link to='/user-bidding-list'>

              <h5
                class="text-white rounded-pill mb-40"
                style={{
                  width: "fit-content",
                  background: "rgba(0,0,0, .13)",
                  padding: "5px 20px",
                }}
              >
                Vehicle Bidding
              </h5>

              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p class="text-white">Live Bidding: 512+</p>

                <button className="btn bg-warning">Go</button>
              </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="card text-bg-success mb-3">
            <div class="card-body">
              <Link to='/user-bidding-list'>

              <h5
                class="text-white rounded-pill mb-40"
                style={{
                  width: "fit-content",
                  background: "rgba(0,0,0, .13)",
                  padding: "5px 20px",
                }}
              >
                Mechanic Bidding
              </h5>

              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p class="text-white">Live Bidding: 512+</p>

                <button className="btn bg-warning">Go</button>
              </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="card text-bg-dark mb-3">
            <div class="card-body">
              <Link to='/user-bidding-list'>

              <h5
                class="text-white rounded-pill mb-40"
                style={{
                  width: "fit-content",
                  background: "rgba(255,255,255, .13)",
                  padding: "5px 20px",
                }}
              >
                Driver Bidding
              </h5>

              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p class="text-white">Live Bidding: 512+</p>

                <button className="btn bg-warning">Go</button>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiddingCategory;
